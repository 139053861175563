const languages = {
  'aa': "Afar",
  'ab': "Abkhazian",
  'ae': "Avestan",
  'af': "Afrikaans",
  'ak': "Akan",
  'am': "Amharic",
  'an': "Aragonese",
  'ar': "Arabic",
  'as': "Assamese",
  'av': "Avaric",
  'ay': "Aymara",
  'az': "Azerbaijani",
  'ba': "Bashkir",
  'be': "Belarusian",
  'bg': "Bulgarian",
  'bh': "Bihari languages",
  'bi': "Bislama",
  'bm': "Bambara",
  'bn': "Bangla",
  'bo': "Tibetan",
  'br': "Breton",
  'bs': "Bosnian",
  'ca': "Valencian",
  'ce': "Chechen",
  'ch': "Chamorro",
  'co': "Corsican",
  'cr': "Cree",
  'cs': "Czech",
  'cu': "Old Slavonic",
  'cv': "Chuvash",
  'cy': "Welsh",
  'da': "Danish",
  'de': "German",
  'dv': "Maldivian",
  'dz': "Dzongkha",
  'ee': "Ewe",
  'el': "Modern Greek (1453-)",
  'en': "English",
  'eo': "Esperanto",
  'es': "Castilian",
  'et': "Estonian",
  'eu': "Basque",
  'fa': "Persian",
  'ff': "Fulah",
  'fi': "Finnish",
  'fj': "Fijian",
  'fo': "Faroese",
  'fr': "French",
  'fy': "Western Frisian",
  'ga': "Irish",
  'gd': "Gaelic",
  'gl': "Galician",
  'gn': "Guarani",
  'gu': "Gujarati",
  'gv': "Manx",
  'ha': "Hausa",
  'he': "Hebrew",
  'hi': "Hindi",
  'ho': "Hiri Motu",
  'hr': "Croatian",
  'ht': "Haitian Creole",
  'hu': "Hungarian",
  'hy': "Armenian",
  'hz': "Herero",
  'ia': "Interlingua (International Auxiliary Language",
  'id': "Indonesian",
  'ie': "Occidental",
  'ig': "Igbo",
  'ii': "Nuosu",
  'ik': "Inupiaq",
  'in': "Indonesian",
  'io': "Ido",
  'is': "Icelandic",
  'it': "Italian",
  'iu': "Inuktitut",
  'iw': "Hebrew",
  'ja': "Japanese",
  'ji': "Yiddish",
  'jv': "Javanese",
  'jw': "Javanese",
  'ka': "Georgian",
  'kg': "Kongo",
  'ki': "Gikuyu",
  'kj': "Kwanyama",
  'kk': "Kazakh",
  'kl': "Greenlandic",
  'km': "Central Khmer",
  'kn': "Kannada",
  'ko': "Korean",
  'kr': "Kanuri",
  'ks': "Kashmiri",
  'ku': "Kurdish",
  'kv': "Komi",
  'kw': "Cornish",
  'ky': "Kyrgyz",
  'la': "Latin",
  'lb': "Letzeburgesch",
  'lg': "Luganda",
  'li': "Limburgish",
  'ln': "Lingala",
  'lo': "Lao",
  'lt': "Lithuanian",
  'lu': "Luba-Katanga",
  'lv': "Latvian",
  'mg': "Malagasy",
  'mh': "Marshallese",
  'mi': "Maori",
  'mk': "Macedonian",
  'ml': "Malayalam",
  'mn': "Mongolian",
  'mo': "Moldovan",
  'mr': "Marathi",
  'ms': "Malay (macrolanguage)",
  'mt': "Maltese",
  'my': "Burmese",
  'na': "Nauru",
  'nb': "Norwegian Bokmål",
  'nd': "North Ndebele",
  'ne': "Nepali (macrolanguage)",
  'ng': "Ndonga",
  'nl': "Flemish",
  'nn': "Norwegian Nynorsk",
  'no': "Norwegian",
  'nr': "South Ndebele",
  'nv': "Navaho",
  'ny': "Chichewa",
  'oc': "Occitan (post 1500)",
  'oj': "Ojibwa",
  'om': "Oromo",
  'or': "Odia (macrolanguage)",
  'os': "Ossetic",
  'pa': "Punjabi",
  'pi': "Pali",
  'pl': "Polish",
  'ps': "Pashto",
  'pt': "Portuguese",
  'qu': "Quechua",
  'rm': "Romansh",
  'rn': "Rundi",
  'ro': "Moldovan",
  'ru': "Russian",
  'rw': "Kinyarwanda",
  'sa': "Sanskrit",
  'sc': "Sardinian",
  'sd': "Sindhi",
  'se': "Northern Sami",
  'sg': "Sango",
  'sh': "Serbo-Croatian",
  'si': "Sinhalese",
  'sk': "Slovak",
  'sl': "Slovenian",
  'sm': "Samoan",
  'sn': "Shona",
  'so': "Somali",
  'sq': "Albanian",
  'sr': "Serbian",
  'ss': "Swati",
  'st': "Southern Sotho",
  'su': "Sundanese",
  'sv': "Swedish",
  'sw': "Swahili (macrolanguage)",
  'ta': "Tamil",
  'te': "Telugu",
  'tg': "Tajik",
  'th': "Thai",
  'ti': "Tigrinya",
  'tk': "Turkmen",
  'tl': "Tagalog",
  'tn': "Tswana",
  'to': "Tonga (Tonga Islands)",
  'tr': "Turkish",
  'ts': "Tsonga",
  'tt': "Tatar",
  'tw': "Twi",
  'ty': "Tahitian",
  'ug': "Uyghur",
  'uk': "Ukrainian",
  'ur': "Urdu",
  'uz': "Uzbek",
  've': "Venda",
  'vi': "Vietnamese",
  'vo': "Volapük",
  'wa': "Walloon",
  'wo': "Wolof",
  'xh': "Xhosa",
  'yi': "Yiddish",
  'yo': "Yoruba",
  'za': "Chuang",
  'zh': "Chinese",
  'zu': "Zulu",
  'aaa': "Ghotuo",
  'aab': "Alumu-Tesu",
  'aac': "Ari",
  'aad': "Amal",
  'aae': "Arbëreshë Albanian",
  'aaf': "Aranadan",
  'aag': "Ambrak",
  'aah': "Abu' Arapesh",
  'aai': "Arifama-Miniafia",
  'aak': "Ankave",
  'aal': "Afade",
  'aam': "Aramanik",
  'aan': "Anambé",
  'aao': "Algerian Saharan Arabic",
  'aap': "Pará Arára",
  'aaq': "Eastern Abnaki",
  'aas': "Aasáx",
  'aat': "Arvanitika Albanian",
  'aau': "Abau",
  'aav': "Austro-Asiatic languages",
  'aaw': "Solong",
  'aax': "Mandobo Atas",
  'aaz': "Amarasi",
  'aba': "Abé",
  'abb': "Bankon",
  'abc': "Ambala Ayta",
  'abd': "Manide",
  'abe': "Western Abnaki",
  'abf': "Abai Sungai",
  'abg': "Abaga",
  'abh': "Tajiki Arabic",
  'abi': "Abidji",
  'abj': "Aka-Bea",
  'abl': "Lampung Nyo",
  'abm': "Abanyom",
  'abn': "Abua",
  'abo': "Abon",
  'abp': "Abellen Ayta",
  'abq': "Abaza",
  'abr': "Abron",
  'abs': "Ambonese Malay",
  'abt': "Ambulas",
  'abu': "Abure",
  'abv': "Baharna Arabic",
  'abw': "Pal",
  'abx': "Inabaknon",
  'aby': "Aneme Wake",
  'abz': "Abui",
  'aca': "Achagua",
  'acb': "Áncá",
  'acd': "Gikyode",
  'ace': "Achinese",
  'acf': "Saint Lucian Creole French",
  'ach': "Acoli",
  'aci': "Aka-Cari",
  'ack': "Aka-Kora",
  'acl': "Akar-Bale",
  'acm': "Mesopotamian Arabic",
  'acn': "Achang",
  'acp': "Eastern Acipa",
  'acq': "Ta'izzi-Adeni Arabic",
  'acr': "Achi",
  'acs': "Acroá",
  'act': "Achterhoeks",
  'acu': "Achuar-Shiwiar",
  'acv': "Achumawi",
  'acw': "Hijazi Arabic",
  'acx': "Omani Arabic",
  'acy': "Cypriot Arabic",
  'acz': "Acheron",
  'ada': "Adangme",
  'adb': "Atauran",
  'add': "Dzodinka",
  'ade': "Adele",
  'adf': "Dhofari Arabic",
  'adg': "Andegerebinha",
  'adh': "Adhola",
  'adi': "Adi",
  'adj': "Adioukrou",
  'adl': "Galo",
  'adn': "Adang",
  'ado': "Abu",
  'adp': "Adap",
  'adq': "Adangbe",
  'adr': "Adonara",
  'ads': "Adamorobe Sign Language",
  'adt': "Adnyamathanha",
  'adu': "Aduge",
  'adw': "Amundava",
  'adx': "Amdo Tibetan",
  'ady': "Adygei",
  'adz': "Adzera",
  'aea': "Areba",
  'aeb': "Tunisian Arabic",
  'aec': "Saidi Arabic",
  'aed': "Argentine Sign Language",
  'aee': "Northeast Pashayi",
  'aek': "Haeke",
  'ael': "Ambele",
  'aem': "Arem",
  'aen': "Armenian Sign Language",
  'aeq': "Aer",
  'aer': "Eastern Arrernte",
  'aes': "Alsea",
  'aeu': "Akeu",
  'aew': "Ambakich",
  'aey': "Amele",
  'aez': "Aeka",
  'afa': "Afro-Asiatic languages",
  'afb': "Gulf Arabic",
  'afd': "Andai",
  'afe': "Putukwam",
  'afg': "Afghan Sign Language",
  'afh': "Afrihili",
  'afi': "Chini",
  'afk': "Nanubae",
  'afn': "Defaka",
  'afo': "Eloyi",
  'afp': "Tapei",
  'afs': "Afro-Seminole Creole",
  'aft': "Afitti",
  'afu': "Awutu",
  'afz': "Obokuitai",
  'aga': "Aguano",
  'agb': "Legbo",
  'agc': "Agatu",
  'agd': "Agarabi",
  'age': "Angal",
  'agf': "Arguni",
  'agg': "Angor",
  'agh': "Ngelima",
  'agi': "Agariya",
  'agj': "Argobba",
  'agk': "Isarog Agta",
  'agl': "Fembe",
  'agm': "Angaataha",
  'agn': "Agutaynen",
  'ago': "Tainae",
  'agp': "Paranan",
  'agq': "Aghem",
  'agr': "Aguaruna",
  'ags': "Esimbi",
  'agt': "Central Cagayan Agta",
  'agu': "Aguacateco",
  'agv': "Remontado Dumagat",
  'agw': "Kahua",
  'agx': "Aghul",
  'agy': "Southern Alta",
  'agz': "Mt. Iriga Agta",
  'aha': "Ahanta",
  'ahb': "Axamb",
  'ahg': "Qimant",
  'ahh': "Aghu",
  'ahi': "Tiagbamrin Aizi",
  'ahk': "Akha",
  'ahl': "Igo",
  'ahm': "Mobumrin Aizi",
  'ahn': "Àhàn",
  'aho': "Ahom",
  'ahp': "Aproumu Aizi",
  'ahr': "Ahirani",
  'ahs': "Ashe",
  'aht': "Ahtena",
  'aia': "Arosi",
  'aib': "Ainu (China)",
  'aic': "Ainbai",
  'aid': "Alngith",
  'aie': "Amara",
  'aif': "Agi",
  'aig': "Antigua and Barbuda Creole English",
  'aih': "Ai-Cham",
  'aii': "Assyrian Neo-Aramaic",
  'aij': "Lishanid Noshan",
  'aik': "Ake",
  'ail': "Aimele",
  'aim': "Aimol",
  'ain': "Ainu (Japan)",
  'aio': "Aiton",
  'aip': "Burumakok",
  'aiq': "Aimaq",
  'air': "Airoran",
  'ais': "Nataoran Amis",
  'ait': "Arikem",
  'aiw': "Aari",
  'aix': "Aighon",
  'aiy': "Ali",
  'aja': "Aja (South Sudan)",
  'ajg': "Aja (Benin)",
  'aji': "Ajië",
  'ajn': "Andajin",
  'ajp': "South Levantine Arabic",
  'ajs': "Algerian Jewish Sign Language",
  'ajt': "Judeo-Tunisian Arabic",
  'aju': "Judeo-Moroccan Arabic",
  'ajw': "Ajawa",
  'ajz': "Amri Karbi",
  'akb': "Batak Angkola",
  'akc': "Mpur",
  'akd': "Ukpet-Ehom",
  'ake': "Akawaio",
  'akf': "Akpa",
  'akg': "Anakalangu",
  'akh': "Angal Heneng",
  'aki': "Aiome",
  'akj': "Aka-Jeru",
  'akk': "Akkadian",
  'akl': "Aklanon",
  'akm': "Aka-Bo",
  'ako': "Akurio",
  'akp': "Siwu",
  'akq': "Ak",
  'akr': "Araki",
  'aks': "Akaselem",
  'akt': "Akolet",
  'aku': "Akum",
  'akv': "Akhvakh",
  'akw': "Akwa",
  'akx': "Aka-Kede",
  'aky': "Aka-Kol",
  'akz': "Alabama",
  'ala': "Alago",
  'alc': "Qawasqar",
  'ald': "Alladian",
  'ale': "Aleut",
  'alf': "Alege",
  'alg': "Algonquian languages",
  'alh': "Alawa",
  'ali': "Amaimon",
  'alj': "Alangan",
  'alk': "Alak",
  'all': "Allar",
  'alm': "Amblong",
  'aln': "Gheg Albanian",
  'alo': "Larike-Wakasihu",
  'alp': "Alune",
  'alq': "Algonquin",
  'alr': "Alutor",
  'als': "Tosk Albanian",
  'alt': "Southern Altai",
  'alu': "'Are'are",
  'alv': "Atlantic-Congo languages",
  'alw': "Wanbasana",
  'alx': "Amol",
  'aly': "Alyawarr",
  'alz': "Alur",
  'ama': "Amanayé",
  'amb': "Ambo",
  'amc': "Amahuaca",
  'ame': "Yanesha'",
  'amf': "Hamer-Banna",
  'amg': "Amurdak",
  'ami': "Amis",
  'amj': "Amdang",
  'amk': "Ambai",
  'aml': "War-Jaintia",
  'amm': "Ama (Papua New Guinea)",
  'amn': "Amanab",
  'amo': "Amo",
  'amp': "Alamblak",
  'amq': "Amahai",
  'amr': "Amarakaeri",
  'ams': "Southern Amami-Oshima",
  'amt': "Amto",
  'amu': "Guerrero Amuzgo",
  'amv': "Ambelau",
  'amw': "Western Neo-Aramaic",
  'amx': "Anmatyerre",
  'amy': "Ami",
  'amz': "Atampaya",
  'ana': "Andaqui",
  'anb': "Andoa",
  'anc': "Ngas",
  'and': "Ansus",
  'ane': "Xârâcùù",
  'anf': "Animere",
  'ang': "Old English (ca. 450-1100)",
  'anh': "Nend",
  'ani': "Andi",
  'anj': "Anor",
  'ank': "Goemai",
  'anl': "Anu-Hkongso Chin",
  'anm': "Anal",
  'ann': "Obolo",
  'ano': "Andoque",
  'anp': "Angika",
  'anq': "Jarawa (India)",
  'anr': "Andh",
  'ans': "Anserma",
  'ant': "Antikarinya",
  'anu': "Anuak",
  'anv': "Denya",
  'anw': "Anaang",
  'anx': "Andra-Hus",
  'any': "Anyin",
  'anz': "Anem",
  'aoa': "Angolar",
  'aob': "Abom",
  'aoc': "Pemon",
  'aod': "Andarum",
  'aoe': "Angal Enen",
  'aof': "Bragat",
  'aog': "Angoram",
  'aoh': "Arma",
  'aoi': "Anindilyakwa",
  'aoj': "Mufian",
  'aok': "Arhö",
  'aol': "Alor",
  'aom': "Ömie",
  'aon': "Bumbita Arapesh",
  'aor': "Aore",
  'aos': "Taikat",
  'aot': "A'tong",
  'aou': "A'ou",
  'aox': "Atorada",
  'aoz': "Uab Meto",
  'apa': "Apache languages",
  'apb': "Sa'a",
  'apc': "Levantine Arabic",
  'apd': "Sudanese Arabic",
  'ape': "Bukiyip",
  'apf': "Pahanan Agta",
  'apg': "Ampanang",
  'aph': "Athpariya",
  'api': "Apiaká",
  'apj': "Jicarilla Apache",
  'apk': "Kiowa Apache",
  'apl': "Lipan Apache",
  'apm': "Mescalero-Chiricahua Apache",
  'apn': "Apinayé",
  'apo': "Ambul",
  'app': "Apma",
  'apq': "A-Pucikwar",
  'apr': "Arop-Lokep",
  'aps': "Arop-Sissano",
  'apt': "Apatani",
  'apu': "Apurinã",
  'apv': "Alapmunte",
  'apw': "Western Apache",
  'apx': "Aputai",
  'apy': "Apalaí",
  'apz': "Safeyoka",
  'aqa': "Alacalufan languages",
  'aqc': "Archi",
  'aqd': "Ampari Dogon",
  'aqg': "Arigidi",
  'aqk': "Aninka",
  'aql': "Algic languages",
  'aqm': "Atohwaim",
  'aqn': "Northern Alta",
  'aqp': "Atakapa",
  'aqr': "Arhâ",
  'aqt': "Angaité",
  'aqz': "Akuntsu",
  'arb': "Standard Arabic",
  'arc': "Imperial Aramaic (700-300 BCE)",
  'ard': "Arabana",
  'are': "Western Arrarnta",
  'arh': "Arhuaco",
  'ari': "Arikara",
  'arj': "Arapaso",
  'ark': "Arikapú",
  'arl': "Arabela",
  'arn': "Mapuche",
  'aro': "Araona",
  'arp': "Arapaho",
  'arq': "Algerian Arabic",
  'arr': "Karo (Brazil)",
  'ars': "Najdi Arabic",
  'art': "Artificial languages",
  'aru': "Arawá",
  'arv': "Arbore",
  'arw': "Arawak",
  'arx': "Aruá (Rodonia State)",
  'ary': "Moroccan Arabic",
  'arz': "Egyptian Arabic",
  'asa': "Asu (Tanzania)",
  'asb': "Assiniboine",
  'asc': "Casuarina Coast Asmat",
  'asd': "Asas",
  'ase': "American Sign Language",
  'asf': "Australian Sign Language",
  'asg': "Cishingini",
  'ash': "Abishira",
  'asi': "Buruwai",
  'asj': "Sari",
  'ask': "Ashkun",
  'asl': "Asilulu",
  'asn': "Xingú Asuriní",
  'aso': "Dano",
  'asp': "Algerian Sign Language",
  'asq': "Austrian Sign Language",
  'asr': "Asuri",
  'ass': "Ipulo",
  'ast': "Leonese",
  'asu': "Tocantins Asurini",
  'asv': "Asoa",
  'asw': "Australian Aborigines Sign Language",
  'asx': "Muratayak",
  'asy': "Yaosakor Asmat",
  'asz': "As",
  'ata': "Pele-Ata",
  'atb': "Zaiwa",
  'atc': "Atsahuaca",
  'atd': "Ata Manobo",
  'ate': "Atemble",
  'atg': "Ivbie North-Okpela-Arhe",
  'ath': "Athapascan languages",
  'ati': "Attié",
  'atj': "Atikamekw",
  'atk': "Ati",
  'atl': "Mt. Iraya Agta",
  'atm': "Ata",
  'atn': "Ashtiani",
  'ato': "Atong (Cameroon)",
  'atp': "Pudtol Atta",
  'atq': "Aralle-Tabulahan",
  'atr': "Waimiri-Atroari",
  'ats': "Gros Ventre",
  'att': "Pamplona Atta",
  'atu': "Reel",
  'atv': "Northern Altai",
  'atw': "Atsugewi",
  'atx': "Arutani",
  'aty': "Aneityum",
  'atz': "Arta",
  'aua': "Asumboa",
  'aub': "Alugu",
  'auc': "Waorani",
  'aud': "Anuta",
  'aue': "ǂKxʼauǁʼein",
  'auf': "Arauan languages",
  'aug': "Aguna",
  'auh': "Aushi",
  'aui': "Anuki",
  'auj': "Awjilah",
  'auk': "Heyo",
  'aul': "Aulua",
  'aum': "Asu (Nigeria)",
  'aun': "Molmo One",
  'auo': "Auyokawa",
  'aup': "Makayam",
  'auq': "Korur",
  'aur': "Aruek",
  'aus': "Australian languages",
  'aut': "Austral",
  'auu': "Auye",
  'auw': "Awyi",
  'aux': "Aurá",
  'auy': "Awiyaana",
  'auz': "Uzbeki Arabic",
  'avb': "Avau",
  'avd': "Alviri-Vidari",
  'avi': "Avikam",
  'avk': "Kotava",
  'avl': "Eastern Egyptian Bedawi Arabic",
  'avm': "Angkamuthi",
  'avn': "Avatime",
  'avo': "Agavotaguerra",
  'avs': "Aushiri",
  'avt': "Au",
  'avu': "Avokaya",
  'avv': "Avá-Canoeiro",
  'awa': "Awadhi",
  'awb': "Awa (Papua New Guinea)",
  'awc': "Cicipu",
  'awd': "Arawakan languages",
  'awe': "Awetí",
  'awg': "Anguthimri",
  'awh': "Awbono",
  'awi': "Aekyom",
  'awk': "Awabakal",
  'awm': "Arawum",
  'awn': "Awngi",
  'awo': "Awak",
  'awr': "Awera",
  'aws': "South Awyu",
  'awt': "Araweté",
  'awu': "Central Awyu",
  'awv': "Jair Awyu",
  'aww': "Awun",
  'awx': "Awara",
  'awy': "Edera Awyu",
  'axb': "Abipon",
  'axe': "Ayerrerenge",
  'axg': "Mato Grosso Arára",
  'axk': "Yaka (Central African Republic)",
  'axl': "Lower Southern Aranda",
  'axm': "Middle Armenian",
  'axx': "Xârâgurè",
  'aya': "Awar",
  'ayb': "Ayizo Gbe",
  'ayc': "Southern Aymara",
  'ayd': "Ayabadhu",
  'aye': "Ayere",
  'ayg': "Ginyanga",
  'ayh': "Hadrami Arabic",
  'ayi': "Leyigha",
  'ayk': "Akuku",
  'ayl': "Libyan Arabic",
  'ayn': "Sanaani Arabic",
  'ayo': "Ayoreo",
  'ayp': "North Mesopotamian Arabic",
  'ayq': "Ayi (Papua New Guinea)",
  'ayr': "Central Aymara",
  'ays': "Sorsogon Ayta",
  'ayt': "Magbukun Ayta",
  'ayu': "Ayu",
  'ayx': "Ayi (China)",
  'ayy': "Tayabas Ayta",
  'ayz': "Mai Brat",
  'aza': "Azha",
  'azb': "South Azerbaijani",
  'azc': "Uto-Aztecan languages",
  'azd': "Eastern Durango Nahuatl",
  'azg': "San Pedro Amuzgos Amuzgo",
  'azj': "North Azerbaijani",
  'azm': "Ipalapa Amuzgo",
  'azn': "Western Durango Nahuatl",
  'azo': "Awing",
  'azt': "Faire Atta",
  'azz': "Highland Puebla Nahuatl",
  'baa': "Babatana",
  'bab': "Bainouk-Gunyuño",
  'bac': "Badui",
  'bad': "Banda languages",
  'bae': "Baré",
  'baf': "Nubaca",
  'bag': "Tuki",
  'bah': "Bahamas Creole English",
  'bai': "Bamileke languages",
  'baj': "Barakai",
  'bal': "Baluchi",
  'ban': "Balinese",
  'bao': "Waimaha",
  'bap': "Bantawa",
  'bar': "Bavarian",
  'bas': "Basa (Cameroon)",
  'bat': "Baltic languages",
  'bau': "Bada (Nigeria)",
  'bav': "Vengo",
  'baw': "Bambili-Bambui",
  'bax': "Bamun",
  'bay': "Batuley",
  'baz': "Tunen",
  'bba': "Baatonum",
  'bbb': "Barai",
  'bbc': "Batak Toba",
  'bbd': "Bau",
  'bbe': "Bangba",
  'bbf': "Baibai",
  'bbg': "Barama",
  'bbh': "Bugan",
  'bbi': "Barombi",
  'bbj': "Ghomálá'",
  'bbk': "Babanki",
  'bbl': "Bats",
  'bbm': "Babango",
  'bbn': "Uneapa",
  'bbo': "Konabéré",
  'bbp': "West Central Banda",
  'bbq': "Bamali",
  'bbr': "Girawa",
  'bbs': "Bakpinka",
  'bbt': "Mburku",
  'bbu': "Kulung (Nigeria)",
  'bbv': "Karnai",
  'bbw': "Baba",
  'bbx': "Bubia",
  'bby': "Befang",
  'bbz': "Babalia Creole Arabic",
  'bca': "Central Bai",
  'bcb': "Bainouk-Samik",
  'bcc': "Southern Balochi",
  'bcd': "North Babar",
  'bce': "Bamenyam",
  'bcf': "Bamu",
  'bcg': "Baga Pokur",
  'bch': "Bariai",
  'bci': "Baoulé",
  'bcj': "Bardi",
  'bck': "Bunuba",
  'bcl': "Central Bikol",
  'bcm': "Bannoni",
  'bcn': "Bali (Nigeria)",
  'bco': "Kaluli",
  'bcp': "Bali (Democratic Republic of Congo)",
  'bcq': "Bench",
  'bcr': "Babine",
  'bcs': "Kohumono",
  'bct': "Bendi",
  'bcu': "Awad Bing",
  'bcv': "Shoo-Minda-Nye",
  'bcw': "Bana",
  'bcy': "Bacama",
  'bcz': "Bainouk-Gunyaamolo",
  'bda': "Bayot",
  'bdb': "Basap",
  'bdc': "Emberá-Baudó",
  'bdd': "Bunama",
  'bde': "Bade",
  'bdf': "Biage",
  'bdg': "Bonggi",
  'bdh': "Baka (South Sudan)",
  'bdi': "Burun",
  'bdj': "Bai",
  'bdk': "Budukh",
  'bdl': "Indonesian Bajau",
  'bdm': "Buduma",
  'bdn': "Baldemu",
  'bdo': "Morom",
  'bdp': "Bende",
  'bdq': "Bahnar",
  'bdr': "West Coast Bajau",
  'bds': "Burunge",
  'bdt': "Bokoto",
  'bdu': "Oroko",
  'bdv': "Bodo Parja",
  'bdw': "Baham",
  'bdx': "Budong-Budong",
  'bdy': "Bandjalang",
  'bdz': "Badeshi",
  'bea': "Beaver",
  'beb': "Bebele",
  'bec': "Iceve-Maci",
  'bed': "Bedoanas",
  'bee': "Byangsi",
  'bef': "Benabena",
  'beg': "Belait",
  'beh': "Biali",
  'bei': "Bekati'",
  'bej': "Bedawiyet",
  'bek': "Bebeli",
  'bem': "Bemba (Zambia)",
  'beo': "Beami",
  'bep': "Besoa",
  'beq': "Beembe",
  'ber': "Berber languages",
  'bes': "Besme",
  'bet': "Guiberoua Béte",
  'beu': "Blagar",
  'bev': "Daloa Bété",
  'bew': "Betawi",
  'bex': "Jur Modo",
  'bey': "Beli (Papua New Guinea)",
  'bez': "Bena (Tanzania)",
  'bfa': "Bari",
  'bfb': "Pauri Bareli",
  'bfc': "Northern Bai",
  'bfd': "Bafut",
  'bfe': "Tena",
  'bff': "Bofi",
  'bfg': "Busang Kayan",
  'bfh': "Blafe",
  'bfi': "British Sign Language",
  'bfj': "Bafanji",
  'bfk': "Ban Khor Sign Language",
  'bfl': "Banda-Ndélé",
  'bfm': "Mmen",
  'bfn': "Bunak",
  'bfo': "Malba Birifor",
  'bfp': "Beba",
  'bfq': "Badaga",
  'bfr': "Bazigar",
  'bfs': "Southern Bai",
  'bft': "Balti",
  'bfu': "Gahri",
  'bfw': "Bondo",
  'bfx': "Bantayanon",
  'bfy': "Bagheli",
  'bfz': "Mahasu Pahari",
  'bga': "Gwamhi-Wuri",
  'bgb': "Bobongko",
  'bgc': "Haryanvi",
  'bgd': "Rathwi Bareli",
  'bge': "Bauria",
  'bgf': "Bangandu",
  'bgg': "Bugun",
  'bgi': "Giangan",
  'bgj': "Bangolan",
  'bgk': "Buxinhua",
  'bgl': "Bo (Laos)",
  'bgm': "Baga Mboteni",
  'bgn': "Western Balochi",
  'bgo': "Baga Koga",
  'bgp': "Eastern Balochi",
  'bgq': "Bagri",
  'bgr': "Bawm Chin",
  'bgs': "Tagabawa",
  'bgt': "Bughotu",
  'bgu': "Mbongno",
  'bgv': "Warkay-Bipim",
  'bgw': "Bhatri",
  'bgx': "Balkan Gagauz Turkish",
  'bgy': "Benggoi",
  'bgz': "Banggai",
  'bha': "Bharia",
  'bhb': "Bhili",
  'bhc': "Biga",
  'bhd': "Bhadrawahi",
  'bhe': "Bhaya",
  'bhf': "Odiai",
  'bhg': "Binandere",
  'bhh': "Bukharic",
  'bhi': "Bhilali",
  'bhj': "Bahing",
  'bhk': "Albay Bicolano",
  'bhl': "Bimin",
  'bhm': "Bathari",
  'bhn': "Bohtan Neo-Aramaic",
  'bho': "Bhojpuri",
  'bhp': "Bima",
  'bhq': "Tukang Besi South",
  'bhr': "Bara Malagasy",
  'bhs': "Buwal",
  'bht': "Bhattiyali",
  'bhu': "Bhunjia",
  'bhv': "Bahau",
  'bhw': "Biak",
  'bhx': "Bhalay",
  'bhy': "Bhele",
  'bhz': "Bada (Indonesia)",
  'bia': "Badimaya",
  'bib': "Bisa",
  'bic': "Bikaru",
  'bid': "Bidiyo",
  'bie': "Bepour",
  'bif': "Biafada",
  'big': "Biangai",
  'bij': "Vaghat-Ya-Bijim-Legeri",
  'bik': "Bikol",
  'bil': "Bile",
  'bim': "Bimoba",
  'bin': "Edo",
  'bio': "Nai",
  'bip': "Bila",
  'biq': "Bipi",
  'bir': "Bisorio",
  'bit': "Berinomo",
  'biu': "Biete",
  'biv': "Southern Birifor",
  'biw': "Kol (Cameroon)",
  'bix': "Bijori",
  'biy': "Birhor",
  'biz': "Baloi",
  'bja': "Budza",
  'bjb': "Banggarla",
  'bjc': "Bariji",
  'bjd': "Bandjigali",
  'bje': "Biao-Jiao Mien",
  'bjf': "Barzani Jewish Neo-Aramaic",
  'bjg': "Bidyogo",
  'bjh': "Bahinemo",
  'bji': "Burji",
  'bjj': "Kanauji",
  'bjk': "Barok",
  'bjl': "Bulu (Papua New Guinea)",
  'bjm': "Bajelani",
  'bjn': "Banjar",
  'bjo': "Mid-Southern Banda",
  'bjp': "Fanamaket",
  'bjq': "Southern Betsimisaraka Malagasy",
  'bjr': "Binumarien",
  'bjs': "Bajan",
  'bjt': "Balanta-Ganja",
  'bju': "Busuu",
  'bjv': "Bedjond",
  'bjw': "Bakwé",
  'bjx': "Banao Itneg",
  'bjy': "Bayali",
  'bjz': "Baruga",
  'bka': "Kyak",
  'bkb': "Finallig",
  'bkc': "Baka (Cameroon)",
  'bkd': "Talaandig",
  'bkf': "Beeke",
  'bkg': "Buraka",
  'bkh': "Bakoko",
  'bki': "Baki",
  'bkj': "Pande",
  'bkk': "Brokskat",
  'bkl': "Berik",
  'bkm': "Kom (Cameroon)",
  'bkn': "Bukitan",
  'bko': "Kwa'",
  'bkp': "Boko (Democratic Republic of Congo)",
  'bkq': "Bakairí",
  'bkr': "Bakumpai",
  'bks': "Northern Sorsoganon",
  'bkt': "Boloki",
  'bku': "Buhid",
  'bkv': "Bekwarra",
  'bkw': "Bekwel",
  'bkx': "Baikeno",
  'bky': "Bokyi",
  'bkz': "Bungku",
  'bla': "Siksika",
  'blb': "Bilua",
  'blc': "Bella Coola",
  'bld': "Bolango",
  'ble': "Balanta-Kentohe",
  'blf': "Buol",
  'blg': "Balau",
  'blh': "Kuwaa",
  'bli': "Bolia",
  'blj': "Bolongan",
  'blk': "Pa'O",
  'bll': "Biloxi",
  'blm': "Beli (South Sudan)",
  'bln': "Southern Catanduanes Bikol",
  'blo': "Anii",
  'blp': "Blablanga",
  'blq': "Baluan-Pam",
  'blr': "Blang",
  'bls': "Balaesang",
  'blt': "Tai Dam",
  'blv': "Bolo",
  'blw': "Balangao",
  'blx': "Mag-Indi Ayta",
  'bly': "Notre",
  'blz': "Balantak",
  'bma': "Lame",
  'bmb': "Bembe",
  'bmc': "Biem",
  'bmd': "Baga Manduri",
  'bme': "Limassa",
  'bmf': "Bom-Kim",
  'bmg': "Bamwe",
  'bmh': "Kein",
  'bmi': "Bagirmi",
  'bmj': "Bote-Majhi",
  'bmk': "Ghayavi",
  'bml': "Bomboli",
  'bmm': "Northern Betsimisaraka Malagasy",
  'bmn': "Bina (Papua New Guinea)",
  'bmo': "Bambalang",
  'bmp': "Bulgebi",
  'bmq': "Bomu",
  'bmr': "Muinane",
  'bms': "Bilma Kanuri",
  'bmt': "Biao Mon",
  'bmu': "Somba-Siawari",
  'bmv': "Bum",
  'bmw': "Bomwali",
  'bmx': "Baimak",
  'bmy': "Bemba (Democratic Republic of Congo)",
  'bmz': "Baramu",
  'bna': "Bonerate",
  'bnb': "Bookan",
  'bnc': "Bontok",
  'bnd': "Banda (Indonesia)",
  'bne': "Bintauna",
  'bnf': "Masiwang",
  'bng': "Benga",
  'bni': "Bangi",
  'bnj': "Eastern Tawbuid",
  'bnk': "Bierebo",
  'bnl': "Boon",
  'bnm': "Batanga",
  'bnn': "Bunun",
  'bno': "Bantoanon",
  'bnp': "Bola",
  'bnq': "Bantik",
  'bnr': "Butmas-Tur",
  'bns': "Bundeli",
  'bnt': "Bantu languages",
  'bnu': "Bentong",
  'bnv': "Edwas",
  'bnw': "Bisis",
  'bnx': "Bangubangu",
  'bny': "Bintulu",
  'bnz': "Beezen",
  'boa': "Bora",
  'bob': "Aweer",
  'boe': "Mundabli",
  'bof': "Bolon",
  'bog': "Bamako Sign Language",
  'boh': "Boma",
  'boi': "Barbareño",
  'boj': "Anjam",
  'bok': "Bonjo",
  'bol': "Bole",
  'bom': "Berom",
  'bon': "Bine",
  'boo': "Tiemacèwè Bozo",
  'bop': "Bonkiman",
  'boq': "Bogaya",
  'bor': "Borôro",
  'bot': "Bongo",
  'bou': "Bondei",
  'bov': "Tuwuli",
  'bow': "Rema",
  'box': "Buamu",
  'boy': "Bodo (Central African Republic)",
  'boz': "Tiéyaxo Bozo",
  'bpa': "Daakaka",
  'bpb': "Barbacoas",
  'bpc': "Mbuk",
  'bpd': "Banda-Banda",
  'bpe': "Bauni",
  'bpg': "Bonggo",
  'bph': "Botlikh",
  'bpi': "Bagupi",
  'bpj': "Binji",
  'bpk': "'Ôrôê",
  'bpl': "Broome Pearling Lugger Pidgin",
  'bpm': "Biyom",
  'bpn': "Dzao Min",
  'bpo': "Anasi",
  'bpp': "Kaure",
  'bpq': "Banda Malay",
  'bpr': "Koronadal Blaan",
  'bps': "Sarangani Blaan",
  'bpt': "Barrow Point",
  'bpu': "Bongu",
  'bpv': "Bian Marind",
  'bpw': "Bo (Papua New Guinea)",
  'bpx': "Palya Bareli",
  'bpy': "Bishnupriya",
  'bpz': "Bilba",
  'bqa': "Tchumbuli",
  'bqb': "Bagusa",
  'bqc': "Boo",
  'bqd': "Bung",
  'bqf': "Baga Kaloum",
  'bqg': "Bago-Kusuntu",
  'bqh': "Baima",
  'bqi': "Bakhtiari",
  'bqj': "Bandial",
  'bqk': "Banda-Mbrès",
  'bql': "Bilakura",
  'bqm': "Wumboko",
  'bqn': "Bulgarian Sign Language",
  'bqo': "Balo",
  'bqp': "Busa",
  'bqq': "Biritai",
  'bqr': "Burusu",
  'bqs': "Bosngun",
  'bqt': "Bamukumbit",
  'bqu': "Boguru",
  'bqv': "Begbere-Ejar",
  'bqw': "Buru (Nigeria)",
  'bqx': "Baangi",
  'bqy': "Bengkala Sign Language",
  'bqz': "Bakaka",
  'bra': "Braj",
  'brb': "Lave",
  'brc': "Berbice Creole Dutch",
  'brd': "Baraamu",
  'brf': "Bira",
  'brg': "Baure",
  'brh': "Brahui",
  'bri': "Mokpwe",
  'brj': "Bieria",
  'brk': "Birked",
  'brl': "Birwa",
  'brm': "Barambu",
  'brn': "Boruca",
  'bro': "Brokkat",
  'brp': "Barapasi",
  'brq': "Breri",
  'brr': "Birao",
  'brs': "Baras",
  'brt': "Bitare",
  'bru': "Eastern Bru",
  'brv': "Western Bru",
  'brw': "Bellari",
  'brx': "Bodo (India)",
  'bry': "Burui",
  'brz': "Bilbil",
  'bsa': "Abinomn",
  'bsb': "Brunei Bisaya",
  'bsc': "Oniyan",
  'bse': "Wushi",
  'bsf': "Bauchi",
  'bsg': "Bashkardi",
  'bsh': "Kati",
  'bsi': "Bassossi",
  'bsj': "Bangwinji",
  'bsk': "Burushaski",
  'bsl': "Basa-Gumna",
  'bsm': "Busami",
  'bsn': "Barasana-Eduria",
  'bso': "Buso",
  'bsp': "Baga Sitemu",
  'bsq': "Bassa",
  'bsr': "Bassa-Kontagora",
  'bss': "Akoose",
  'bst': "Basketo",
  'bsu': "Bahonsuai",
  'bsv': "Baga Sobané",
  'bsw': "Baiso",
  'bsx': "Yangkam",
  'bsy': "Sabah Bisaya",
  'bta': "Bata",
  'btb': "Beti (Cameroon)",
  'btc': "Bati (Cameroon)",
  'btd': "Batak Dairi",
  'bte': "Gamo-Ningi",
  'btf': "Birgit",
  'btg': "Gagnoa Bété",
  'bth': "Biatah Bidayuh",
  'bti': "Burate",
  'btj': "Bacanese Malay",
  'btk': "Batak languages",
  'btl': "Bhatola",
  'btm': "Batak Mandailing",
  'btn': "Ratagnon",
  'bto': "Rinconada Bikol",
  'btp': "Budibud",
  'btq': "Batek",
  'btr': "Baetora",
  'bts': "Batak Simalungun",
  'btt': "Bete-Bendi",
  'btu': "Batu",
  'btv': "Bateri",
  'btw': "Butuanon",
  'btx': "Batak Karo",
  'bty': "Bobot",
  'btz': "Batak Alas-Kluet",
  'bua': "Buriat",
  'bub': "Bua",
  'buc': "Bushi",
  'bud': "Ntcham",
  'bue': "Beothuk",
  'buf': "Bushoong",
  'bug': "Buginese",
  'buh': "Younuo Bunu",
  'bui': "Bongili",
  'buj': "Basa-Gurmana",
  'buk': "Bugawac",
  'bum': "Bulu (Cameroon)",
  'bun': "Sherbro",
  'buo': "Terei",
  'bup': "Busoa",
  'buq': "Brem",
  'bus': "Bokobaru",
  'but': "Bungain",
  'buu': "Budu",
  'buv': "Bun",
  'buw': "Bubi",
  'bux': "Boghom",
  'buy': "Bullom So",
  'buz': "Bukwen",
  'bva': "Barein",
  'bvb': "Bube",
  'bvc': "Baelelea",
  'bvd': "Baeggu",
  'bve': "Berau Malay",
  'bvf': "Boor",
  'bvg': "Bonkeng",
  'bvh': "Bure",
  'bvi': "Belanda Viri",
  'bvj': "Baan",
  'bvk': "Bukat",
  'bvl': "Bolivian Sign Language",
  'bvm': "Bamunka",
  'bvn': "Buna",
  'bvo': "Bolgo",
  'bvp': "Bumang",
  'bvq': "Birri",
  'bvr': "Burarra",
  'bvt': "Bati (Indonesia)",
  'bvu': "Bukit Malay",
  'bvv': "Baniva",
  'bvw': "Boga",
  'bvx': "Dibole",
  'bvy': "Baybayanon",
  'bvz': "Bauzi",
  'bwa': "Bwatoo",
  'bwb': "Namosi-Naitasiri-Serua",
  'bwc': "Bwile",
  'bwd': "Bwaidoka",
  'bwe': "Bwe Karen",
  'bwf': "Boselewa",
  'bwg': "Barwe",
  'bwh': "Bishuo",
  'bwi': "Baniwa",
  'bwj': "Láá Láá Bwamu",
  'bwk': "Bauwaki",
  'bwl': "Bwela",
  'bwm': "Biwat",
  'bwn': "Wunai Bunu",
  'bwo': "Borna (Ethiopia)",
  'bwp': "Mandobo Bawah",
  'bwq': "Southern Bobo Madaré",
  'bwr': "Bura-Pabir",
  'bws': "Bomboma",
  'bwt': "Bafaw-Balong",
  'bwu': "Buli (Ghana)",
  'bww': "Bwa",
  'bwx': "Bu-Nao Bunu",
  'bwy': "Cwi Bwamu",
  'bwz': "Bwisi",
  'bxa': "Tairaha",
  'bxb': "Belanda Bor",
  'bxc': "Molengue",
  'bxd': "Pela",
  'bxe': "Birale",
  'bxf': "Minigir",
  'bxg': "Bangala",
  'bxh': "Buhutu",
  'bxi': "Pirlatapa",
  'bxj': "Bayungu",
  'bxk': "Lubukusu",
  'bxl': "Jalkunan",
  'bxm': "Mongolia Buriat",
  'bxn': "Burduna",
  'bxo': "Barikanchi",
  'bxp': "Bebil",
  'bxq': "Beele",
  'bxr': "Russia Buriat",
  'bxs': "Busam",
  'bxu': "China Buriat",
  'bxv': "Berakou",
  'bxw': "Bankagooma",
  'bxx': "Borna (Democratic Republic of Congo)",
  'bxz': "Binahari",
  'bya': "Batak",
  'byb': "Bikya",
  'byc': "Ubaghara",
  'byd': "Benyadu'",
  'bye': "Pouye",
  'byf': "Bete",
  'byg': "Baygo",
  'byh': "Bhujel",
  'byi': "Buyu",
  'byj': "Bina (Nigeria)",
  'byk': "Biao",
  'byl': "Bayono",
  'bym': "Bidjara",
  'byn': "Blin",
  'byo': "Biyo",
  'byp': "Bumaji",
  'byq': "Basay",
  'byr': "Yipma",
  'bys': "Burak",
  'byt': "Berti",
  'byv': "Medumba",
  'byw': "Belhariya",
  'byx': "Qaqet",
  'byy': "Buya",
  'byz': "Banaro",
  'bza': "Bandi",
  'bzb': "Andio",
  'bzc': "Southern Betsimisaraka Malagasy",
  'bzd': "Bribri",
  'bze': "Jenaama Bozo",
  'bzf': "Boikin",
  'bzg': "Babuza",
  'bzh': "Mapos Buang",
  'bzi': "Bisu",
  'bzj': "Belize Kriol English",
  'bzk': "Nicaragua Creole English",
  'bzl': "Boano (Sulawesi)",
  'bzm': "Bolondo",
  'bzn': "Boano (Maluku)",
  'bzo': "Bozaba",
  'bzp': "Kemberano",
  'bzq': "Buli (Indonesia)",
  'bzr': "Biri",
  'bzs': "Brazilian Sign Language",
  'bzt': "Brithenig",
  'bzu': "Burmeso",
  'bzv': "Naami",
  'bzw': "Basa (Nigeria)",
  'bzx': "Kɛlɛngaxo Bozo",
  'bzy': "Obanliku",
  'bzz': "Evant",
  'caa': "Chortí",
  'cab': "Garifuna",
  'cac': "Chuj",
  'cad': "Caddo",
  'cae': "Laalaa",
  'caf': "Southern Carrier",
  'cag': "Nivaclé",
  'cah': "Cahuarano",
  'cai': "Central American Indian languages",
  'caj': "Chané",
  'cak': "Cakchiquel",
  'cal': "Carolinian",
  'cam': "Cemuhî",
  'can': "Chambri",
  'cao': "Chácobo",
  'cap': "Chipaya",
  'caq': "Car Nicobarese",
  'car': "Galibi Carib",
  'cas': "Tsimané",
  'cau': "Caucasian languages",
  'cav': "Cavineña",
  'caw': "Callawalla",
  'cax': "Chiquitano",
  'cay': "Cayuga",
  'caz': "Canichana",
  'cba': "Chibchan languages",
  'cbb': "Cabiyarí",
  'cbc': "Carapana",
  'cbd': "Carijona",
  'cbe': "Chipiajes",
  'cbg': "Chimila",
  'cbh': "Cagua",
  'cbi': "Chachi",
  'cbj': "Ede Cabe",
  'cbk': "Chavacano",
  'cbl': "Bualkhaw Chin",
  'cbn': "Nyahkur",
  'cbo': "Izora",
  'cbq': "Cuba",
  'cbr': "Cashibo-Cacataibo",
  'cbs': "Cashinahua",
  'cbt': "Chayahuita",
  'cbu': "Candoshi-Shapra",
  'cbv': "Cacua",
  'cbw': "Kinabalian",
  'cby': "Carabayo",
  'cca': "Cauca",
  'ccc': "Chamicuro",
  'ccd': "Cafundo Creole",
  'cce': "Chopi",
  'ccg': "Samba Daka",
  'cch': "Atsam",
  'ccj': "Kasanga",
  'ccl': "Cutchi-Swahili",
  'ccm': "Malaccan Creole Malay",
  'ccn': "North Caucasian languages",
  'cco': "Comaltepec Chinantec",
  'ccp': "Chakma",
  'ccq': "Chaungtha",
  'ccr': "Cacaopera",
  'ccs': "South Caucasian languages",
  'cda': "Choni",
  'cdc': "Chadic languages",
  'cdd': "Caddoan languages",
  'cde': "Chenchu",
  'cdf': "Chiru",
  'cdg': "Chamari",
  'cdh': "Chambeali",
  'cdi': "Chodri",
  'cdj': "Churahi",
  'cdm': "Chepang",
  'cdn': "Chaudangsi",
  'cdo': "Min Dong Chinese",
  'cdr': "Cinda-Regi-Tiyal",
  'cds': "Chadian Sign Language",
  'cdy': "Chadong",
  'cdz': "Koda",
  'cea': "Lower Chehalis",
  'ceb': "Cebuano",
  'ceg': "Chamacoco",
  'cek': "Eastern Khumi Chin",
  'cel': "Celtic languages",
  'cen': "Cen",
  'cet': "Centúúm",
  'cey': "Ekai Chin",
  'cfa': "Dijim-Bwilim",
  'cfd': "Cara",
  'cfg': "Como Karim",
  'cfm': "Falam Chin",
  'cga': "Changriwa",
  'cgc': "Kagayanen",
  'cgg': "Chiga",
  'cgk': "Chocangacakha",
  'chb': "Chibcha",
  'chc': "Catawba",
  'chd': "Highland Oaxaca Chontal",
  'chf': "Tabasco Chontal",
  'chg': "Chagatai",
  'chh': "Chinook",
  'chj': "Ojitlán Chinantec",
  'chk': "Chuukese",
  'chl': "Cahuilla",
  'chm': "Mari (Russia)",
  'chn': "Chinook jargon",
  'cho': "Choctaw",
  'chp': "Dene Suline",
  'chq': "Quiotepec Chinantec",
  'chr': "Cherokee",
  'cht': "Cholón",
  'chw': "Chuwabu",
  'chx': "Chantyal",
  'chy': "Cheyenne",
  'chz': "Ozumacín Chinantec",
  'cia': "Cia-Cia",
  'cib': "Ci Gbe",
  'cic': "Chickasaw",
  'cid': "Chimariko",
  'cie': "Cineni",
  'cih': "Chinali",
  'cik': "Chitkuli Kinnauri",
  'cim': "Cimbrian",
  'cin': "Cinta Larga",
  'cip': "Chiapanec",
  'cir': "Méa",
  'ciw': "Chippewa",
  'ciy': "Chaima",
  'cja': "Western Cham",
  'cje': "Chru",
  'cjh': "Upper Chehalis",
  'cji': "Chamalal",
  'cjk': "Chokwe",
  'cjm': "Eastern Cham",
  'cjn': "Chenapian",
  'cjo': "Ashéninka Pajonal",
  'cjp': "Cabécar",
  'cjr': "Chorotega",
  'cjs': "Shor",
  'cjv': "Chuave",
  'cjy': "Jinyu Chinese",
  'cka': "Khumi Awa Chin",
  'ckb': "Central Kurdish",
  'ckh': "Chak",
  'ckl': "Cibak",
  'ckm': "Chakavian",
  'ckn': "Kaang Chin",
  'cko': "Anufo",
  'ckq': "Kajakse",
  'ckr': "Kairak",
  'cks': "Tayo",
  'ckt': "Chukot",
  'cku': "Koasati",
  'ckv': "Kavalan",
  'ckx': "Caka",
  'cky': "Cakfem-Mushere",
  'ckz': "Cakchiquel-Quiché Mixed Language",
  'cla': "Ron",
  'clc': "Chilcotin",
  'cld': "Chaldean Neo-Aramaic",
  'cle': "Lealao Chinantec",
  'clh': "Chilisso",
  'cli': "Chakali",
  'clj': "Laitu Chin",
  'clk': "Idu-Mishmi",
  'cll': "Chala",
  'clm': "Clallam",
  'clo': "Lowland Oaxaca Chontal",
  'clt': "Lautu Chin",
  'clu': "Caluyanun",
  'clw': "Chulym",
  'cly': "Eastern Highland Chatino",
  'cma': "Maa",
  'cmc': "Chamic languages",
  'cme': "Cerma",
  'cmg': "Classical Mongolian",
  'cmi': "Emberá-Chamí",
  'cmk': "Chimakum",
  'cml': "Campalagian",
  'cmm': "Michigamea",
  'cmn': "Mandarin Chinese",
  'cmo': "Central Mnong",
  'cmr': "Mro-Khimi Chin",
  'cms': "Messapic",
  'cmt': "Camtho",
  'cna': "Changthang",
  'cnb': "Chinbon Chin",
  'cnc': "Côông",
  'cng': "Northern Qiang",
  'cnh': "Haka Chin",
  'cni': "Asháninka",
  'cnk': "Khumi Chin",
  'cnl': "Lalana Chinantec",
  'cno': "Con",
  'cnp': "Northern Pinghua",
  'cnq': "Chung",
  'cnr': "Montenegrin",
  'cns': "Central Asmat",
  'cnt': "Tepetotutla Chinantec",
  'cnu': "Chenoua",
  'cnw': "Ngawn Chin",
  'cnx': "Middle Cornish",
  'coa': "Cocos Islands Malay",
  'cob': "Chicomuceltec",
  'coc': "Cocopa",
  'cod': "Cocama-Cocamilla",
  'coe': "Koreguaje",
  'cof': "Colorado",
  'cog': "Chong",
  'coh': "Chichonyi-Chidzihana-Chikauma",
  'coj': "Cochimi",
  'cok': "Santa Teresa Cora",
  'col': "Columbia-Wenatchi",
  'com': "Comanche",
  'con': "Cofán",
  'coo': "Comox",
  'cop': "Coptic",
  'coq': "Coquille",
  'cot': "Caquinte",
  'cou': "Wamey",
  'cov': "Cao Miao",
  'cow': "Cowlitz",
  'cox': "Nanti",
  'coy': "Coyaima",
  'coz': "Chochotec",
  'cpa': "Palantla Chinantec",
  'cpb': "Ucayali-Yurúa Ashéninka",
  'cpc': "Ajyíninka Apurucayali",
  'cpe': "English-based creoles and pidgins",
  'cpf': "French-based creoles and pidgins",
  'cpg': "Cappadocian Greek",
  'cpi': "Chinese Pidgin English",
  'cpn': "Cherepon",
  'cpo': "Kpeego",
  'cpp': "Portuguese-based creoles and pidgins",
  'cps': "Capiznon",
  'cpu': "Pichis Ashéninka",
  'cpx': "Pu-Xian Chinese",
  'cpy': "South Ucayali Ashéninka",
  'cqd': "Chuanqiandian Cluster Miao",
  'cqu': "Chilean Quechua",
  'cra': "Chara",
  'crb': "Island Carib",
  'crc': "Lonwolwol",
  'crd': "Coeur d'Alene",
  'crf': "Caramanta",
  'crg': "Michif",
  'crh': "Crimean Turkish",
  'cri': "Sãotomense",
  'crj': "Southern East Cree",
  'crk': "Plains Cree",
  'crl': "Northern East Cree",
  'crm': "Moose Cree",
  'crn': "El Nayar Cora",
  'cro': "Crow",
  'crp': "Creoles and pidgins",
  'crq': "Iyo'wujwa Chorote",
  'crr': "Carolina Algonquian",
  'crs': "Seselwa Creole French",
  'crt': "Iyojwa'ja Chorote",
  'crv': "Chaura",
  'crw': "Chrau",
  'crx': "Carrier",
  'cry': "Cori",
  'crz': "Cruzeño",
  'csa': "Chiltepec Chinantec",
  'csb': "Kashubian",
  'csc': "Llengua de Signes Catalana",
  'csd': "Chiangmai Sign Language",
  'cse': "Czech Sign Language",
  'csf': "Cuba Sign Language",
  'csg': "Chilean Sign Language",
  'csh': "Asho Chin",
  'csi': "Coast Miwok",
  'csj': "Songlai Chin",
  'csk': "Jola-Kasa",
  'csl': "Chinese Sign Language",
  'csm': "Central Sierra Miwok",
  'csn': "Colombian Sign Language",
  'cso': "Sochiapan Chinantec",
  'csp': "Southern Pinghua",
  'csq': "Croatia Sign Language",
  'csr': "Costa Rican Sign Language",
  'css': "Southern Ohlone",
  'cst': "Northern Ohlone",
  'csu': "Central Sudanic languages",
  'csv': "Sumtu Chin",
  'csw': "Swampy Cree",
  'csx': "Cambodian Sign Language",
  'csy': "Siyin Chin",
  'csz': "Coos",
  'cta': "Tataltepec Chatino",
  'ctc': "Chetco",
  'ctd': "Tedim Chin",
  'cte': "Tepinapa Chinantec",
  'ctg': "Chittagonian",
  'cth': "Thaiphum Chin",
  'ctl': "Tlacoatzintepec Chinantec",
  'ctm': "Chitimacha",
  'ctn': "Chhintange",
  'cto': "Emberá-Catío",
  'ctp': "Western Highland Chatino",
  'cts': "Northern Catanduanes Bikol",
  'ctt': "Wayanad Chetti",
  'ctu': "Chol",
  'cty': "Moundadan Chetty",
  'ctz': "Zacatepec Chatino",
  'cua': "Cua",
  'cub': "Cubeo",
  'cuc': "Usila Chinantec",
  'cug': "Cung",
  'cuh': "Gichuka",
  'cui': "Cuiba",
  'cuj': "Mashco Piro",
  'cuk': "San Blas Kuna",
  'cul': "Kulina",
  'cum': "Cumeral",
  'cuo': "Cumanagoto",
  'cup': "Cupeño",
  'cuq': "Cun",
  'cur': "Chhulung",
  'cus': "Cushitic languages",
  'cut': "Teutila Cuicatec",
  'cuu': "Tai Ya",
  'cuv': "Cuvok",
  'cuw': "Chukwa",
  'cux': "Tepeuxila Cuicatec",
  'cuy': "Cuitlatec",
  'cvg': "Chug",
  'cvn': "Valle Nacional Chinantec",
  'cwa': "Kabwa",
  'cwb': "Maindo",
  'cwd': "Woods Cree",
  'cwe': "Kwere",
  'cwg': "Cheq Wong",
  'cwt': "Kuwaataay",
  'cxh': "Cha'ari",
  'cya': "Nopala Chatino",
  'cyb': "Cayubaba",
  'cyo': "Cuyonon",
  'czh': "Huizhou Chinese",
  'czk': "Knaanic",
  'czn': "Zenzontepec Chatino",
  'czo': "Min Zhong Chinese",
  'czt': "Zotung Chin",
  'daa': "Dangaléat",
  'dac': "Dambi",
  'dad': "Marik",
  'dae': "Duupa",
  'daf': "Dan",
  'dag': "Dagbani",
  'dah': "Gwahatike",
  'dai': "Day",
  'daj': "Dar Fur Daju",
  'dak': "Dakota",
  'dal': "Dahalo",
  'dam': "Damakawa",
  'dao': "Daai Chin",
  'dap': "Nisi (India)",
  'daq': "Dandami Maria",
  'dar': "Dargwa",
  'das': "Daho-Doo",
  'dau': "Dar Sila Daju",
  'dav': "Dawida",
  'daw': "Davawenyo",
  'dax': "Dayi",
  'day': "Land Dayak languages",
  'daz': "Dao",
  'dba': "Bangime",
  'dbb': "Deno",
  'dbd': "Dadiya",
  'dbe': "Dabe",
  'dbf': "Edopi",
  'dbg': "Dogul Dom Dogon",
  'dbi': "Doka",
  'dbj': "Ida'an",
  'dbl': "Dyirbal",
  'dbm': "Duguri",
  'dbn': "Duriankere",
  'dbo': "Dulbu",
  'dbp': "Duwai",
  'dbq': "Daba",
  'dbr': "Dabarre",
  'dbt': "Ben Tey Dogon",
  'dbu': "Bondum Dom Dogon",
  'dbv': "Dungu",
  'dbw': "Bankan Tey Dogon",
  'dby': "Dibiyaso",
  'dcc': "Deccan",
  'dcr': "Negerhollands",
  'dda': "Dadi Dadi",
  'ddd': "Dongotono",
  'dde': "Doondo",
  'ddg': "Fataluku",
  'ddi': "West Goodenough",
  'ddj': "Jaru",
  'ddn': "Dendi (Benin)",
  'ddo': "Dido",
  'ddr': "Dhudhuroa",
  'dds': "Donno So Dogon",
  'ddw': "Dawera-Daweloor",
  'dec': "Dagik",
  'ded': "Dedua",
  'dee': "Dewoin",
  'def': "Dezfuli",
  'deg': "Degema",
  'deh': "Dehwari",
  'dei': "Demisa",
  'dek': "Dek",
  'del': "Delaware",
  'dem': "Dem",
  'den': "Slave (Athapascan)",
  'dep': "Pidgin Delaware",
  'deq': "Dendi (Central African Republic)",
  'der': "Deori",
  'des': "Desano",
  'dev': "Domung",
  'dez': "Dengese",
  'dga': "Southern Dagaare",
  'dgb': "Bunoge Dogon",
  'dgc': "Casiguran Dumagat Agta",
  'dgd': "Dagaari Dioula",
  'dge': "Degenan",
  'dgg': "Doga",
  'dgh': "Dghwede",
  'dgi': "Northern Dagara",
  'dgk': "Dagba",
  'dgl': "Dongolawi",
  'dgn': "Dagoman",
  'dgo': "Dogri (individual language)",
  'dgr': "Tłı̨chǫ",
  'dgs': "Dogoso",
  'dgt': "Ndra'ngith",
  'dgu': "Degaru",
  'dgw': "Daungwurrung",
  'dgx': "Doghoro",
  'dgz': "Daga",
  'dha': "Dhanwar (India)",
  'dhd': "Dhundari",
  'dhg': "Djangu",
  'dhi': "Dhimal",
  'dhl': "Dhalandji",
  'dhm': "Zemba",
  'dhn': "Dhanki",
  'dho': "Dhodia",
  'dhr': "Dhargari",
  'dhs': "Dhaiso",
  'dhu': "Dhurga",
  'dhv': "Drehu",
  'dhw': "Dhanwar (Nepal)",
  'dhx': "Dhungaloo",
  'dia': "Dia",
  'dib': "South Central Dinka",
  'dic': "Lakota Dida",
  'did': "Didinga",
  'dif': "Diyari",
  'dig': "Chidigo",
  'dih': "Kumiai",
  'dii': "Dimbong",
  'dij': "Dai",
  'dik': "Southwestern Dinka",
  'dil': "Dilling",
  'dim': "Dime",
  'din': "Dinka",
  'dio': "Dibo",
  'dip': "Northeastern Dinka",
  'diq': "Dimli (individual language)",
  'dir': "Dirim",
  'dis': "Dimasa",
  'dit': "Dirari",
  'diu': "Diriku",
  'diw': "Northwestern Dinka",
  'dix': "Dixon Reef",
  'diy': "Diuwe",
  'diz': "Ding",
  'dja': "Djadjawurrung",
  'djb': "Djinba",
  'djc': "Dar Daju Daju",
  'djd': "Ngaliwurru",
  'dje': "Zarma",
  'djf': "Djangun",
  'dji': "Djinang",
  'djj': "Djeebbana",
  'djk': "Nenge",
  'djl': "Djiwarli",
  'djm': "Jamsay Dogon",
  'djn': "Djauan",
  'djo': "Jangkang",
  'djr': "Djambarrpuyngu",
  'dju': "Kapriman",
  'djw': "Djawi",
  'dka': "Dakpakha",
  'dkg': "Kadung",
  'dkk': "Dakka",
  'dkl': "Kolum So Dogon",
  'dkr': "Kuijau",
  'dks': "Southeastern Dinka",
  'dkx': "Mazagway",
  'dlg': "Dolgan",
  'dlk': "Dahalik",
  'dlm': "Dalmatian",
  'dln': "Darlong",
  'dma': "Duma",
  'dmb': "Mombo Dogon",
  'dmc': "Gavak",
  'dmd': "Madhi Madhi",
  'dme': "Dugwor",
  'dmf': "Medefaidrin",
  'dmg': "Upper Kinabatangan",
  'dmk': "Domaaki",
  'dml': "Dameli",
  'dmm': "Dama",
  'dmn': "Mande languages",
  'dmo': "Kemedzung",
  'dmr': "East Damar",
  'dms': "Dampelas",
  'dmu': "Tebi",
  'dmv': "Dumpas",
  'dmw': "Mudburra",
  'dmx': "Dema",
  'dmy': "Sowari",
  'dna': "Upper Grand Valley Dani",
  'dnd': "Daonda",
  'dne': "Ndendeule",
  'dng': "Dungan",
  'dni': "Lower Grand Valley Dani",
  'dnj': "Dan",
  'dnk': "Dengka",
  'dnn': "Dzùùngoo",
  'dno': "Northern Lendu",
  'dnr': "Danaru",
  'dnt': "Mid Grand Valley Dani",
  'dnu': "Danau",
  'dnv': "Danu",
  'dnw': "Western Dani",
  'dny': "Dení",
  'doa': "Dom",
  'dob': "Dobu",
  'doc': "Northern Dong",
  'doe': "Doe",
  'dof': "Domu",
  'doh': "Dong",
  'doi': "Dogri (macrolanguage)",
  'dok': "Dondo",
  'dol': "Doso",
  'don': "Toura (Papua New Guinea)",
  'doo': "Dongo",
  'dop': "Lukpa",
  'doq': "Dominican Sign Language",
  'dor': "Dori'o",
  'dos': "Dogosé",
  'dot': "Dass",
  'dov': "Dombe",
  'dow': "Doyayo",
  'dox': "Bussa",
  'doy': "Dompo",
  'doz': "Dorze",
  'dpp': "Papar",
  'dra': "Dravidian languages",
  'drb': "Dair",
  'drc': "Minderico",
  'drd': "Darmiya",
  'dre': "Dolpo",
  'drg': "Rungus",
  'drh': "Darkhat",
  'dri': "C'Lela",
  'drl': "Paakantyi",
  'drn': "West Damar",
  'dro': "Daro-Matu Melanau",
  'drq': "Dura",
  'drr': "Dororo",
  'drs': "Gedeo",
  'drt': "Drents",
  'dru': "Rukai",
  'drw': "Darwazi",
  'dry': "Darai",
  'dsb': "Lower Sorbian",
  'dse': "Dutch Sign Language",
  'dsh': "Daasanach",
  'dsi': "Disa",
  'dsk': "Dokshi",
  'dsl': "Danish Sign Language",
  'dsn': "Dusner",
  'dso': "Desiya",
  'dsq': "Tadaksahak",
  'dsz': "Mardin Sign Language",
  'dta': "Daur",
  'dtb': "Labuk-Kinabatangan Kadazan",
  'dtd': "Ditidaht",
  'dth': "Adithinngithigh",
  'dti': "Ana Tinga Dogon",
  'dtk': "Tene Kan Dogon",
  'dtm': "Tomo Kan Dogon",
  'dtn': "Daatsʼíin",
  'dto': "Tommo So Dogon",
  'dtp': "Central Dusun",
  'dtr': "Lotud",
  'dts': "Toro So Dogon",
  'dtt': "Toro Tegu Dogon",
  'dtu': "Tebul Ure Dogon",
  'dty': "Dotyali",
  'dua': "Duala",
  'dub': "Dubli",
  'duc': "Duna",
  'dud': "Hun-Saare",
  'due': "Umiray Dumaget Agta",
  'duf': "Drubea",
  'dug': "Chiduruma",
  'duh': "Dungra Bhil",
  'dui': "Dumun",
  'duj': "Dhuwal",
  'duk': "Uyajitaya",
  'dul': "Alabat Island Agta",
  'dum': "Middle Dutch (ca. 1050-1350)",
  'dun': "Dusun Deyah",
  'duo': "Dupaninan Agta",
  'dup': "Duano",
  'duq': "Dusun Malang",
  'dur': "Dii",
  'dus': "Dumi",
  'duu': "Drung",
  'duv': "Duvle",
  'duw': "Dusun Witu",
  'dux': "Duungooma",
  'duy': "Dicamay Agta",
  'duz': "Duli-Gey",
  'dva': "Duau",
  'dwa': "Diri",
  'dwk': "Dawik Kui",
  'dwl': "Walo Kumbe Dogon",
  'dwr': "Dawro",
  'dws': "Dutton World Speedwords",
  'dwu': "Dhuwal",
  'dww': "Dawawa",
  'dwy': "Dhuwaya",
  'dwz': "Dewas Rai",
  'dya': "Dyan",
  'dyb': "Dyaberdyaber",
  'dyd': "Dyugun",
  'dyg': "Villa Viciosa Agta",
  'dyi': "Djimini Senoufo",
  'dym': "Yanda Dom Dogon",
  'dyn': "Dhanggatti",
  'dyo': "Jola-Fonyi",
  'dyr': "Dyarim",
  'dyu': "Dyula",
  'dyy': "Dyaabugay",
  'dza': "Tunzu",
  'dzd': "Daza",
  'dze': "Djiwarli",
  'dzg': "Dazaga",
  'dzl': "Dzalakha",
  'dzn': "Dzando",
  'eaa': "Karenggapa",
  'ebc': "Beginci",
  'ebg': "Ebughu",
  'ebk': "Eastern Bontok",
  'ebo': "Teke-Ebo",
  'ebr': "Ebrié",
  'ebu': "Kiembu",
  'ecr': "Eteocretan",
  'ecs': "Ecuadorian Sign Language",
  'ecy': "Eteocypriot",
  'eee': "E",
  'efa': "Efai",
  'efe': "Efe",
  'efi': "Efik",
  'ega': "Ega",
  'egl': "Emilian",
  'egm': "Benamanga",
  'ego': "Eggon",
  'egx': "Egyptian languages",
  'egy': "Egyptian (Ancient)",
  'ehs': "Miyakubo Sign Language",
  'ehu': "Ehueun",
  'eip': "Eipomek",
  'eit': "Eitiep",
  'eiv': "Askopan",
  'eja': "Ejamat",
  'eka': "Ekajuk",
  'ekc': "Eastern Karnic",
  'eke': "Ekit",
  'ekg': "Ekari",
  'eki': "Eki",
  'ekk': "Standard Estonian",
  'ekl': "Kol",
  'ekm': "Elip",
  'eko': "Koti",
  'ekp': "Ekpeye",
  'ekr': "Yace",
  'eky': "Eastern Kayah",
  'ele': "Elepi",
  'elh': "El Hugeirat",
  'eli': "Nding",
  'elk': "Elkei",
  'elm': "Eleme",
  'elo': "El Molo",
  'elp': "Elpaputih",
  'elu': "Elu",
  'elx': "Elamite",
  'ema': "Emai-Iuleha-Ora",
  'emb': "Embaloh",
  'eme': "Emerillon",
  'emg': "Eastern Meohang",
  'emi': "Mussau-Emira",
  'emk': "Eastern Maninkakan",
  'emm': "Mamulique",
  'emn': "Eman",
  'emo': "Emok",
  'emp': "Northern Emberá",
  'emq': "Eastern Minyag",
  'ems': "Pacific Gulf Yupik",
  'emu': "Eastern Muria",
  'emw': "Emplawas",
  'emx': "Erromintxela",
  'emy': "Epigraphic Mayan",
  'emz': "Mbessa",
  'ena': "Apali",
  'enb': "Markweeta",
  'enc': "En",
  'end': "Ende",
  'enf': "Forest Enets",
  'enh': "Tundra Enets",
  'enl': "Enlhet",
  'enm': "Middle English (1100-1500)",
  'enn': "Engenni",
  'eno': "Enggano",
  'enq': "Enga",
  'enr': "Emem",
  'enu': "Enu",
  'env': "Enwan (Edo State)",
  'enw': "Enwan (Akwa Ibom State)",
  'enx': "Enxet",
  'eot': "Beti (Côte d'Ivoire)",
  'epi': "Epie",
  'era': "Eravallan",
  'erg': "Sie",
  'erh': "Eruwa",
  'eri': "Ogea",
  'erk': "South Efate",
  'ero': "Horpa",
  'err': "Erre",
  'ers': "Ersu",
  'ert': "Eritai",
  'erw': "Erokwanas",
  'ese': "Ese Ejja",
  'esg': "Aheri Gondi",
  'esh': "Eshtehardi",
  'esi': "North Alaskan Inupiatun",
  'esk': "Northwest Alaska Inupiatun",
  'esl': "Egypt Sign Language",
  'esm': "Esuma",
  'esn': "Salvadoran Sign Language",
  'eso': "Estonian Sign Language",
  'esq': "Esselen",
  'ess': "Central Siberian Yupik",
  'esu': "Central Yupik",
  'esx': "Eskimo-Aleut languages",
  'esy': "Eskayan",
  'etb': "Etebi",
  'etc': "Etchemin",
  'eth': "Ethiopian Sign Language",
  'etn': "Eton (Vanuatu)",
  'eto': "Eton (Cameroon)",
  'etr': "Edolo",
  'ets': "Yekhee",
  'ett': "Etruscan",
  'etu': "Ejagham",
  'etx': "Eten",
  'etz': "Semimi",
  'eud': "Eudeve",
  'euq': "Basque (family)",
  'eve': "Even",
  'evh': "Uvbie",
  'evn': "Evenki",
  'ewo': "Ewondo",
  'ext': "Extremaduran",
  'eya': "Eyak",
  'eyo': "Keiyo",
  'eza': "Ezaa",
  'eze': "Uzekwe",
  'faa': "Fasu",
  'fab': "Fa d'Ambu",
  'fad': "Wagi",
  'faf': "Fagani",
  'fag': "Finongan",
  'fah': "Baissa Fali",
  'fai': "Faiwol",
  'faj': "Faita",
  'fak': "Fang (Cameroon)",
  'fal': "South Fali",
  'fam': "Fam",
  'fan': "Fang (Equatorial Guinea)",
  'fap': "Paloor",
  'far': "Fataleka",
  'fat': "Fanti",
  'fau': "Fayu",
  'fax': "Fala",
  'fay': "Southwestern Fars",
  'faz': "Northwestern Fars",
  'fbl': "West Albay Bikol",
  'fcs': "Quebec Sign Language",
  'fer': "Feroge",
  'ffi': "Foia Foia",
  'ffm': "Maasina Fulfulde",
  'fgr': "Fongoro",
  'fia': "Nobiin",
  'fie': "Fyer",
  'fif': "Faifi",
  'fil': "Pilipino",
  'fip': "Fipa",
  'fir': "Firan",
  'fit': "Meänkieli",
  'fiu': "Finno-Ugrian languages",
  'fiw': "Fiwaga",
  'fkk': "Kirya-Konzəl",
  'fkv': "Kven Finnish",
  'fla': "Kalispel-Pend d'Oreille",
  'flh': "Foau",
  'fli': "Fali",
  'fll': "North Fali",
  'fln': "Flinders Island",
  'flr': "Fuliiru",
  'fly': "Tsotsitaal",
  'fmp': "Fe'fe'",
  'fmu': "Far Western Muria",
  'fnb': "Fanbak",
  'fng': "Fanagalo",
  'fni': "Fania",
  'fod': "Foodo",
  'foi': "Foi",
  'fom': "Foma",
  'fon': "Fon",
  'for': "Fore",
  'fos': "Siraya",
  'fox': "Formosan languages",
  'fpe': "Fernando Po Creole English",
  'fqs': "Fas",
  'frc': "Cajun French",
  'frd': "Fordata",
  'frk': "Frankish",
  'frm': "Middle French (ca. 1400-1600)",
  'fro': "Old French (842-ca. 1400)",
  'frp': "Francoprovençal",
  'frq': "Forak",
  'frr': "Northern Frisian",
  'frs': "Eastern Frisian",
  'frt': "Fortsenal",
  'fse': "Finnish Sign Language",
  'fsl': "French Sign Language",
  'fss': "suomenruotsalainen viittomakieli",
  'fub': "Adamawa Fulfulde",
  'fuc': "Pulaar",
  'fud': "East Futuna",
  'fue': "Borgu Fulfulde",
  'fuf': "Pular",
  'fuh': "Western Niger Fulfulde",
  'fui': "Bagirmi Fulfulde",
  'fuj': "Ko",
  'fum': "Fum",
  'fun': "Fulniô",
  'fuq': "Central-Eastern Niger Fulfulde",
  'fur': "Friulian",
  'fut': "Futuna-Aniwa",
  'fuu': "Furu",
  'fuv': "Nigerian Fulfulde",
  'fuy': "Fuyug",
  'fvr': "Fur",
  'fwa': "Fwâi",
  'fwe': "Fwe",
  'gaa': "Ga",
  'gab': "Gabri",
  'gac': "Mixed Great Andamanese",
  'gad': "Gaddang",
  'gae': "Guarequena",
  'gaf': "Gende",
  'gag': "Gagauz",
  'gah': "Alekano",
  'gai': "Borei",
  'gaj': "Gadsup",
  'gak': "Gamkonora",
  'gal': "Galolen",
  'gam': "Kandawo",
  'gan': "Gan Chinese",
  'gao': "Gants",
  'gap': "Gal",
  'gaq': "Gata'",
  'gar': "Galeya",
  'gas': "Adiwasi Garasia",
  'gat': "Kenati",
  'gau': "Mudhili Gadaba",
  'gav': "Gabutamon",
  'gaw': "Nobonob",
  'gax': "Borana-Arsi-Guji Oromo",
  'gay': "Gayo",
  'gaz': "West Central Oromo",
  'gba': "Gbaya (Central African Republic)",
  'gbb': "Kaytetye",
  'gbc': "Garawa",
  'gbd': "Karajarri",
  'gbe': "Niksek",
  'gbf': "Gaikundi",
  'gbg': "Gbanziri",
  'gbh': "Defi Gbe",
  'gbi': "Galela",
  'gbj': "Bodo Gadaba",
  'gbk': "Gaddi",
  'gbl': "Gamit",
  'gbm': "Garhwali",
  'gbn': "Mo'da",
  'gbo': "Northern Grebo",
  'gbp': "Gbaya-Bossangoa",
  'gbq': "Gbaya-Bozoum",
  'gbr': "Gbagyi",
  'gbs': "Gbesi Gbe",
  'gbu': "Gagadu",
  'gbv': "Gbanu",
  'gbw': "Gabi-Gabi",
  'gbx': "Eastern Xwla Gbe",
  'gby': "Gbari",
  'gbz': "Zoroastrian Dari",
  'gcc': "Mali",
  'gcd': "Ganggalida",
  'gce': "Galice",
  'gcf': "Guadeloupean Creole French",
  'gcl': "Grenadian Creole English",
  'gcn': "Gaina",
  'gcr': "Guianese Creole French",
  'gct': "Colonia Tovar German",
  'gda': "Gade Lohar",
  'gdb': "Pottangi Ollar Gadaba",
  'gdc': "Gugu Badhun",
  'gdd': "Gedaged",
  'gde': "Gude",
  'gdf': "Guduf-Gava",
  'gdg': "Ga'dang",
  'gdh': "Gajirrabeng",
  'gdi': "Gundi",
  'gdj': "Gurdjar",
  'gdk': "Gadang",
  'gdl': "Dirasha",
  'gdm': "Laal",
  'gdn': "Umanakaina",
  'gdo': "Ghodoberi",
  'gdq': "Mehri",
  'gdr': "Wipi",
  'gds': "Ghandruk Sign Language",
  'gdt': "Kungardutyi",
  'gdu': "Gudu",
  'gdx': "Godwari",
  'gea': "Geruma",
  'geb': "Kire",
  'gec': "Gboloo Grebo",
  'ged': "Gade",
  'gef': "Gerai",
  'geg': "Gengle",
  'geh': "Hutterisch",
  'gei': "Gebe",
  'gej': "Gen",
  'gek': "Ywom",
  'gel': "ut-Ma'in",
  'gem': "Germanic languages",
  'geq': "Geme",
  'ges': "Geser-Gorom",
  'gev': "Eviya",
  'gew': "Gera",
  'gex': "Garre",
  'gey': "Enya",
  'gez': "Geez",
  'gfk': "Patpatar",
  'gft': "Gafat",
  'gfx': "Mangetti Dune ǃXung",
  'gga': "Gao",
  'ggb': "Gbii",
  'ggd': "Gugadj",
  'gge': "Gurr-goni",
  'ggg': "Gurgula",
  'ggk': "Kungarakany",
  'ggl': "Ganglau",
  'ggn': "Eastern Gurung",
  'ggo': "Southern Gondi",
  'ggr': "Aghu Tharnggalu",
  'ggt': "Gitua",
  'ggu': "Gban",
  'ggw': "Gogodala",
  'gha': "Ghadamès",
  'ghc': "Hiberno-Scottish Gaelic",
  'ghe': "Southern Ghale",
  'ghh': "Northern Ghale",
  'ghk': "Geko Karen",
  'ghl': "Ghulfan",
  'ghn': "Ghanongga",
  'gho': "Ghomara",
  'ghr': "Ghera",
  'ghs': "Guhu-Samane",
  'ght': "Kutang Ghale",
  'gia': "Kija",
  'gib': "Gibanawa",
  'gic': "Gail",
  'gid': "Gidar",
  'gie': "Guébie",
  'gig': "Goaria",
  'gih': "Githabul",
  'gii': "Girirra",
  'gil': "Gilbertese",
  'gim': "Gimi (Eastern Highlands)",
  'gin': "Hinukh",
  'gio': "Gelao",
  'gip': "Gimi (West New Britain)",
  'giq': "Green Gelao",
  'gir': "Red Gelao",
  'gis': "North Giziga",
  'git': "Gitxsan",
  'giu': "Mulao",
  'giw': "White Gelao",
  'gix': "Gilima",
  'giy': "Giyug",
  'giz': "South Giziga",
  'gji': "Geji",
  'gjk': "Kachi Koli",
  'gjm': "Gunditjmara",
  'gjn': "Gonja",
  'gjr': "Gurindji Kriol",
  'gju': "Gujari",
  'gka': "Guya",
  'gkd': "Magɨ (Madang Province)",
  'gke': "Ndai",
  'gkn': "Gokana",
  'gko': "Kok-Nar",
  'gkp': "Guinea Kpelle",
  'gku': "ǂUngkue",
  'glb': "Belning",
  'glc': "Bon Gula",
  'gld': "Nanai",
  'glh': "Northwest Pashayi",
  'gli': "Guliguli",
  'glj': "Gula Iro",
  'glk': "Gilaki",
  'gll': "Garlali",
  'glo': "Galambu",
  'glr': "Glaro-Twabo",
  'glu': "Gula (Chad)",
  'glw': "Glavda",
  'gly': "Gule",
  'gma': "Gambera",
  'gmb': "Gula'alaa",
  'gmd': "Mághdì",
  'gme': "East Germanic languages",
  'gmg': "Magɨyi",
  'gmh': "Middle High German (ca. 1050-1500)",
  'gml': "Middle Low German",
  'gmm': "Gbaya-Mbodomo",
  'gmn': "Gimnime",
  'gmq': "North Germanic languages",
  'gmr': "Mirniny",
  'gmu': "Gumalu",
  'gmv': "Gamo",
  'gmw': "West Germanic languages",
  'gmx': "Magoma",
  'gmy': "Mycenaean Greek",
  'gmz': "Mgbolizhia",
  'gna': "Kaansa",
  'gnb': "Gangte",
  'gnc': "Guanche",
  'gnd': "Zulgo-Gemzek",
  'gne': "Ganang",
  'gng': "Ngangam",
  'gnh': "Lere",
  'gni': "Gooniyandi",
  'gnj': "Ngen",
  'gnk': "ǁGana",
  'gnl': "Gangulu",
  'gnm': "Ginuman",
  'gnn': "Gumatj",
  'gno': "Northern Gondi",
  'gnq': "Gana",
  'gnr': "Gureng Gureng",
  'gnt': "Guntai",
  'gnu': "Gnau",
  'gnw': "Western Bolivian Guaraní",
  'gnz': "Ganzi",
  'goa': "Guro",
  'gob': "Playero",
  'goc': "Gorakor",
  'god': "Godié",
  'goe': "Gongduk",
  'gof': "Gofa",
  'gog': "Gogo",
  'goh': "Old High German (ca. 750-1050)",
  'goi': "Gobasi",
  'goj': "Gowlan",
  'gok': "Gowli",
  'gol': "Gola",
  'gom': "Goan Konkani",
  'gon': "Gondi",
  'goo': "Gone Dau",
  'gop': "Yeretuar",
  'goq': "Gorap",
  'gor': "Gorontalo",
  'gos': "Gronings",
  'got': "Gothic",
  'gou': "Gavar",
  'gov': "Goo",
  'gow': "Gorowa",
  'gox': "Gobu",
  'goy': "Goundo",
  'goz': "Gozarkhani",
  'gpa': "Gupa-Abawa",
  'gpe': "Ghanaian Pidgin English",
  'gpn': "Taiap",
  'gqa': "Ga'anda",
  'gqi': "Guiqiong",
  'gqn': "Guana (Brazil)",
  'gqr': "Gor",
  'gqu': "Qau",
  'gra': "Rajput Garasia",
  'grb': "Grebo",
  'grc': "Ancient Greek (to 1453)",
  'grd': "Guruntum-Mbaaru",
  'grg': "Madi",
  'grh': "Gbiri-Niragu",
  'gri': "Ghari",
  'grj': "Southern Grebo",
  'grk': "Greek languages",
  'grm': "Kota Marudu Talantang",
  'gro': "Groma",
  'grq': "Gorovu",
  'grr': "Taznatit",
  'grs': "Gresi",
  'grt': "Garo",
  'gru': "Kistane",
  'grv': "Central Grebo",
  'grw': "Gweda",
  'grx': "Guriaso",
  'gry': "Barclayville Grebo",
  'grz': "Guramalum",
  'gse': "Ghanaian Sign Language",
  'gsg': "German Sign Language",
  'gsl': "Gusilay",
  'gsm': "Guatemalan Sign Language",
  'gsn': "Gusan",
  'gso': "Southwest Gbaya",
  'gsp': "Wasembo",
  'gss': "Greek Sign Language",
  'gsw': "Alsatian",
  'gta': "Guató",
  'gti': "Gbati-ri",
  'gtu': "Aghu-Tharnggala",
  'gua': "Shiki",
  'gub': "Guajajára",
  'guc': "Wayuu",
  'gud': "Yocoboué Dida",
  'gue': "Gurindji",
  'guf': "Gupapuyngu",
  'gug': "Paraguayan Guaraní",
  'guh': "Guahibo",
  'gui': "Eastern Bolivian Guaraní",
  'guk': "Gumuz",
  'gul': "Sea Island Creole English",
  'gum': "Guambiano",
  'gun': "Mbyá Guaraní",
  'guo': "Guayabero",
  'gup': "Gunwinggu",
  'guq': "Aché",
  'gur': "Farefare",
  'gus': "Guinean Sign Language",
  'gut': "Maléku Jaíka",
  'guu': "Yanomamö",
  'guv': "Gey",
  'guw': "Gun",
  'gux': "Gourmanchéma",
  'guz': "Ekegusii",
  'gva': "Guana (Paraguay)",
  'gvc': "Guanano",
  'gve': "Duwet",
  'gvf': "Golin",
  'gvj': "Guajá",
  'gvl': "Gulay",
  'gvm': "Gurmana",
  'gvn': "Kuku-Yalanji",
  'gvo': "Gavião Do Jiparaná",
  'gvp': "Pará Gavião",
  'gvr': "Gurung",
  'gvs': "Gumawana",
  'gvy': "Guyani",
  'gwa': "Mbato",
  'gwb': "Gwa",
  'gwc': "Kalami",
  'gwd': "Gawwada",
  'gwe': "Gweno",
  'gwf': "Gowro",
  'gwg': "Moo",
  'gwi': "Gwichʼin",
  'gwj': "ǀGwi",
  'gwm': "Awngthim",
  'gwn': "Gwandara",
  'gwr': "Gwere",
  'gwt': "Gawar-Bati",
  'gwu': "Guwamu",
  'gww': "Kwini",
  'gwx': "Gua",
  'gxx': "Wè Southern",
  'gya': "Northwest Gbaya",
  'gyb': "Garus",
  'gyd': "Kayardild",
  'gye': "Gyem",
  'gyf': "Gungabula",
  'gyg': "Gbayi",
  'gyi': "Gyele",
  'gyl': "Gayil",
  'gym': "Ngäbere",
  'gyn': "Guyanese Creole English",
  'gyo': "Gyalsumdo",
  'gyr': "Guarayu",
  'gyy': "Gunya",
  'gyz': "Gyaazi",
  'gza': "Ganza",
  'gzi': "Gazi",
  'gzn': "Gane",
  'haa': "Han",
  'hab': "Hanoi Sign Language",
  'hac': "Gurani",
  'had': "Hatam",
  'hae': "Eastern Oromo",
  'haf': "Haiphong Sign Language",
  'hag': "Hanga",
  'hah': "Hahon",
  'hai': "Haida",
  'haj': "Hajong",
  'hak': "Hakka Chinese",
  'hal': "Halang",
  'ham': "Hewa",
  'han': "Hangaza",
  'hao': "Hakö",
  'hap': "Hupla",
  'haq': "Ha",
  'har': "Harari",
  'has': "Haisla",
  'hav': "Havu",
  'haw': "Hawaiian",
  'hax': "Southern Haida",
  'hay': "Haya",
  'haz': "Hazaragi",
  'hba': "Hamba",
  'hbb': "Huba",
  'hbn': "Heiban",
  'hbo': "Ancient Hebrew",
  'hbu': "Habu",
  'hca': "Andaman Creole Hindi",
  'hch': "Huichol",
  'hdn': "Northern Haida",
  'hds': "Honduras Sign Language",
  'hdy': "Hadiyya",
  'hea': "Northern Qiandong Miao",
  'hed': "Herdé",
  'heg': "Helong",
  'heh': "Hehe",
  'hei': "Heiltsuk",
  'hem': "Hemba",
  'hgm': "Haiǁom",
  'hgw': "Haigwai",
  'hhi': "Hoia Hoia",
  'hhr': "Kerak",
  'hhy': "Hoyahoya",
  'hia': "Lamang",
  'hib': "Hibito",
  'hid': "Hidatsa",
  'hif': "Fiji Hindi",
  'hig': "Kamwe",
  'hih': "Pamosu",
  'hii': "Hinduri",
  'hij': "Hijuk",
  'hik': "Seit-Kaitetu",
  'hil': "Hiligaynon",
  'him': "Western Pahari languages",
  'hio': "Tsoa",
  'hir': "Himarimã",
  'hit': "Hittite",
  'hiw': "Hiw",
  'hix': "Hixkaryána",
  'hji': "Haji",
  'hka': "Kahe",
  'hke': "Hunde",
  'hkh': "Poguli",
  'hkk': "Hunjara-Kaina Ke",
  'hkn': "Mel-Khaonh",
  'hks': "Heung Kong Sau Yue",
  'hla': "Halia",
  'hlb': "Halbi",
  'hld': "Halang Doan",
  'hle': "Hlersu",
  'hlt': "Matu Chin",
  'hlu': "Hieroglyphic Luwian",
  'hma': "Southern Mashan Miao",
  'hmb': "Humburi Senni Songhay",
  'hmc': "Central Huishui Miao",
  'hmd': "Da-Hua Miao",
  'hme': "Eastern Huishui Miao",
  'hmf': "Hmong Don",
  'hmg': "Southwestern Guiyang Hmong",
  'hmh': "Southwestern Huishui Miao",
  'hmi': "Northern Huishui Miao",
  'hmj': "Gejia",
  'hmk': "Maek",
  'hml': "Luopohe Miao",
  'hmm': "Central Mashan Miao",
  'hmn': "Mong",
  'hmp': "Northern Mashan Miao",
  'hmq': "Eastern Qiandong Miao",
  'hmr': "Hmar",
  'hms': "Southern Qiandong Miao",
  'hmt': "Hamtai",
  'hmu': "Hamap",
  'hmv': "Hmong Dô",
  'hmw': "Western Mashan Miao",
  'hmx': "Hmong-Mien languages",
  'hmy': "Southern Guiyang Miao",
  'hmz': "Sinicized Miao",
  'hna': "Mina (Cameroon)",
  'hnd': "Southern Hindko",
  'hne': "Chhattisgarhi",
  'hng': "Hungu",
  'hnh': "ǁAni",
  'hni': "Hani",
  'hnj': "Mong Njua",
  'hnn': "Hanunoo",
  'hno': "Northern Hindko",
  'hns': "Caribbean Hindustani",
  'hnu': "Hung",
  'hoa': "Hoava",
  'hob': "Mari (Madang Province)",
  'hoc': "Ho",
  'hod': "Holma",
  'hoe': "Horom",
  'hoh': "Hobyót",
  'hoi': "Holikachuk",
  'hoj': "Haroti",
  'hok': "Hokan languages",
  'hol': "Holu",
  'hom': "Homa",
  'hoo': "Holoholo",
  'hop': "Hopi",
  'hor': "Horo",
  'hos': "Ho Chi Minh City Sign Language",
  'hot': "Malê",
  'hov': "Hovongan",
  'how': "Honi",
  'hoy': "Holiya",
  'hoz': "Hozo",
  'hpo': "Hpon",
  'hps': "Hawai'i Pidgin Sign Language",
  'hra': "Hrangkhol",
  'hrc': "Niwer Mil",
  'hre': "Hre",
  'hrk': "Haruku",
  'hrm': "Horned Miao",
  'hro': "Haroi",
  'hrp': "Nhirrpi",
  'hrr': "Horuru",
  'hrt': "Hértevin",
  'hru': "Hruso",
  'hrw': "Warwar Feni",
  'hrx': "Hunsrik",
  'hrz': "Harzani",
  'hsb': "Upper Sorbian",
  'hsh': "Hungarian Sign Language",
  'hsl': "Hausa Sign Language",
  'hsn': "Xiang Chinese",
  'hss': "Harsusi",
  'hti': "Hoti",
  'hto': "Minica Huitoto",
  'hts': "Hadza",
  'htu': "Hitu",
  'htx': "Middle Hittite",
  'hub': "Huambisa",
  'huc': "ǂʼAmkhoe",
  'hud': "Huaulu",
  'hue': "San Francisco Del Mar Huave",
  'huf': "Humene",
  'hug': "Huachipaeri",
  'huh': "Huilliche",
  'hui': "Huli",
  'huj': "Northern Guiyang Miao",
  'huk': "Hulung",
  'hul': "Hula",
  'hum': "Hungana",
  'huo': "Hu",
  'hup': "Hupa",
  'huq': "Tsat",
  'hur': "Halkomelem",
  'hus': "Huastec",
  'hut': "Humla",
  'huu': "Murui Huitoto",
  'huv': "San Mateo Del Mar Huave",
  'huw': "Hukumina",
  'hux': "Nüpode Huitoto",
  'huy': "Hulaulá",
  'huz': "Hunzib",
  'hvc': "Haitian Vodoun Culture Language",
  'hve': "San Dionisio Del Mar Huave",
  'hvk': "Haveke",
  'hvn': "Sabu",
  'hvv': "Santa María Del Mar Huave",
  'hwa': "Wané",
  'hwc': "Hawai'i Pidgin",
  'hwo': "Hwana",
  'hya': "Hya",
  'hyw': "Western Armenian",
  'hyx': "Armenian (family)",
  'iai': "Iaai",
  'ian': "Iatmul",
  'iap': "Iapama",
  'iar': "Purari",
  'iba': "Iban",
  'ibb': "Ibibio",
  'ibd': "Iwaidja",
  'ibe': "Akpes",
  'ibg': "Ibanag",
  'ibh': "Bih",
  'ibi': "Ibilo",
  'ibl': "Ibaloi",
  'ibm': "Agoi",
  'ibn': "Ibino",
  'ibr': "Ibuoro",
  'ibu': "Ibu",
  'iby': "Ibani",
  'ica': "Ede Ica",
  'ich': "Etkywan",
  'icl': "Icelandic Sign Language",
  'icr': "Islander Creole English",
  'ida': "Luidakho-Luisukha-Lutirichi",
  'idb': "Indo-Portuguese",
  'idc': "Ajiya",
  'idd': "Ede Idaca",
  'ide': "Idere",
  'idi': "Idi",
  'idr': "Indri",
  'ids': "Idesa",
  'idt': "Idaté",
  'idu': "Idoma",
  'ifa': "Amganad Ifugao",
  'ifb': "Ayangan Ifugao",
  'ife': "Ifè",
  'iff': "Ifo",
  'ifk': "Tuwali Ifugao",
  'ifm': "Teke-Fuumu",
  'ifu': "Mayoyao Ifugao",
  'ify': "Keley-I Kallahan",
  'igb': "Ebira",
  'ige': "Igede",
  'igg': "Igana",
  'igl': "Igala",
  'igm': "Kanggape",
  'ign': "Ignaciano",
  'igo': "Isebe",
  'igs': "Interglossa",
  'igw': "Igwe",
  'ihb': "Iha Based Pidgin",
  'ihi': "Ihievbe",
  'ihp': "Iha",
  'ihw': "Bidhawal",
  'iin': "Thiin",
  'iir': "Indo-Iranian languages",
  'ijc': "Izon",
  'ije': "Biseni",
  'ijj': "Ede Ije",
  'ijn': "Kalabari",
  'ijo': "Ijo languages",
  'ijs': "Southeast Ijo",
  'ike': "Eastern Canadian Inuktitut",
  'ikh': "Ikhin-Arokho",
  'iki': "Iko",
  'ikk': "Ika",
  'ikl': "Ikulu",
  'iko': "Olulumo-Ikom",
  'ikp': "Ikpeshi",
  'ikr': "Ikaranggal",
  'iks': "Inuit Sign Language",
  'ikt': "Western Canadian Inuktitut",
  'ikv': "Iku-Gora-Ankwa",
  'ikw': "Ikwere",
  'ikx': "Ik",
  'ikz': "Ikizu",
  'ila': "Ile Ape",
  'ilb': "Ila",
  'ilg': "Garig-Ilgar",
  'ili': "Ili Turki",
  'ilk': "Ilongot",
  'ill': "Iranun",
  'ilm': "Iranun (Malaysia)",
  'ilo': "Iloko",
  'ilp': "Iranun (Philippines)",
  'ils': "International Sign",
  'ilu': "Ili'uun",
  'ilv': "Ilue",
  'ilw': "Talur",
  'ima': "Mala Malasar",
  'ime': "Imeraguen",
  'imi': "Anamgura",
  'iml': "Miluk",
  'imn': "Imonda",
  'imo': "Imbongu",
  'imr': "Imroing",
  'ims': "Marsian",
  'imt': "Imotong",
  'imy': "Milyan",
  'inb': "Inga",
  'inc': "Indic languages",
  'ine': "Indo-European languages",
  'ing': "Degexit'an",
  'inh': "Ingush",
  'inj': "Jungle Inga",
  'inl': "Indonesian Sign Language",
  'inm': "Minaean",
  'inn': "Isinai",
  'ino': "Inoke-Yate",
  'inp': "Iñapari",
  'ins': "Indian Sign Language",
  'int': "Intha",
  'inz': "Ineseño",
  'ior': "Inor",
  'iou': "Tuma-Irumu",
  'iow': "Iowa-Oto",
  'ipi': "Ipili",
  'ipo': "Ipiko",
  'iqu': "Iquito",
  'iqw': "Ikwo",
  'ira': "Iranian languages",
  'ire': "Iresim",
  'irh': "Irarutu",
  'iri': "Irigwe",
  'irk': "Iraqw",
  'irn': "Irántxe",
  'iro': "Iroquoian languages",
  'irr': "Ir",
  'iru': "Irula",
  'irx': "Kamberau",
  'iry': "Iraya",
  'isa': "Isabi",
  'isc': "Isconahua",
  'isd': "Isnag",
  'ise': "Italian Sign Language",
  'isg': "Irish Sign Language",
  'ish': "Esan",
  'isi': "Nkem-Nkum",
  'isk': "Ishkashimi",
  'ism': "Masimasi",
  'isn': "Isanzu",
  'iso': "Isoko",
  'isr': "Israeli Sign Language",
  'ist': "Istriot",
  'isu': "Isu (Menchum Division)",
  'itb': "Binongan Itneg",
  'itc': "Italic languages",
  'itd': "Southern Tidung",
  'ite': "Itene",
  'iti': "Inlaod Itneg",
  'itk': "Judeo-Italian",
  'itl': "Itelmen",
  'itm': "Itu Mbon Uzo",
  'ito': "Itonama",
  'itr': "Iteri",
  'its': "Isekiri",
  'itt': "Maeng Itneg",
  'itv': "Itawit",
  'itw': "Ito",
  'itx': "Itik",
  'ity': "Moyadan Itneg",
  'itz': "Itzá",
  'ium': "Iu Mien",
  'ivb': "Ibatan",
  'ivv': "Ivatan",
  'iwk': "I-Wak",
  'iwm': "Iwam",
  'iwo': "Iwur",
  'iws': "Sepik Iwam",
  'ixc': "Ixcatec",
  'ixl': "Ixil",
  'iya': "Iyayu",
  'iyo': "Mesaka",
  'iyx': "Yaka (Congo)",
  'izh': "Ingrian",
  'izi': "Izi-Ezaa-Ikwo-Mgbo",
  'izm': "Kizamani",
  'izr': "Izere",
  'izz': "Izii",
  'jaa': "Jamamadí",
  'jab': "Hyam",
  'jac': "Jakalteko",
  'jad': "Jahanka",
  'jae': "Yabem",
  'jaf': "Jara",
  'jah': "Jah Hut",
  'jaj': "Zazao",
  'jak': "Jakun",
  'jal': "Yalahatan",
  'jam': "Jamaican Creole English",
  'jan': "Jandai",
  'jao': "Yanyuwa",
  'jaq': "Yaqay",
  'jar': "Jarawa (Nigeria)",
  'jas': "New Caledonian Javanese",
  'jat': "Jakati",
  'jau': "Yaur",
  'jax': "Jambi Malay",
  'jay': "Nhangu",
  'jaz': "Jawe",
  'jbe': "Judeo-Berber",
  'jbi': "Badjiri",
  'jbj': "Arandai",
  'jbk': "Barikewa",
  'jbm': "Bijim",
  'jbn': "Nafusi",
  'jbo': "Lojban",
  'jbr': "Jofotek-Bromnya",
  'jbt': "Jabutí",
  'jbu': "Jukun Takum",
  'jbw': "Yawijibaya",
  'jcs': "Jamaican Country Sign Language",
  'jct': "Krymchak",
  'jda': "Jad",
  'jdg': "Jadgali",
  'jdt': "Judeo-Tat",
  'jeb': "Jebero",
  'jee': "Jerung",
  'jeg': "Jeng",
  'jeh': "Jeh",
  'jei': "Yei",
  'jek': "Jeri Kuo",
  'jel': "Yelmek",
  'jen': "Dza",
  'jer': "Jere",
  'jet': "Manem",
  'jeu': "Jonkor Bourmataguil",
  'jgb': "Ngbee",
  'jge': "Judeo-Georgian",
  'jgk': "Gwak",
  'jgo': "Ngomba",
  'jhi': "Jehai",
  'jhs': "Jhankot Sign Language",
  'jia': "Jina",
  'jib': "Jibu",
  'jic': "Tol",
  'jid': "Bu (Kaduna State)",
  'jie': "Jilbe",
  'jig': "Djingili",
  'jih': "Shangzhai",
  'jii': "Jiiddu",
  'jil': "Jilim",
  'jim': "Jimi (Cameroon)",
  'jio': "Jiamao",
  'jiq': "Lavrung",
  'jit': "Jita",
  'jiu': "Youle Jinuo",
  'jiv': "Shuar",
  'jiy': "Buyuan Jinuo",
  'jje': "Jejueo",
  'jjr': "Bankal",
  'jka': "Kaera",
  'jkm': "Mobwa Karen",
  'jko': "Kubo",
  'jkp': "Paku Karen",
  'jkr': "Koro (India)",
  'jks': "Amami Koniya Sign Language",
  'jku': "Labir",
  'jle': "Ngile",
  'jls': "Jamaican Sign Language",
  'jma': "Dima",
  'jmb': "Zumbun",
  'jmc': "Machame",
  'jmd': "Yamdena",
  'jmi': "Jimi (Nigeria)",
  'jml': "Jumli",
  'jmn': "Makuri Naga",
  'jmr': "Kamara",
  'jms': "Mashi (Nigeria)",
  'jmw': "Mouwase",
  'jmx': "Western Juxtlahuaca Mixtec",
  'jna': "Jangshung",
  'jnd': "Jandavra",
  'jng': "Yangman",
  'jni': "Janji",
  'jnj': "Yemsa",
  'jnl': "Rawat",
  'jns': "Jaunsari",
  'job': "Joba",
  'jod': "Wojenaka",
  'jog': "Jogi",
  'jor': "Jorá",
  'jos': "Jordanian Sign Language",
  'jow': "Jowulu",
  'jpa': "Jewish Palestinian Aramaic",
  'jpr': "Judeo-Persian",
  'jpx': "Japanese (family)",
  'jqr': "Jaqaru",
  'jra': "Jarai",
  'jrb': "Judeo-Arabic",
  'jrr': "Jiru",
  'jrt': "Jakattoe",
  'jru': "Japrería",
  'jsl': "Japanese Sign Language",
  'jua': "Júma",
  'jub': "Wannu",
  'juc': "Jurchen",
  'jud': "Worodougou",
  'juh': "Hõne",
  'jui': "Ngadjuri",
  'juk': "Wapan",
  'jul': "Jirel",
  'jum': "Jumjum",
  'jun': "Juang",
  'juo': "Jiba",
  'jup': "Hupdë",
  'jur': "Jurúna",
  'jus': "Jumla Sign Language",
  'jut': "Jutish",
  'juu': "Ju",
  'juw': "Wãpha",
  'juy': "Juray",
  'jvd': "Javindo",
  'jvn': "Caribbean Javanese",
  'jwi': "Jwira-Pepesa",
  'jya': "Jiarong",
  'jye': "Judeo-Yemeni Arabic",
  'jyy': "Jaya",
  'kaa': "Karakalpak",
  'kab': "Kabyle",
  'kac': "Jingpho",
  'kad': "Adara",
  'kae': "Ketangalan",
  'kaf': "Katso",
  'kag': "Kajaman",
  'kah': "Kara (Central African Republic)",
  'kai': "Karekare",
  'kaj': "Jju",
  'kak': "Kayapa Kallahan",
  'kam': "Kamba (Kenya)",
  'kao': "Xaasongaxango",
  'kap': "Bezhta",
  'kaq': "Capanahua",
  'kar': "Karen languages",
  'kav': "Katukína",
  'kaw': "Kawi",
  'kax': "Kao",
  'kay': "Kamayurá",
  'kba': "Kalarko",
  'kbb': "Kaxuiâna",
  'kbc': "Kadiwéu",
  'kbd': "Kabardian",
  'kbe': "Kanju",
  'kbf': "Kakauhua",
  'kbg': "Khamba",
  'kbh': "Camsá",
  'kbi': "Kaptiau",
  'kbj': "Kari",
  'kbk': "Grass Koiari",
  'kbl': "Kanembu",
  'kbm': "Iwal",
  'kbn': "Kare (Central African Republic)",
  'kbo': "Keliko",
  'kbp': "Kabiyè",
  'kbq': "Kamano",
  'kbr': "Kafa",
  'kbs': "Kande",
  'kbt': "Abadi",
  'kbu': "Kabutra",
  'kbv': "Dera (Indonesia)",
  'kbw': "Kaiep",
  'kbx': "Ap Ma",
  'kby': "Manga Kanuri",
  'kbz': "Duhwa",
  'kca': "Khanty",
  'kcb': "Kawacha",
  'kcc': "Lubila",
  'kcd': "Ngkâlmpw Kanum",
  'kce': "Kaivi",
  'kcf': "Ukaan",
  'kcg': "Tyap",
  'kch': "Vono",
  'kci': "Kamantan",
  'kcj': "Kobiana",
  'kck': "Kalanga",
  'kcl': "Kala",
  'kcm': "Gula (Central African Republic)",
  'kcn': "Nubi",
  'kco': "Kinalakna",
  'kcp': "Kanga",
  'kcq': "Kamo",
  'kcr': "Katla",
  'kcs': "Koenoem",
  'kct': "Kaian",
  'kcu': "Kami (Tanzania)",
  'kcv': "Kete",
  'kcw': "Kabwari",
  'kcx': "Kachama-Ganjule",
  'kcy': "Korandje",
  'kcz': "Konongo",
  'kda': "Worimi",
  'kdc': "Kutu",
  'kdd': "Yankunytjatjara",
  'kde': "Makonde",
  'kdf': "Mamusi",
  'kdg': "Seba",
  'kdh': "Tem",
  'kdi': "Kumam",
  'kdj': "Karamojong",
  'kdk': "Kwényi",
  'kdl': "Tsikimba",
  'kdm': "Kagoma",
  'kdn': "Kunda",
  'kdo': "Kordofanian languages",
  'kdp': "Kaningdon-Nindem",
  'kdq': "Koch",
  'kdr': "Karaim",
  'kdt': "Kuy",
  'kdu': "Kadaru",
  'kdv': "Kado",
  'kdw': "Koneraw",
  'kdx': "Kam",
  'kdy': "Keijar",
  'kdz': "Kwaja",
  'kea': "Kabuverdianu",
  'keb': "Kélé",
  'kec': "Keiga",
  'ked': "Kerewe",
  'kee': "Eastern Keres",
  'kef': "Kpessi",
  'keg': "Tese",
  'keh': "Keak",
  'kei': "Kei",
  'kej': "Kadar",
  'kek': "Kekchí",
  'kel': "Kela (Democratic Republic of Congo)",
  'kem': "Kemak",
  'ken': "Kenyang",
  'keo': "Kakwa",
  'kep': "Kaikadi",
  'keq': "Kamar",
  'ker': "Kera",
  'kes': "Kugbo",
  'ket': "Ket",
  'keu': "Akebu",
  'kev': "Kanikkaran",
  'kew': "West Kewa",
  'kex': "Kukna",
  'key': "Kupia",
  'kez': "Kukele",
  'kfa': "Kodava",
  'kfb': "Northwestern Kolami",
  'kfc': "Konda-Dora",
  'kfd': "Korra Koraga",
  'kfe': "Kota (India)",
  'kff': "Koya",
  'kfg': "Kudiya",
  'kfh': "Kurichiya",
  'kfi': "Kannada Kurumba",
  'kfj': "Kemiehua",
  'kfk': "Kinnauri",
  'kfl': "Kung",
  'kfm': "Khunsari",
  'kfn': "Kuk",
  'kfo': "Koro (Côte d'Ivoire)",
  'kfp': "Korwa",
  'kfq': "Korku",
  'kfr': "Kutchi",
  'kfs': "Bilaspuri",
  'kft': "Kanjari",
  'kfu': "Katkari",
  'kfv': "Kurmukar",
  'kfw': "Kharam Naga",
  'kfx': "Kullu Pahari",
  'kfy': "Kumaoni",
  'kfz': "Koromfé",
  'kga': "Koyaga",
  'kgb': "Kawe",
  'kgc': "Kasseng",
  'kgd': "Kataang",
  'kge': "Komering",
  'kgf': "Kube",
  'kgg': "Kusunda",
  'kgh': "Upper Tanudan Kalinga",
  'kgi': "Selangor Sign Language",
  'kgj': "Gamale Kham",
  'kgk': "Kaiwá",
  'kgl': "Kunggari",
  'kgm': "Karipúna",
  'kgn': "Karingani",
  'kgo': "Krongo",
  'kgp': "Kaingang",
  'kgq': "Kamoro",
  'kgr': "Abun",
  'kgs': "Kumbainggar",
  'kgt': "Somyev",
  'kgu': "Kobol",
  'kgv': "Karas",
  'kgw': "Karon Dori",
  'kgx': "Kamaru",
  'kgy': "Kyerung",
  'kha': "Khasi",
  'khb': "Lü",
  'khc': "Tukang Besi North",
  'khd': "Bädi Kanum",
  'khe': "Korowai",
  'khf': "Khuen",
  'khg': "Khams Tibetan",
  'khh': "Kehu",
  'khi': "Khoisan languages",
  'khj': "Kuturmi",
  'khk': "Halh Mongolian",
  'khl': "Lusi",
  'khn': "Khandesi",
  'kho': "Sakan",
  'khp': "Kapauri",
  'khq': "Koyra Chiini Songhay",
  'khr': "Kharia",
  'khs': "Kasua",
  'kht': "Khamti",
  'khu': "Nkhumbi",
  'khv': "Khvarshi",
  'khw': "Khowar",
  'khx': "Kanu",
  'khy': "Kele (Democratic Republic of Congo)",
  'khz': "Keapara",
  'kia': "Kim",
  'kib': "Koalib",
  'kic': "Kickapoo",
  'kid': "Koshin",
  'kie': "Kibet",
  'kif': "Eastern Parbate Kham",
  'kig': "Kimaghima",
  'kih': "Kilmeri",
  'kii': "Kitsai",
  'kij': "Kilivila",
  'kil': "Kariya",
  'kim': "Karagas",
  'kio': "Kiowa",
  'kip': "Sheshi Kham",
  'kiq': "Kosare",
  'kis': "Kis",
  'kit': "Agob",
  'kiu': "Kirmanjki (individual language)",
  'kiv': "Kimbu",
  'kiw': "Northeast Kiwai",
  'kix': "Khiamniungan Naga",
  'kiy': "Kirikiri",
  'kiz': "Kisi",
  'kja': "Mlap",
  'kjb': "Kanjobal",
  'kjc': "Coastal Konjo",
  'kjd': "Southern Kiwai",
  'kje': "Kisar",
  'kjf': "Khalaj [Indo-Iranian]",
  'kjg': "Khmu",
  'kjh': "Khakas",
  'kji': "Zabana",
  'kjj': "Khinalugh",
  'kjk': "Highland Konjo",
  'kjl': "Western Parbate Kham",
  'kjm': "Kháng",
  'kjn': "Kunjen",
  'kjo': "Harijan Kinnauri",
  'kjp': "Pwo Eastern Karen",
  'kjq': "Western Keres",
  'kjr': "Kurudu",
  'kjs': "East Kewa",
  'kjt': "Phrae Pwo Karen",
  'kju': "Kashaya",
  'kjv': "Kaikavian Literary Language",
  'kjx': "Ramopa",
  'kjy': "Erave",
  'kjz': "Bumthangkha",
  'kka': "Kakanda",
  'kkb': "Kwerisa",
  'kkc': "Odoodee",
  'kkd': "Kinuku",
  'kke': "Kakabe",
  'kkf': "Kalaktang Monpa",
  'kkg': "Mabaka Valley Kalinga",
  'kkh': "Khün",
  'kki': "Kagulu",
  'kkj': "Kako",
  'kkk': "Kokota",
  'kkl': "Kosarek Yale",
  'kkm': "Kiong",
  'kkn': "Kon Keu",
  'kko': "Karko",
  'kkp': "Koko-Bera",
  'kkq': "Kaeku",
  'kkr': "Kir-Balar",
  'kks': "Giiwo",
  'kkt': "Koi",
  'kku': "Tumi",
  'kkv': "Kangean",
  'kkw': "Teke-Kukuya",
  'kkx': "Kohin",
  'kky': "Guguyimidjir",
  'kkz': "Kaska",
  'kla': "Klamath-Modoc",
  'klb': "Kiliwa",
  'klc': "Kolbila",
  'kld': "Gamilaraay",
  'kle': "Kulung (Nepal)",
  'klf': "Kendeje",
  'klg': "Tagakaulo",
  'klh': "Weliki",
  'kli': "Kalumpang",
  'klj': "Khalaj",
  'klk': "Kono (Nigeria)",
  'kll': "Kagan Kalagan",
  'klm': "Migum",
  'kln': "Kalenjin",
  'klo': "Kapya",
  'klp': "Kamasa",
  'klq': "Rumu",
  'klr': "Khaling",
  'kls': "Kalasha",
  'klt': "Nukna",
  'klu': "Klao",
  'klv': "Maskelynes",
  'klw': "Lindu",
  'klx': "Koluwawa",
  'kly': "Kalao",
  'klz': "Kabola",
  'kma': "Konni",
  'kmb': "Kimbundu",
  'kmc': "Southern Dong",
  'kmd': "Majukayang Kalinga",
  'kme': "Bakole",
  'kmf': "Kare (Papua New Guinea)",
  'kmg': "Kâte",
  'kmh': "Kalam",
  'kmi': "Kami (Nigeria)",
  'kmj': "Kumarbhag Paharia",
  'kmk': "Limos Kalinga",
  'kml': "Tanudan Kalinga",
  'kmm': "Kom (India)",
  'kmn': "Awtuw",
  'kmo': "Kwoma",
  'kmp': "Gimme",
  'kmq': "Kwama",
  'kmr': "Northern Kurdish",
  'kms': "Kamasau",
  'kmt': "Kemtuik",
  'kmu': "Kanite",
  'kmv': "Karipúna Creole French",
  'kmw': "Komo (Democratic Republic of Congo)",
  'kmx': "Waboda",
  'kmy': "Koma",
  'kmz': "Khorasani Turkish",
  'kna': "Dera (Nigeria)",
  'knb': "Lubuagan Kalinga",
  'knc': "Central Kanuri",
  'knd': "Konda",
  'kne': "Kankanaey",
  'knf': "Mankanya",
  'kng': "Koongo",
  'kni': "Kanufi",
  'knj': "Western Kanjobal",
  'knk': "Kuranko",
  'knl': "Keninjal",
  'knm': "Kanamarí",
  'knn': "Konkani (individual language)",
  'kno': "Kono (Sierra Leone)",
  'knp': "Kwanja",
  'knq': "Kintaq",
  'knr': "Kaningra",
  'kns': "Kensiu",
  'knt': "Panoan Katukína",
  'knu': "Kono (Guinea)",
  'knv': "Tabo",
  'knw': "Kung-Ekoka",
  'knx': "Salako",
  'kny': "Kanyok",
  'knz': "Kalamsé",
  'koa': "Konomala",
  'koc': "Kpati",
  'kod': "Kodi",
  'koe': "Kacipo-Bale Suri",
  'kof': "Kubi",
  'kog': "Kogi",
  'koh': "Koyo",
  'koi': "Komi-Permyak",
  'koj': "Sara Dunjo",
  'kok': "Konkani (macrolanguage)",
  'kol': "Kol (Papua New Guinea)",
  'koo': "Konzo",
  'kop': "Waube",
  'koq': "Kota (Gabon)",
  'kos': "Kosraean",
  'kot': "Lagwan",
  'kou': "Koke",
  'kov': "Kudu-Camo",
  'kow': "Kugama",
  'kox': "Coxima",
  'koy': "Koyukon",
  'koz': "Korak",
  'kpa': "Kutto",
  'kpb': "Mullu Kurumba",
  'kpc': "Curripaco",
  'kpd': "Koba",
  'kpe': "Kpelle",
  'kpf': "Komba",
  'kpg': "Kapingamarangi",
  'kph': "Kplang",
  'kpi': "Kofei",
  'kpj': "Karajá",
  'kpk': "Kpan",
  'kpl': "Kpala",
  'kpm': "Koho",
  'kpn': "Kepkiriwát",
  'kpo': "Ikposo",
  'kpp': "Paku Karen",
  'kpq': "Korupun-Sela",
  'kpr': "Korafe-Yegha",
  'kps': "Tehit",
  'kpt': "Karata",
  'kpu': "Kafoa",
  'kpv': "Komi-Zyrian",
  'kpw': "Kobon",
  'kpx': "Mountain Koiali",
  'kpy': "Koryak",
  'kpz': "Kupsabiny",
  'kqa': "Mum",
  'kqb': "Kovai",
  'kqc': "Doromu-Koki",
  'kqd': "Koy Sanjaq Surat",
  'kqe': "Kalagan",
  'kqf': "Kakabai",
  'kqg': "Khe",
  'kqh': "Kisankasa",
  'kqi': "Koitabu",
  'kqj': "Koromira",
  'kqk': "Kotafon Gbe",
  'kql': "Kyenele",
  'kqm': "Khisa",
  'kqn': "Kaonde",
  'kqo': "Eastern Krahn",
  'kqp': "Kimré",
  'kqq': "Krenak",
  'kqr': "Kimaragang",
  'kqs': "Northern Kissi",
  'kqt': "Klias River Kadazan",
  'kqu': "Seroa",
  'kqv': "Okolod",
  'kqw': "Kandas",
  'kqx': "Mser",
  'kqy': "Koorete",
  'kqz': "Korana",
  'kra': "Kumhali",
  'krb': "Karkin",
  'krc': "Karachay-Balkar",
  'krd': "Kairui-Midiki",
  'kre': "Panará",
  'krf': "Koro (Vanuatu)",
  'krh': "Kurama",
  'kri': "Krio",
  'krj': "Kinaray-A",
  'krk': "Kerek",
  'krl': "Karelian",
  'krm': "Krim",
  'krn': "Sapo",
  'kro': "Kru languages",
  'krp': "Durop",
  'krr': "Krung",
  'krs': "Gbaya (Sudan)",
  'krt': "Tumari Kanuri",
  'kru': "Kurukh",
  'krv': "Kavet",
  'krw': "Western Krahn",
  'krx': "Karon",
  'kry': "Kryts",
  'krz': "Sota Kanum",
  'ksa': "Shuwa-Zamani",
  'ksb': "Shambala",
  'ksc': "Southern Kalinga",
  'ksd': "Kuanua",
  'kse': "Kuni",
  'ksf': "Bafia",
  'ksg': "Kusaghe",
  'ksh': "Kölsch",
  'ksi': "I'saka",
  'ksj': "Uare",
  'ksk': "Kansa",
  'ksl': "Kumalu",
  'ksm': "Kumba",
  'ksn': "Kasiguranin",
  'kso': "Kofa",
  'ksp': "Kaba",
  'ksq': "Kwaami",
  'ksr': "Borong",
  'kss': "Southern Kisi",
  'kst': "Winyé",
  'ksu': "Khamyang",
  'ksv': "Kusu",
  'ksw': "S'gaw Karen",
  'ksx': "Kedang",
  'ksy': "Kharia Thar",
  'ksz': "Kodaku",
  'kta': "Katua",
  'ktb': "Kambaata",
  'ktc': "Kholok",
  'ktd': "Kukatha",
  'kte': "Nubri",
  'ktf': "Kwami",
  'ktg': "Kalkutung",
  'kth': "Karanga",
  'kti': "North Muyu",
  'ktj': "Plapo Krumen",
  'ktk': "Kaniet",
  'ktl': "Koroshi",
  'ktm': "Kurti",
  'ktn': "Karitiâna",
  'kto': "Kuot",
  'ktp': "Kaduo",
  'ktq': "Katabaga",
  'ktr': "Kota Marudu Tinagas",
  'kts': "South Muyu",
  'ktt': "Ketum",
  'ktu': "Kituba (Democratic Republic of Congo)",
  'ktv': "Eastern Katu",
  'ktw': "Kato",
  'ktx': "Kaxararí",
  'kty': "Kango (Bas-Uélé District)",
  'ktz': "Juǀʼhoansi",
  'kub': "Kutep",
  'kuc': "Kwinsu",
  'kud': "'Auhelawa",
  'kue': "Kuman (Papua New Guinea)",
  'kuf': "Western Katu",
  'kug': "Kupa",
  'kuh': "Kushi",
  'kui': "Kalapalo",
  'kuj': "Kuria",
  'kuk': "Kepo'",
  'kul': "Kulere",
  'kum': "Kumyk",
  'kun': "Kunama",
  'kuo': "Kumukio",
  'kup': "Kunimaipa",
  'kuq': "Karipuna",
  'kus': "Kusaal",
  'kut': "Kutenai",
  'kuu': "Upper Kuskokwim",
  'kuv': "Kur",
  'kuw': "Kpagua",
  'kux': "Kukatja",
  'kuy': "Kuuku-Ya'u",
  'kuz': "Kunza",
  'kva': "Bagvalal",
  'kvb': "Kubu",
  'kvc': "Kove",
  'kvd': "Kui (Indonesia)",
  'kve': "Kalabakan",
  'kvf': "Kabalai",
  'kvg': "Kuni-Boazi",
  'kvh': "Komodo",
  'kvi': "Kwang",
  'kvj': "Psikye",
  'kvk': "Korean Sign Language",
  'kvl': "Kayaw",
  'kvm': "Kendem",
  'kvn': "Border Kuna",
  'kvo': "Dobel",
  'kvp': "Kompane",
  'kvq': "Geba Karen",
  'kvr': "Kerinci",
  'kvs': "Kunggara",
  'kvt': "Lahta",
  'kvu': "Yinbaw Karen",
  'kvv': "Kola",
  'kvw': "Wersing",
  'kvx': "Parkari Koli",
  'kvy': "Yintale",
  'kvz': "Tsaukambo",
  'kwa': "Dâw",
  'kwb': "Kwa",
  'kwc': "Likwala",
  'kwd': "Kwaio",
  'kwe': "Kwerba",
  'kwf': "Kwara'ae",
  'kwg': "Sara Kaba Deme",
  'kwh': "Kowiai",
  'kwi': "Awa-Cuaiquer",
  'kwj': "Kwanga",
  'kwk': "Kwakiutl",
  'kwl': "Kofyar",
  'kwm': "Kwambi",
  'kwn': "Kwangali",
  'kwo': "Kwomtari",
  'kwp': "Kodia",
  'kwq': "Kwak",
  'kwr': "Kwer",
  'kws': "Kwese",
  'kwt': "Kwesten",
  'kwu': "Kwakum",
  'kwv': "Sara Kaba Náà",
  'kww': "Kwinti",
  'kwx': "Khirwar",
  'kwy': "San Salvador Kongo",
  'kwz': "Kwadi",
  'kxa': "Kairiru",
  'kxb': "Krobu",
  'kxc': "Khonso",
  'kxd': "Brunei",
  'kxe': "Kakihum",
  'kxf': "Manumanaw",
  'kxh': "Karo (Ethiopia)",
  'kxi': "Keningau Murut",
  'kxj': "Kulfa",
  'kxk': "Zayein Karen",
  'kxl': "Nepali Kurux",
  'kxm': "Northern Khmer",
  'kxn': "Kanowit-Tanjong Melanau",
  'kxo': "Kanoé",
  'kxp': "Wadiyara Koli",
  'kxq': "Smärky Kanum",
  'kxr': "Koro (Papua New Guinea)",
  'kxs': "Kangjia",
  'kxt': "Koiwat",
  'kxu': "Kui (India)",
  'kxv': "Kuvi",
  'kxw': "Konai",
  'kxx': "Likuba",
  'kxy': "Kayong",
  'kxz': "Kerewo",
  'kya': "Kwaya",
  'kyb': "Butbut Kalinga",
  'kyc': "Kyaka",
  'kyd': "Karey",
  'kye': "Krache",
  'kyf': "Kouya",
  'kyg': "Keyagana",
  'kyh': "Karok",
  'kyi': "Kiput",
  'kyj': "Karao",
  'kyk': "Kamayo",
  'kyl': "Kalapuya",
  'kym': "Kpatili",
  'kyn': "Northern Binukidnon",
  'kyo': "Kelon",
  'kyp': "Kang",
  'kyq': "Kenga",
  'kyr': "Kuruáya",
  'kys': "Baram Kayan",
  'kyt': "Kayagar",
  'kyu': "Western Kayah",
  'kyv': "Kayort",
  'kyw': "Kudmali",
  'kyx': "Rapoisi",
  'kyy': "Kambaira",
  'kyz': "Kayabí",
  'kza': "Western Karaboro",
  'kzb': "Kaibobo",
  'kzc': "Bondoukou Kulango",
  'kzd': "Kadai",
  'kze': "Kosena",
  'kzf': "Da'a Kaili",
  'kzg': "Kikai",
  'kzh': "Kenuzi-Dongola",
  'kzi': "Kelabit",
  'kzj': "Coastal Kadazan",
  'kzk': "Kazukuru",
  'kzl': "Kayeli",
  'kzm': "Kais",
  'kzn': "Kokola",
  'kzo': "Kaningi",
  'kzp': "Kaidipang",
  'kzq': "Kaike",
  'kzr': "Karang",
  'kzs': "Sugut Dusun",
  'kzt': "Tambunan Dusun",
  'kzu': "Kayupulau",
  'kzv': "Komyandaret",
  'kzw': "Karirí-Xocó",
  'kzx': "Kamarian",
  'kzy': "Kango (Tshopo District)",
  'kzz': "Kalabra",
  'laa': "Southern Subanen",
  'lab': "Linear A",
  'lac': "Lacandon",
  'lad': "Ladino",
  'lae': "Pattani",
  'laf': "Lafofa",
  'lag': "Rangi",
  'lah': "Lahnda",
  'lai': "Lambya",
  'laj': "Lango (Uganda)",
  'lak': "Laka (Nigeria)",
  'lal': "Lalia",
  'lam': "Lamba",
  'lan': "Laru",
  'lap': "Laka (Chad)",
  'laq': "Qabiao",
  'lar': "Larteh",
  'las': "Lama (Togo)",
  'lau': "Laba",
  'law': "Lauje",
  'lax': "Tiwa",
  'lay': "Lama Bai",
  'laz': "Aribwatsa",
  'lba': "Lui",
  'lbb': "Label",
  'lbc': "Lakkia",
  'lbe': "Lak",
  'lbf': "Tinani",
  'lbg': "Laopang",
  'lbi': "La'bi",
  'lbj': "Ladakhi",
  'lbk': "Central Bontok",
  'lbl': "Libon Bikol",
  'lbm': "Lodhi",
  'lbn': "Rmeet",
  'lbo': "Laven",
  'lbq': "Wampar",
  'lbr': "Lohorung",
  'lbs': "Libyan Sign Language",
  'lbt': "Lachi",
  'lbu': "Labu",
  'lbv': "Lavatbura-Lamusong",
  'lbw': "Tolaki",
  'lbx': "Lawangan",
  'lby': "Lamu-Lamu",
  'lbz': "Lardil",
  'lcc': "Legenyem",
  'lcd': "Lola",
  'lce': "Sekak",
  'lcf': "Lubu",
  'lch': "Luchazi",
  'lcl': "Lisela",
  'lcm': "Tungag",
  'lcp': "Western Lawa",
  'lcq': "Luhu",
  'lcs': "Lisabata-Nuniali",
  'lda': "Kla-Dan",
  'ldb': "Dũya",
  'ldd': "Luri",
  'ldg': "Lenyima",
  'ldh': "Lamja-Dengsa-Tola",
  'ldi': "Laari",
  'ldj': "Lemoro",
  'ldk': "Leelau",
  'ldl': "Kaan",
  'ldm': "Landoma",
  'ldn': "Láadan",
  'ldo': "Loo",
  'ldp': "Tso",
  'ldq': "Lufu",
  'lea': "Lega-Shabunda",
  'leb': "Lala-Bisa",
  'lec': "Leco",
  'led': "Lendu",
  'lee': "Lyélé",
  'lef': "Lelemi",
  'leg': "Lengua",
  'leh': "Lenje",
  'lei': "Lemio",
  'lej': "Lengola",
  'lek': "Leipon",
  'lel': "Lele (Democratic Republic of Congo)",
  'lem': "Nomaande",
  'len': "Lenca",
  'leo': "Leti (Cameroon)",
  'lep': "Lepcha",
  'leq': "Lembena",
  'ler': "Lenkau",
  'les': "Lese",
  'let': "Amio-Gelimi",
  'leu': "Kara (Papua New Guinea)",
  'lev': "Lamma",
  'lew': "Ledo Kaili",
  'lex': "Luang",
  'ley': "Lemolang",
  'lez': "Lezghian",
  'lfa': "Lefa",
  'lfn': "Lingua Franca Nova",
  'lga': "Lungga",
  'lgb': "Laghu",
  'lgg': "Lugbara",
  'lgh': "Laghuu",
  'lgi': "Lengilu",
  'lgk': "Neverver",
  'lgl': "Wala",
  'lgm': "Lega-Mwenga",
  'lgn': "Opuuo",
  'lgo': "Lango (South Sudan)",
  'lgq': "Logba",
  'lgr': "Lengo",
  'lgs': "Língua Gestual Guineense",
  'lgt': "Pahi",
  'lgu': "Longgu",
  'lgz': "Ligenza",
  'lha': "Laha (Viet Nam)",
  'lhh': "Laha (Indonesia)",
  'lhi': "Lahu Shi",
  'lhl': "Lahul Lohar",
  'lhm': "Lhomi",
  'lhn': "Lahanan",
  'lhp': "Lhokpu",
  'lhs': "Mlahsö",
  'lht': "Lo-Toga",
  'lhu': "Lahu",
  'lia': "West-Central Limba",
  'lib': "Likum",
  'lic': "Hlai",
  'lid': "Nyindrou",
  'lie': "Likila",
  'lif': "Limbu",
  'lig': "Ligbi",
  'lih': "Lihir",
  'lii': "Lingkhim",
  'lij': "Ligurian",
  'lik': "Lika",
  'lil': "Lillooet",
  'lio': "Liki",
  'lip': "Sekpele",
  'liq': "Libido",
  'lir': "Liberian English",
  'lis': "Lisu",
  'liu': "Logorik",
  'liv': "Liv",
  'liw': "Col",
  'lix': "Liabuku",
  'liy': "Banda-Bambari",
  'liz': "Libinza",
  'lja': "Golpa",
  'lje': "Rampi",
  'lji': "Laiyolo",
  'ljl': "Li'o",
  'ljp': "Lampung Api",
  'ljw': "Yirandali",
  'ljx': "Yuru",
  'lka': "Lakalei",
  'lkb': "Lukabaras",
  'lkc': "Kucong",
  'lkd': "Lakondê",
  'lke': "Kenyi",
  'lkh': "Lakha",
  'lki': "Laki",
  'lkj': "Remun",
  'lkl': "Laeko-Libuat",
  'lkm': "Kalaamaya",
  'lkn': "Vure",
  'lko': "Olukhayo",
  'lkr': "Päri",
  'lks': "Olushisa",
  'lkt': "Lakota",
  'lku': "Kungkari",
  'lky': "Lokoya",
  'lla': "Lala-Roba",
  'llb': "Lolo",
  'llc': "Lele (Guinea)",
  'lld': "Ladin",
  'lle': "Lele (Papua New Guinea)",
  'llf': "Hermit",
  'llg': "Lole",
  'llh': "Lamu",
  'lli': "Teke-Laali",
  'llj': "Ladji Ladji",
  'llk': "Lelak",
  'lll': "Lilau",
  'llm': "Lasalimu",
  'lln': "Lele (Chad)",
  'llo': "Khlor",
  'llp': "North Efate",
  'llq': "Lolak",
  'lls': "Lithuanian Sign Language",
  'llu': "Lau",
  'llx': "Lauan",
  'lma': "East Limba",
  'lmb': "Merei",
  'lmc': "Limilngan",
  'lmd': "Lumun",
  'lme': "Pévé",
  'lmf': "South Lembata",
  'lmg': "Lamogai",
  'lmh': "Lambichhong",
  'lmi': "Lombi",
  'lmj': "West Lembata",
  'lmk': "Lamkang",
  'lml': "Hano",
  'lmm': "Lamam",
  'lmn': "Lambadi",
  'lmo': "Lombard",
  'lmp': "Limbum",
  'lmq': "Lamatuka",
  'lmr': "Lamalera",
  'lmu': "Lamenu",
  'lmv': "Lomaiviti",
  'lmw': "Lake Miwok",
  'lmx': "Laimbue",
  'lmy': "Lamboya",
  'lmz': "Lumbee",
  'lna': "Langbashe",
  'lnb': "Mbalanhu",
  'lnd': "Lun Bawang",
  'lng': "Langobardic",
  'lnh': "Lanoh",
  'lni': "Daantanai'",
  'lnj': "Leningitij",
  'lnl': "South Central Banda",
  'lnm': "Langam",
  'lnn': "Lorediakarkar",
  'lno': "Lango (South Sudan)",
  'lns': "Lamnso'",
  'lnu': "Longuda",
  'lnw': "Lanima",
  'lnz': "Lonzo",
  'loa': "Loloda",
  'lob': "Lobi",
  'loc': "Inonhan",
  'loe': "Saluan",
  'lof': "Logol",
  'log': "Logo",
  'loh': "Narim",
  'loi': "Loma (Côte d'Ivoire)",
  'loj': "Lou",
  'lok': "Loko",
  'lol': "Mongo",
  'lom': "Loma (Liberia)",
  'lon': "Malawi Lomwe",
  'loo': "Lombo",
  'lop': "Lopa",
  'loq': "Lobala",
  'lor': "Téén",
  'los': "Loniu",
  'lot': "Otuho",
  'lou': "Louisiana Creole",
  'lov': "Lopi",
  'low': "Tampias Lobu",
  'lox': "Loun",
  'loy': "Loke",
  'loz': "Lozi",
  'lpa': "Lelepa",
  'lpe': "Lepki",
  'lpn': "Long Phuri Naga",
  'lpo': "Lipo",
  'lpx': "Lopit",
  'lqr': "Logir",
  'lra': "Rara Bakati'",
  'lrc': "Northern Luri",
  'lre': "Laurentian",
  'lrg': "Laragia",
  'lri': "Olumarachi",
  'lrk': "Loarki",
  'lrl': "Lari",
  'lrm': "Olumarama",
  'lrn': "Lorang",
  'lro': "Laro",
  'lrr': "Southern Yamphu",
  'lrt': "Larantuka Malay",
  'lrv': "Larevat",
  'lrz': "Lemerig",
  'lsa': "Lasgerdi",
  'lsb': "Langue des Signes Burundaise",
  'lsc': "Lengua de señas Albarradas",
  'lsd': "Lishana Deni",
  'lse': "Lusengo",
  'lsg': "Lyons Sign Language",
  'lsh': "Lish",
  'lsi': "Lashi",
  'lsl': "Latvian Sign Language",
  'lsm': "Olusamia",
  'lsn': "Tibetan Sign Language",
  'lso': "Laos Sign Language",
  'lsp': "Lengua de Señas Panameñas",
  'lsr': "Aruop",
  'lss': "Lasi",
  'lst': "Trinidad and Tobago Sign Language",
  'lsv': "Sivia Sign Language",
  'lsw': "Langue des Signes Seychelloise",
  'lsy': "Mauritian Sign Language",
  'ltc': "Late Middle Chinese",
  'ltg': "Latgalian",
  'lth': "Thur",
  'lti': "Leti (Indonesia)",
  'ltn': "Latundê",
  'lto': "Olutsotso",
  'lts': "Lutachoni",
  'ltu': "Latu",
  'lua': "Luba-Lulua",
  'luc': "Aringa",
  'lud': "Ludian",
  'lue': "Luvale",
  'luf': "Laua",
  'lui': "Luiseno",
  'luj': "Luna",
  'luk': "Lunanakha",
  'lul': "Olu'bo",
  'lum': "Luimbi",
  'lun': "Lunda",
  'luo': "Dholuo",
  'lup': "Lumbu",
  'luq': "Lucumi",
  'lur': "Laura",
  'lus': "Lushai",
  'lut': "Lushootseed",
  'luu': "Lumba-Yakkha",
  'luv': "Luwati",
  'luw': "Luo (Cameroon)",
  'luy': "Oluluyia",
  'luz': "Southern Luri",
  'lva': "Maku'a",
  'lvi': "Lavi",
  'lvk': "Lavukaleve",
  'lvl': "Lwel",
  'lvs': "Standard Latvian",
  'lvu': "Levuka",
  'lwa': "Lwalu",
  'lwe': "Lewo Eleng",
  'lwg': "Oluwanga",
  'lwh': "White Lachi",
  'lwl': "Eastern Lawa",
  'lwm': "Laomian",
  'lwo': "Luwo",
  'lws': "Malawian Sign Language",
  'lwt': "Lewotobi",
  'lwu': "Lawu",
  'lww': "Lewo",
  'lxm': "Lakurumau",
  'lya': "Layakha",
  'lyg': "Lyngngam",
  'lyn': "Luyana",
  'lzh': "Literary Chinese",
  'lzl': "Litzlitz",
  'lzn': "Leinong Naga",
  'lzz': "Laz",
  'maa': "San Jerónimo Tecóatl Mazatec",
  'mab': "Yutanduchi Mixtec",
  'mad': "Madurese",
  'mae': "Bo-Rukul",
  'maf': "Mafa",
  'mag': "Magahi",
  'mai': "Maithili",
  'maj': "Jalapa De Díaz Mazatec",
  'mak': "Makasar",
  'mam': "Mam",
  'man': "Manding",
  'map': "Austronesian languages",
  'maq': "Chiquihuitlán Mazatec",
  'mas': "Masai",
  'mat': "San Francisco Matlatzinca",
  'mau': "Huautla Mazatec",
  'mav': "Sateré-Mawé",
  'maw': "Mampruli",
  'max': "North Moluccan Malay",
  'maz': "Central Mazahua",
  'mba': "Higaonon",
  'mbb': "Western Bukidnon Manobo",
  'mbc': "Macushi",
  'mbd': "Dibabawon Manobo",
  'mbe': "Molale",
  'mbf': "Baba Malay",
  'mbh': "Mangseng",
  'mbi': "Ilianen Manobo",
  'mbj': "Nadëb",
  'mbk': "Malol",
  'mbl': "Maxakalí",
  'mbm': "Ombamba",
  'mbn': "Macaguán",
  'mbo': "Mbo (Cameroon)",
  'mbp': "Malayo",
  'mbq': "Maisin",
  'mbr': "Nukak Makú",
  'mbs': "Sarangani Manobo",
  'mbt': "Matigsalug Manobo",
  'mbu': "Mbula-Bwazza",
  'mbv': "Mbulungish",
  'mbw': "Maring",
  'mbx': "Mari (East Sepik Province)",
  'mby': "Memoni",
  'mbz': "Amoltepec Mixtec",
  'mca': "Maca",
  'mcb': "Machiguenga",
  'mcc': "Bitur",
  'mcd': "Sharanahua",
  'mce': "Itundujia Mixtec",
  'mcf': "Matsés",
  'mcg': "Mapoyo",
  'mch': "Maquiritari",
  'mci': "Mese",
  'mcj': "Mvanip",
  'mck': "Mbunda",
  'mcl': "Macaguaje",
  'mcm': "Malaccan Creole Portuguese",
  'mcn': "Masana",
  'mco': "Coatlán Mixe",
  'mcp': "Makaa",
  'mcq': "Ese",
  'mcr': "Menya",
  'mcs': "Mambai",
  'mct': "Mengisa",
  'mcu': "Cameroon Mambila",
  'mcv': "Minanibai",
  'mcw': "Mawa (Chad)",
  'mcx': "Mpiemo",
  'mcy': "South Watut",
  'mcz': "Mawan",
  'mda': "Mada (Nigeria)",
  'mdb': "Morigi",
  'mdc': "Male (Papua New Guinea)",
  'mdd': "Mbum",
  'mde': "Maba (Chad)",
  'mdf': "Moksha",
  'mdg': "Massalat",
  'mdh': "Maguindanaon",
  'mdi': "Mamvu",
  'mdj': "Mangbetu",
  'mdk': "Mangbutu",
  'mdl': "Maltese Sign Language",
  'mdm': "Mayogo",
  'mdn': "Mbati",
  'mdp': "Mbala",
  'mdq': "Mbole",
  'mdr': "Mandar",
  'mds': "Maria (Papua New Guinea)",
  'mdt': "Mbere",
  'mdu': "Mboko",
  'mdv': "Santa Lucía Monteverde Mixtec",
  'mdw': "Mbosi",
  'mdx': "Dizin",
  'mdy': "Male (Ethiopia)",
  'mdz': "Suruí Do Pará",
  'mea': "Menka",
  'meb': "Ikobi",
  'mec': "Marra",
  'med': "Melpa",
  'mee': "Mengen",
  'mef': "Megam",
  'meg': "Mea",
  'meh': "Southwestern Tlaxiaco Mixtec",
  'mei': "Midob",
  'mej': "Meyah",
  'mek': "Mekeo",
  'mel': "Central Melanau",
  'mem': "Mangala",
  'men': "Mende (Sierra Leone)",
  'meo': "Kedah Malay",
  'mep': "Miriwoong",
  'meq': "Merey",
  'mer': "Meru",
  'mes': "Masmaje",
  'met': "Mato",
  'meu': "Motu",
  'mev': "Mano",
  'mew': "Maaka",
  'mey': "Hassaniyya",
  'mez': "Menominee",
  'mfa': "Pattani Malay",
  'mfb': "Bangka",
  'mfc': "Mba",
  'mfd': "Mendankwe-Nkwen",
  'mfe': "Morisyen",
  'mff': "Naki",
  'mfg': "Mogofin",
  'mfh': "Matal",
  'mfi': "Wandala",
  'mfj': "Mefele",
  'mfk': "North Mofu",
  'mfl': "Putai",
  'mfm': "Marghi South",
  'mfn': "Cross River Mbembe",
  'mfo': "Mbe",
  'mfp': "Makassar Malay",
  'mfq': "Moba",
  'mfr': "Marrithiyel",
  'mfs': "Mexican Sign Language",
  'mft': "Mokerang",
  'mfu': "Mbwela",
  'mfv': "Mandjak",
  'mfw': "Mulaha",
  'mfx': "Melo",
  'mfy': "Mayo",
  'mfz': "Mabaan",
  'mga': "Middle Irish (900-1200)",
  'mgb': "Mararit",
  'mgc': "Morokodo",
  'mgd': "Moru",
  'mge': "Mango",
  'mgf': "Maklew",
  'mgg': "Mpumpong",
  'mgh': "Makhuwa-Meetto",
  'mgi': "Lijili",
  'mgj': "Abureni",
  'mgk': "Mawes",
  'mgl': "Maleu-Kilenge",
  'mgm': "Mambae",
  'mgn': "Mbangi",
  'mgo': "Meta'",
  'mgp': "Eastern Magar",
  'mgq': "Malila",
  'mgr': "Mambwe-Lungu",
  'mgs': "Manda (Tanzania)",
  'mgt': "Mongol",
  'mgu': "Mailu",
  'mgv': "Matengo",
  'mgw': "Matumbi",
  'mgx': "Omati",
  'mgy': "Mbunga",
  'mgz': "Mbugwe",
  'mha': "Manda (India)",
  'mhb': "Mahongwe",
  'mhc': "Mocho",
  'mhd': "Mbugu",
  'mhe': "Mah Meri",
  'mhf': "Mamaa",
  'mhg': "Margu",
  'mhh': "Maskoy Pidgin",
  'mhi': "Ma'di",
  'mhj': "Mogholi",
  'mhk': "Mungaka",
  'mhl': "Mauwake",
  'mhm': "Makhuwa-Moniga",
  'mhn': "Mócheno",
  'mho': "Mashi (Zambia)",
  'mhp': "Balinese Malay",
  'mhq': "Mandan",
  'mhr': "Eastern Mari",
  'mhs': "Buru (Indonesia)",
  'mht': "Mandahuaca",
  'mhu': "Darang Deng",
  'mhw': "Mbukushu",
  'mhx': "Lhaovo",
  'mhy': "Ma'anyan",
  'mhz': "Mor (Mor Islands)",
  'mia': "Miami",
  'mib': "Atatláhuca Mixtec",
  'mic': "Micmac",
  'mid': "Mandaic",
  'mie': "Ocotepec Mixtec",
  'mif': "Mofu-Gudur",
  'mig': "San Miguel El Grande Mixtec",
  'mih': "Chayuco Mixtec",
  'mii': "Chigmecatitlán Mixtec",
  'mij': "Mungbam",
  'mik': "Mikasuki",
  'mil': "Peñoles Mixtec",
  'mim': "Alacatlatzala Mixtec",
  'min': "Minangkabau",
  'mio': "Pinotepa Nacional Mixtec",
  'mip': "Apasco-Apoala Mixtec",
  'miq': "Mískito",
  'mir': "Isthmus Mixe",
  'mis': "Uncoded languages",
  'mit': "Southern Puebla Mixtec",
  'miu': "Cacaloxtepec Mixtec",
  'miw': "Akoye",
  'mix': "Mixtepec Mixtec",
  'miy': "Ayutla Mixtec",
  'miz': "Coatzospan Mixtec",
  'mja': "Mahei",
  'mjb': "Makalero",
  'mjc': "San Juan Colorado Mixtec",
  'mjd': "Northwest Maidu",
  'mje': "Muskum",
  'mjg': "Tu",
  'mjh': "Mwera (Nyasa)",
  'mji': "Kim Mun",
  'mjj': "Mawak",
  'mjk': "Matukar",
  'mjl': "Mandeali",
  'mjm': "Medebur",
  'mjn': "Ma (Papua New Guinea)",
  'mjo': "Malankuravan",
  'mjp': "Malapandaram",
  'mjq': "Malaryan",
  'mjr': "Malavedan",
  'mjs': "Miship",
  'mjt': "Sauria Paharia",
  'mju': "Manna-Dora",
  'mjv': "Mannan",
  'mjw': "Karbi",
  'mjx': "Mahali",
  'mjy': "Mahican",
  'mjz': "Majhi",
  'mka': "Mbre",
  'mkb': "Mal Paharia",
  'mkc': "Siliput",
  'mke': "Mawchi",
  'mkf': "Miya",
  'mkg': "Mak (China)",
  'mkh': "Mon-Khmer languages",
  'mki': "Dhatki",
  'mkj': "Mokilese",
  'mkk': "Byep",
  'mkl': "Mokole",
  'mkm': "Moklen",
  'mkn': "Kupang Malay",
  'mko': "Mingang Doso",
  'mkp': "Moikodi",
  'mkq': "Bay Miwok",
  'mkr': "Malas",
  'mks': "Silacayoapan Mixtec",
  'mkt': "Vamale",
  'mku': "Konyanka Maninka",
  'mkv': "Mafea",
  'mkw': "Kituba (Congo)",
  'mkx': "Kinamiging Manobo",
  'mky': "East Makian",
  'mkz': "Makasae",
  'mla': "Malo",
  'mlb': "Mbule",
  'mlc': "Cao Lan",
  'mld': "Malakhel",
  'mle': "Manambu",
  'mlf': "Mal",
  'mlh': "Mape",
  'mli': "Malimpung",
  'mlj': "Miltu",
  'mlk': "Kiwilwana",
  'mll': "Malua Bay",
  'mlm': "Mulam",
  'mln': "Malango",
  'mlo': "Mlomp",
  'mlp': "Bargam",
  'mlq': "Western Maninkakan",
  'mlr': "Vame",
  'mls': "Masalit",
  'mlu': "To'abaita",
  'mlv': "Mwotlap",
  'mlw': "Moloko",
  'mlx': "Naha'ai",
  'mlz': "Malaynon",
  'mma': "Mama",
  'mmb': "Momina",
  'mmc': "Michoacán Mazahua",
  'mmd': "Maonan",
  'mme': "Mae",
  'mmf': "Mundat",
  'mmg': "North Ambrym",
  'mmh': "Mehináku",
  'mmi': "Musar",
  'mmj': "Majhwar",
  'mmk': "Mukha-Dora",
  'mml': "Man Met",
  'mmm': "Maii",
  'mmn': "Mamanwa",
  'mmo': "Mangga Buang",
  'mmp': "Siawi",
  'mmq': "Musak",
  'mmr': "Western Xiangxi Miao",
  'mmt': "Malalamai",
  'mmu': "Mmaala",
  'mmv': "Miriti",
  'mmw': "Emae",
  'mmx': "Madak",
  'mmy': "Migaama",
  'mmz': "Mabaale",
  'mna': "Mbula",
  'mnb': "Muna",
  'mnc': "Manchu",
  'mnd': "Mondé",
  'mne': "Naba",
  'mnf': "Mundani",
  'mng': "Eastern Mnong",
  'mnh': "Mono (Democratic Republic of Congo)",
  'mni': "Manipuri",
  'mnj': "Munji",
  'mnk': "Mandinka",
  'mnl': "Tiale",
  'mnm': "Mapena",
  'mnn': "Southern Mnong",
  'mno': "Manobo languages",
  'mnp': "Min Bei Chinese",
  'mnq': "Minriq",
  'mnr': "Mono (USA)",
  'mns': "Mansi",
  'mnt': "Maykulan",
  'mnu': "Mer",
  'mnv': "Rennell-Bellona",
  'mnw': "Mon",
  'mnx': "Manikion",
  'mny': "Manyawa",
  'mnz': "Moni",
  'moa': "Mwan",
  'moc': "Mocoví",
  'mod': "Mobilian",
  'moe': "Montagnais",
  'mof': "Mohegan-Montauk-Narragansett",
  'mog': "Mongondow",
  'moh': "Mohawk",
  'moi': "Mboi",
  'moj': "Monzombo",
  'mok': "Morori",
  'mom': "Mangue",
  'moo': "Monom",
  'mop': "Mopán Maya",
  'moq': "Mor (Bomberai Peninsula)",
  'mor': "Moro",
  'mos': "Mossi",
  'mot': "Barí",
  'mou': "Mogum",
  'mov': "Mohave",
  'mow': "Moi (Congo)",
  'mox': "Molima",
  'moy': "Shekkacho",
  'moz': "Gergiko",
  'mpa': "Mpoto",
  'mpb': "Mullukmulluk",
  'mpc': "Mangarrayi",
  'mpd': "Machinere",
  'mpe': "Majang",
  'mpg': "Marba",
  'mph': "Maung",
  'mpi': "Mpade",
  'mpj': "Wangkajunga",
  'mpk': "Mbara (Chad)",
  'mpl': "Middle Watut",
  'mpm': "Yosondúa Mixtec",
  'mpn': "Mindiri",
  'mpo': "Miu",
  'mpp': "Migabac",
  'mpq': "Matís",
  'mpr': "Vangunu",
  'mps': "Dadibi",
  'mpt': "Mian",
  'mpu': "Makuráp",
  'mpv': "Mungkip",
  'mpw': "Mapidian",
  'mpx': "Misima-Panaeati",
  'mpy': "Mapia",
  'mpz': "Mpi",
  'mqa': "Maba (Indonesia)",
  'mqb': "Mbuko",
  'mqc': "Mangole",
  'mqe': "Matepi",
  'mqf': "Momuna",
  'mqg': "Kota Bangun Kutai Malay",
  'mqh': "Tlazoyaltepec Mixtec",
  'mqi': "Mariri",
  'mqj': "Mamasa",
  'mqk': "Rajah Kabunsuwan Manobo",
  'mql': "Mbelime",
  'mqm': "South Marquesan",
  'mqn': "Moronene",
  'mqo': "Modole",
  'mqp': "Manipa",
  'mqq': "Minokok",
  'mqr': "Mander",
  'mqs': "West Makian",
  'mqt': "Mok",
  'mqu': "Mandari",
  'mqv': "Mosimo",
  'mqw': "Murupi",
  'mqx': "Mamuju",
  'mqy': "Manggarai",
  'mqz': "Pano",
  'mra': "Mlabri",
  'mrb': "Marino",
  'mrc': "Maricopa",
  'mrd': "Western Magar",
  'mre': "Martha's Vineyard Sign Language",
  'mrf': "Elseng",
  'mrg': "Mising",
  'mrh': "Mara Chin",
  'mrj': "Western Mari",
  'mrk': "Hmwaveke",
  'mrl': "Mortlockese",
  'mrm': "Mwerlap",
  'mrn': "Cheke Holo",
  'mro': "Mru",
  'mrp': "Morouas",
  'mrq': "North Marquesan",
  'mrr': "Maria (India)",
  'mrs': "Maragus",
  'mrt': "Marghi Central",
  'mru': "Mono (Cameroon)",
  'mrv': "Mangareva",
  'mrw': "Maranao",
  'mrx': "Dineor",
  'mry': "Mandaya",
  'mrz': "Marind",
  'msb': "Masbatenyo",
  'msc': "Sankaran Maninka",
  'msd': "Yucatec Maya Sign Language",
  'mse': "Musey",
  'msf': "Mekwei",
  'msg': "Moraid",
  'msh': "Masikoro Malagasy",
  'msi': "Sabah Malay",
  'msj': "Ma (Democratic Republic of Congo)",
  'msk': "Mansaka",
  'msl': "Poule",
  'msm': "Agusan Manobo",
  'msn': "Vurës",
  'mso': "Mombum",
  'msp': "Maritsauá",
  'msq': "Caac",
  'msr': "Mongolian Sign Language",
  'mss': "West Masela",
  'mst': "Cataelano Mandaya",
  'msu': "Musom",
  'msv': "Maslam",
  'msw': "Mansoanka",
  'msx': "Moresada",
  'msy': "Aruamu",
  'msz': "Momare",
  'mta': "Cotabato Manobo",
  'mtb': "Anyin Morofo",
  'mtc': "Munit",
  'mtd': "Mualang",
  'mte': "Mono (Solomon Islands)",
  'mtf': "Murik (Papua New Guinea)",
  'mtg': "Una",
  'mth': "Munggui",
  'mti': "Maiwa (Papua New Guinea)",
  'mtj': "Moskona",
  'mtk': "Mbe'",
  'mtl': "Montol",
  'mtm': "Mator",
  'mtn': "Matagalpa",
  'mto': "Totontepec Mixe",
  'mtp': "Wichí Lhamtés Nocten",
  'mtq': "Muong",
  'mtr': "Mewari",
  'mts': "Yora",
  'mtt': "Mota",
  'mtu': "Tututepec Mixtec",
  'mtv': "Asaro'o",
  'mtw': "Southern Binukidnon",
  'mtx': "Tidaá Mixtec",
  'mty': "Nabi",
  'mua': "Mundang",
  'mub': "Mubi",
  'muc': "Ajumbu",
  'mud': "Mednyj Aleut",
  'mue': "Media Lengua",
  'mug': "Musgu",
  'muh': "Mündü",
  'mui': "Musi",
  'muj': "Mabire",
  'muk': "Mugom",
  'mul': "Multiple languages",
  'mum': "Maiwala",
  'mun': "Munda languages",
  'muo': "Nyong",
  'mup': "Malvi",
  'muq': "Eastern Xiangxi Miao",
  'mur': "Murle",
  'mus': "Creek",
  'mut': "Western Muria",
  'muu': "Yaaku",
  'muv': "Muthuvan",
  'mux': "Bo-Ung",
  'muy': "Muyang",
  'muz': "Mursi",
  'mva': "Manam",
  'mvb': "Mattole",
  'mvd': "Mamboru",
  'mve': "Marwari (Pakistan)",
  'mvf': "Peripheral Mongolian",
  'mvg': "Yucuañe Mixtec",
  'mvh': "Mulgi",
  'mvi': "Miyako",
  'mvk': "Mekmek",
  'mvl': "Mbara (Australia)",
  'mvm': "Muya",
  'mvn': "Minaveha",
  'mvo': "Marovo",
  'mvp': "Duri",
  'mvq': "Moere",
  'mvr': "Marau",
  'mvs': "Massep",
  'mvt': "Mpotovoro",
  'mvu': "Marfa",
  'mvv': "Tagal Murut",
  'mvw': "Machinga",
  'mvx': "Meoswar",
  'mvy': "Indus Kohistani",
  'mvz': "Mesqan",
  'mwa': "Mwatebu",
  'mwb': "Juwal",
  'mwc': "Are",
  'mwd': "Mudbura",
  'mwe': "Mwera (Chimwera)",
  'mwf': "Murrinh-Patha",
  'mwg': "Aiklep",
  'mwh': "Mouk-Aria",
  'mwi': "Ninde",
  'mwj': "Maligo",
  'mwk': "Kita Maninkakan",
  'mwl': "Mirandese",
  'mwm': "Sar",
  'mwn': "Nyamwanga",
  'mwo': "Central Maewo",
  'mwp': "Kala Lagaw Ya",
  'mwq': "Mün Chin",
  'mwr': "Marwari",
  'mws': "Mwimbi-Muthambi",
  'mwt': "Moken",
  'mwu': "Mittu",
  'mwv': "Mentawai",
  'mww': "Hmong Daw",
  'mwx': "Mediak",
  'mwy': "Mosiro",
  'mwz': "Moingi",
  'mxa': "Northwest Oaxaca Mixtec",
  'mxb': "Tezoatlán Mixtec",
  'mxc': "Manyika",
  'mxd': "Modang",
  'mxe': "Mele-Fila",
  'mxf': "Malgbe",
  'mxg': "Mbangala",
  'mxh': "Mvuba",
  'mxi': "Mozarabic",
  'mxj': "Geman Deng",
  'mxk': "Monumbo",
  'mxl': "Maxi Gbe",
  'mxm': "Meramera",
  'mxn': "Moi (Indonesia)",
  'mxo': "Mbowe",
  'mxp': "Tlahuitoltepec Mixe",
  'mxq': "Juquila Mixe",
  'mxr': "Murik (Malaysia)",
  'mxs': "Huitepec Mixtec",
  'mxt': "Jamiltepec Mixtec",
  'mxu': "Mada (Cameroon)",
  'mxv': "Metlatónoc Mixtec",
  'mxw': "Namo",
  'mxx': "Mawukakan",
  'mxy': "Southeastern Nochixtlán Mixtec",
  'mxz': "Central Masela",
  'myb': "Mbay",
  'myc': "Mayeka",
  'myd': "Maramba",
  'mye': "Myene",
  'myf': "Bambassi",
  'myg': "Manta",
  'myh': "Makah",
  'myi': "Mina (India)",
  'myj': "Mangayat",
  'myk': "Mamara Senoufo",
  'myl': "Moma",
  'mym': "Me'en",
  'myn': "Mayan languages",
  'myo': "Anfillo",
  'myp': "Pirahã",
  'myq': "Forest Maninka",
  'myr': "Muniche",
  'mys': "Mesmes",
  'myt': "Sangab Mandaya",
  'myu': "Mundurukú",
  'myv': "Erzya",
  'myw': "Muyuw",
  'myx': "Masaaba",
  'myy': "Macuna",
  'myz': "Classical Mandaic",
  'mza': "Santa María Zacatepec Mixtec",
  'mzb': "Tumzabt",
  'mzc': "Madagascar Sign Language",
  'mzd': "Malimba",
  'mze': "Morawa",
  'mzg': "Monastic Sign Language",
  'mzh': "Wichí Lhamtés Güisnay",
  'mzi': "Ixcatlán Mazatec",
  'mzj': "Manya",
  'mzk': "Nigeria Mambila",
  'mzl': "Mazatlán Mixe",
  'mzm': "Mumuye",
  'mzn': "Mazanderani",
  'mzo': "Matipuhy",
  'mzp': "Movima",
  'mzq': "Mori Atas",
  'mzr': "Marúbo",
  'mzs': "Macanese",
  'mzt': "Mintil",
  'mzu': "Inapang",
  'mzv': "Manza",
  'mzw': "Deg",
  'mzx': "Mawayana",
  'mzy': "Mozambican Sign Language",
  'mzz': "Maiadomu",
  'naa': "Namla",
  'nab': "Southern Nambikuára",
  'nac': "Narak",
  'nad': "Nijadali",
  'nae': "Naka'ela",
  'naf': "Nabak",
  'nag': "Naga Pidgin",
  'nah': "Nahuatl languages",
  'nai': "North American Indian languages",
  'naj': "Nalu",
  'nak': "Nakanai",
  'nal': "Nalik",
  'nam': "Ngan'gityemerri",
  'nan': "Min Nan Chinese",
  'nao': "Naaba",
  'nap': "Neapolitan",
  'naq': "Nama (Namibia)",
  'nar': "Iguta",
  'nas': "Naasioi",
  'nat': "Hungworo",
  'naw': "Nawuri",
  'nax': "Nakwi",
  'nay': "Ngarrindjeri",
  'naz': "Coatepec Nahuatl",
  'nba': "Nyemba",
  'nbb': "Ndoe",
  'nbc': "Chang Naga",
  'nbd': "Ngbinda",
  'nbe': "Konyak Naga",
  'nbf': "Naxi",
  'nbg': "Nagarchal",
  'nbh': "Ngamo",
  'nbi': "Mao Naga",
  'nbj': "Ngarinyman",
  'nbk': "Nake",
  'nbm': "Ngbaka Ma'bo",
  'nbn': "Kuri",
  'nbo': "Nkukoli",
  'nbp': "Nnam",
  'nbq': "Nggem",
  'nbr': "Numana",
  'nbs': "Namibian Sign Language",
  'nbt': "Na",
  'nbu': "Rongmei Naga",
  'nbv': "Ngamambo",
  'nbw': "Southern Ngbandi",
  'nbx': "Ngura",
  'nby': "Ningera",
  'nca': "Iyo",
  'ncb': "Central Nicobarese",
  'ncc': "Ponam",
  'ncd': "Nachering",
  'nce': "Yale",
  'ncf': "Notsi",
  'ncg': "Nisga'a",
  'nch': "Central Huasteca Nahuatl",
  'nci': "Classical Nahuatl",
  'ncj': "Northern Puebla Nahuatl",
  'nck': "Na-kara",
  'ncl': "Michoacán Nahuatl",
  'ncm': "Nambo",
  'ncn': "Nauna",
  'nco': "Sibe",
  'ncp': "Ndaktup",
  'ncq': "Northern Katang",
  'ncr': "Ncane",
  'ncs': "Nicaraguan Sign Language",
  'nct': "Chothe Naga",
  'ncu': "Chumburung",
  'ncx': "Central Puebla Nahuatl",
  'ncz': "Natchez",
  'nda': "Ndasa",
  'ndb': "Kenswei Nsei",
  'ndc': "Ndau",
  'ndd': "Nde-Nsele-Nta",
  'ndf': "Nadruvian",
  'ndg': "Ndengereko",
  'ndh': "Ndali",
  'ndi': "Samba Leko",
  'ndj': "Ndamba",
  'ndk': "Ndaka",
  'ndl': "Ndolo",
  'ndm': "Ndam",
  'ndn': "Ngundi",
  'ndp': "Ndo",
  'ndq': "Ndombe",
  'ndr': "Ndoola",
  'nds': "Low Saxon",
  'ndt': "Ndunga",
  'ndu': "Dugun",
  'ndv': "Ndut",
  'ndw': "Ndobo",
  'ndx': "Nduga",
  'ndy': "Lutos",
  'ndz': "Ndogo",
  'nea': "Eastern Ngad'a",
  'neb': "Toura (Côte d'Ivoire)",
  'nec': "Nedebang",
  'ned': "Nde-Gbite",
  'nee': "Nêlêmwa-Nixumwak",
  'nef': "Nefamese",
  'neg': "Negidal",
  'neh': "Nyenkha",
  'nei': "Neo-Hittite",
  'nej': "Neko",
  'nek': "Neku",
  'nem': "Nemi",
  'nen': "Nengone",
  'neo': "Ná-Meo",
  'neq': "North Central Mixe",
  'ner': "Yahadian",
  'nes': "Bhoti Kinnauri",
  'net': "Nete",
  'neu': "Neo",
  'nev': "Nyaheun",
  'new': "Nepal Bhasa",
  'nex': "Neme",
  'ney': "Neyo",
  'nez': "Nez Perce",
  'nfa': "Dhao",
  'nfd': "Ahwai",
  'nfl': "Äiwoo",
  'nfr': "Nafaanra",
  'nfu': "Mfumte",
  'nga': "Ngbaka",
  'ngb': "Northern Ngbandi",
  'ngc': "Ngombe (Democratic Republic of Congo)",
  'ngd': "Ngando (Central African Republic)",
  'nge': "Ngemba",
  'ngf': "Trans-New Guinea languages",
  'ngg': "Ngbaka Manza",
  'ngh': "Nǁng",
  'ngi': "Ngizim",
  'ngj': "Ngie",
  'ngk': "Dalabon",
  'ngl': "Lomwe",
  'ngm': "Ngatik Men's Creole",
  'ngn': "Ngwo",
  'ngo': "Ngoni",
  'ngp': "Ngulu",
  'ngq': "Ngoreme",
  'ngr': "Engdewu",
  'ngs': "Gvoko",
  'ngt': "Ngeq",
  'ngu': "Guerrero Nahuatl",
  'ngv': "Nagumi",
  'ngw': "Ngwaba",
  'ngx': "Nggwahyi",
  'ngy': "Tibea",
  'ngz': "Ngungwel",
  'nha': "Nhanda",
  'nhb': "Beng",
  'nhc': "Tabasco Nahuatl",
  'nhd': "Ava Guaraní",
  'nhe': "Eastern Huasteca Nahuatl",
  'nhf': "Nhuwala",
  'nhg': "Tetelcingo Nahuatl",
  'nhh': "Nahari",
  'nhi': "Zacatlán-Ahuacatlán-Tepetzintla Nahuatl",
  'nhk': "Isthmus-Cosoleacaque Nahuatl",
  'nhm': "Morelos Nahuatl",
  'nhn': "Central Nahuatl",
  'nho': "Takuu",
  'nhp': "Isthmus-Pajapan Nahuatl",
  'nhq': "Huaxcaleca Nahuatl",
  'nhr': "Naro",
  'nht': "Ometepec Nahuatl",
  'nhu': "Noone",
  'nhv': "Temascaltepec Nahuatl",
  'nhw': "Western Huasteca Nahuatl",
  'nhx': "Isthmus-Mecayapan Nahuatl",
  'nhy': "Northern Oaxaca Nahuatl",
  'nhz': "Santa María La Alta Nahuatl",
  'nia': "Nias",
  'nib': "Nakame",
  'nic': "Niger-Kordofanian languages",
  'nid': "Ngandi",
  'nie': "Niellim",
  'nif': "Nek",
  'nig': "Ngalakgan",
  'nih': "Nyiha (Tanzania)",
  'nii': "Nii",
  'nij': "Ngaju",
  'nik': "Southern Nicobarese",
  'nil': "Nila",
  'nim': "Nilamba",
  'nin': "Ninzo",
  'nio': "Nganasan",
  'niq': "Nandi",
  'nir': "Nimboran",
  'nis': "Nimi",
  'nit': "Southeastern Kolami",
  'niu': "Niuean",
  'niv': "Gilyak",
  'niw': "Nimo",
  'nix': "Hema",
  'niy': "Ngiti",
  'niz': "Ningil",
  'nja': "Nzanyi",
  'njb': "Nocte Naga",
  'njd': "Ndonde Hamba",
  'njh': "Lotha Naga",
  'nji': "Gudanji",
  'njj': "Njen",
  'njl': "Njalgulgule",
  'njm': "Angami Naga",
  'njn': "Liangmai Naga",
  'njo': "Ao Naga",
  'njr': "Njerep",
  'njs': "Nisa",
  'njt': "Ndyuka-Trio Pidgin",
  'nju': "Ngadjunmaya",
  'njx': "Kunyi",
  'njy': "Njyem",
  'njz': "Nyishi",
  'nka': "Nkoya",
  'nkb': "Khoibu Naga",
  'nkc': "Nkongho",
  'nkd': "Koireng",
  'nke': "Duke",
  'nkf': "Inpui Naga",
  'nkg': "Nekgini",
  'nkh': "Khezha Naga",
  'nki': "Thangal Naga",
  'nkj': "Nakai",
  'nkk': "Nokuku",
  'nkm': "Namat",
  'nkn': "Nkangala",
  'nko': "Nkonya",
  'nkp': "Niuatoputapu",
  'nkq': "Nkami",
  'nkr': "Nukuoro",
  'nks': "North Asmat",
  'nkt': "Nyika (Tanzania)",
  'nku': "Bouna Kulango",
  'nkv': "Nyika (Malawi and Zambia)",
  'nkw': "Nkutu",
  'nkx': "Nkoroo",
  'nkz': "Nkari",
  'nla': "Ngombale",
  'nlc': "Nalca",
  'nle': "East Nyala",
  'nlg': "Gela",
  'nli': "Grangali",
  'nlj': "Nyali",
  'nlk': "Ninia Yali",
  'nll': "Nihali",
  'nlm': "Mankiyali",
  'nln': "Durango Nahuatl",
  'nlo': "Ngul",
  'nlq': "Lao Naga",
  'nlr': "Ngarla",
  'nlu': "Nchumbulu",
  'nlv': "Orizaba Nahuatl",
  'nlw': "Walangama",
  'nlx': "Nahali",
  'nly': "Nyamal",
  'nlz': "Nalögo",
  'nma': "Maram Naga",
  'nmb': "V'ënen Taut",
  'nmc': "Ngam",
  'nmd': "Ndumu",
  'nme': "Mzieme Naga",
  'nmf': "Tangkhul Naga (India)",
  'nmg': "Kwasio",
  'nmh': "Monsang Naga",
  'nmi': "Nyam",
  'nmj': "Ngombe (Central African Republic)",
  'nmk': "Namakura",
  'nml': "Ndemli",
  'nmm': "Manangba",
  'nmn': "ǃXóõ",
  'nmo': "Moyon Naga",
  'nmp': "Nimanbur",
  'nmq': "Nambya",
  'nmr': "Nimbari",
  'nms': "Letemboi",
  'nmt': "Namonuito",
  'nmu': "Northeast Maidu",
  'nmv': "Ngamini",
  'nmw': "Rifao",
  'nmx': "Nama (Papua New Guinea)",
  'nmy': "Namuyi",
  'nmz': "Nawdm",
  'nna': "Nyangumarta",
  'nnb': "Nande",
  'nnc': "Nancere",
  'nnd': "West Ambae",
  'nne': "Ngandyera",
  'nnf': "Ngaing",
  'nng': "Maring Naga",
  'nnh': "Ngiemboon",
  'nni': "North Nuaulu",
  'nnj': "Nyangatom",
  'nnk': "Nankina",
  'nnl': "Northern Rengma Naga",
  'nnm': "Namia",
  'nnn': "Ngete",
  'nnp': "Wancho Naga",
  'nnq': "Ngindo",
  'nnr': "Narungga",
  'nns': "Ningye",
  'nnt': "Nanticoke",
  'nnu': "Dwang",
  'nnv': "Nugunu (Australia)",
  'nnw': "Southern Nuni",
  'nnx': "Ngong",
  'nny': "Nyangga",
  'nnz': "Nda'nda'",
  'noa': "Woun Meu",
  'noc': "Nuk",
  'nod': "Northern Thai",
  'noe': "Nimadi",
  'nof': "Nomane",
  'nog': "Nogai",
  'noh': "Nomu",
  'noi': "Noiri",
  'noj': "Nonuya",
  'nok': "Nooksack",
  'nol': "Nomlaki",
  'nom': "Nocamán",
  'non': "Old Norse",
  'noo': "Nootka",
  'nop': "Numanggang",
  'noq': "Ngongo",
  'nos': "Eastern Nisu",
  'not': "Nomatsiguenga",
  'nou': "Ewage-Notu",
  'nov': "Novial",
  'now': "Nyambo",
  'noy': "Noy",
  'noz': "Nayi",
  'npa': "Nar Phu",
  'npb': "Nupbikha",
  'npg': "Ponyo-Gongwang Naga",
  'nph': "Phom Naga",
  'npi': "Nepali (individual language)",
  'npl': "Southeastern Puebla Nahuatl",
  'npn': "Mondropolon",
  'npo': "Pochuri Naga",
  'nps': "Nipsan",
  'npu': "Puimei Naga",
  'npx': "Noipx",
  'npy': "Napu",
  'nqg': "Southern Nago",
  'nqk': "Kura Ede Nago",
  'nql': "Ngendelengo",
  'nqm': "Ndom",
  'nqn': "Nen",
  'nqo': "N’Ko",
  'nqq': "Kyan-Karyaw Naga",
  'nqt': "Nteng",
  'nqy': "Akyaung Ari Naga",
  'nra': "Ngom",
  'nrb': "Nara",
  'nrc': "Noric",
  'nre': "Southern Rengma Naga",
  'nrf': "Sercquiais",
  'nrg': "Narango",
  'nri': "Chokri Naga",
  'nrk': "Ngarla",
  'nrl': "Ngarluma",
  'nrm': "Narom",
  'nrn': "Norn",
  'nrp': "North Picene",
  'nrr': "Nora",
  'nrt': "Northern Kalapuya",
  'nru': "Narua",
  'nrx': "Ngurmbur",
  'nrz': "Lala",
  'nsa': "Sangtam Naga",
  'nsb': "Lower Nossob",
  'nsc': "Nshi",
  'nsd': "Southern Nisu",
  'nse': "Nsenga",
  'nsf': "Northwestern Nisu",
  'nsg': "Ngasa",
  'nsh': "Ngoshie",
  'nsi': "Nigerian Sign Language",
  'nsk': "Naskapi",
  'nsl': "Norwegian Sign Language",
  'nsm': "Sumi Naga",
  'nsn': "Nehan",
  'nso': "Sepedi",
  'nsp': "Nepalese Sign Language",
  'nsq': "Northern Sierra Miwok",
  'nsr': "Maritime Sign Language",
  'nss': "Nali",
  'nst': "Tase Naga",
  'nsu': "Sierra Negra Nahuatl",
  'nsv': "Southwestern Nisu",
  'nsw': "Navut",
  'nsx': "Nsongo",
  'nsy': "Nasal",
  'nsz': "Nisenan",
  'ntd': "Northern Tidung",
  'nte': "Nathembo",
  'ntg': "Ngantangarra",
  'nti': "Natioro",
  'ntj': "Ngaanyatjarra",
  'ntk': "Ikoma-Nata-Isenye",
  'ntm': "Nateni",
  'nto': "Ntomba",
  'ntp': "Northern Tepehuan",
  'ntr': "Delo",
  'nts': "Natagaimas",
  'ntu': "Natügu",
  'ntw': "Nottoway",
  'ntx': "Tangkhul Naga (Myanmar)",
  'nty': "Mantsi",
  'ntz': "Natanzi",
  'nua': "Yuanga",
  'nub': "Nubian languages",
  'nuc': "Nukuini",
  'nud': "Ngala",
  'nue': "Ngundu",
  'nuf': "Nusu",
  'nug': "Nungali",
  'nuh': "Ndunda",
  'nui': "Ngumbi",
  'nuj': "Nyole",
  'nuk': "Nuuchahnulth",
  'nul': "Nusa Laut",
  'num': "Niuafo'ou",
  'nun': "Anong",
  'nuo': "Nguôn",
  'nup': "Nupe-Nupe-Tako",
  'nuq': "Nukumanu",
  'nur': "Nukuria",
  'nus': "Nuer",
  'nut': "Nung (Viet Nam)",
  'nuu': "Ngbundu",
  'nuv': "Northern Nuni",
  'nuw': "Nguluwan",
  'nux': "Mehek",
  'nuy': "Nunggubuyu",
  'nuz': "Tlamacazapa Nahuatl",
  'nvh': "Nasarian",
  'nvm': "Namiae",
  'nvo': "Nyokon",
  'nwa': "Nawathinehena",
  'nwb': "Nyabwa",
  'nwc': "Old Newari",
  'nwe': "Ngwe",
  'nwg': "Ngayawung",
  'nwi': "Southwest Tanna",
  'nwm': "Nyamusa-Molo",
  'nwo': "Nauo",
  'nwr': "Nawaru",
  'nww': "Ndwewe",
  'nwx': "Middle Newar",
  'nwy': "Nottoway-Meherrin",
  'nxa': "Nauete",
  'nxd': "Ngando (Democratic Republic of Congo)",
  'nxe': "Nage",
  'nxg': "Ngad'a",
  'nxi': "Nindi",
  'nxk': "Koki Naga",
  'nxl': "South Nuaulu",
  'nxm': "Numidian",
  'nxn': "Ngawun",
  'nxo': "Ndambomo",
  'nxq': "Naxi",
  'nxr': "Ninggerum",
  'nxu': "Narau",
  'nxx': "Nafri",
  'nyb': "Nyangbo",
  'nyc': "Nyanga-li",
  'nyd': "Olunyole",
  'nye': "Nyengo",
  'nyf': "Kigiryama",
  'nyg': "Nyindu",
  'nyh': "Nyikina",
  'nyi': "Ama (Sudan)",
  'nyj': "Nyanga",
  'nyk': "Nyaneka",
  'nyl': "Nyeu",
  'nym': "Nyamwezi",
  'nyn': "Nyankole",
  'nyo': "Nyoro",
  'nyp': "Nyang'i",
  'nyq': "Nayini",
  'nyr': "Nyiha (Malawi)",
  'nys': "Nyungar",
  'nyt': "Nyawaygi",
  'nyu': "Nyungwe",
  'nyv': "Nyulnyul",
  'nyw': "Nyaw",
  'nyx': "Nganyaywana",
  'nyy': "Nyakyusa-Ngonde",
  'nza': "Tigon Mbembe",
  'nzb': "Njebi",
  'nzd': "Nzadi",
  'nzi': "Nzima",
  'nzk': "Nzakara",
  'nzm': "Zeme Naga",
  'nzr': "Dir-Nyamzak-Mbarimi",
  'nzs': "New Zealand Sign Language",
  'nzu': "Teke-Nzikou",
  'nzy': "Nzakambay",
  'nzz': "Nanga Dama Dogon",
  'oaa': "Orok",
  'oac': "Oroch",
  'oar': "Ancient Aramaic (up to 700 BCE)",
  'oav': "Old Avar",
  'obi': "Obispeño",
  'obk': "Southern Bontok",
  'obl': "Oblo",
  'obm': "Moabite",
  'obo': "Obo Manobo",
  'obr': "Old Burmese",
  'obt': "Old Breton",
  'obu': "Obulom",
  'oca': "Ocaina",
  'och': "Old Chinese",
  'ocm': "Old Cham",
  'oco': "Old Cornish",
  'ocu': "Atzingo Matlatzinca",
  'oda': "Odut",
  'odk': "Od",
  'odt': "Old Dutch",
  'odu': "Odual",
  'ofo': "Ofo",
  'ofs': "Old Frisian",
  'ofu': "Efutop",
  'ogb': "Ogbia",
  'ogc': "Ogbah",
  'oge': "Old Georgian",
  'ogg': "Ogbogolo",
  'ogo': "Khana",
  'ogu': "Ogbronuagum",
  'oht': "Old Hittite",
  'ohu': "Old Hungarian",
  'oia': "Oirata",
  'oie': "Okolie",
  'oin': "Inebu One",
  'ojb': "Northwestern Ojibwa",
  'ojc': "Central Ojibwa",
  'ojg': "Eastern Ojibwa",
  'ojp': "Old Japanese",
  'ojs': "Severn Ojibwa",
  'ojv': "Ontong Java",
  'ojw': "Western Ojibwa",
  'oka': "Okanagan",
  'okb': "Okobo",
  'okc': "Kobo",
  'okd': "Okodia",
  'oke': "Okpe (Southwestern Edo)",
  'okg': "Koko Babangk",
  'okh': "Koresh-e Rostam",
  'oki': "Okiek",
  'okj': "Oko-Juwoi",
  'okk': "Kwamtim One",
  'okl': "Old Kentish Sign Language",
  'okm': "Middle Korean (10th-16th cent.)",
  'okn': "Oki-No-Erabu",
  'oko': "Old Korean (3rd-9th cent.)",
  'okr': "Kirike",
  'oks': "Oko-Eni-Osayen",
  'oku': "Oku",
  'okv': "Orokaiva",
  'okx': "Okpe (Northwestern Edo)",
  'okz': "Old Khmer",
  'ola': "Walungge",
  'old': "Mochi",
  'ole': "Olekha",
  'olk': "Olkol",
  'olm': "Oloma",
  'olo': "Livvi",
  'olr': "Olrat",
  'olt': "Old Lithuanian",
  'olu': "Kuvale",
  'oma': "Omaha-Ponca",
  'omb': "East Ambae",
  'omc': "Mochica",
  'ome': "Omejes",
  'omg': "Omagua",
  'omi': "Omi",
  'omk': "Omok",
  'oml': "Ombo",
  'omn': "Minoan",
  'omo': "Utarmbung",
  'omp': "Old Manipuri",
  'omq': "Oto-Manguean languages",
  'omr': "Old Marathi",
  'omt': "Omotik",
  'omu': "Omurano",
  'omv': "Omotic languages",
  'omw': "South Tairora",
  'omx': "Old Mon",
  'omy': "Old Malay",
  'ona': "Ona",
  'onb': "Lingao",
  'one': "Oneida",
  'ong': "Olo",
  'oni': "Onin",
  'onj': "Onjob",
  'onk': "Kabore One",
  'onn': "Onobasulu",
  'ono': "Onondaga",
  'onp': "Sartang",
  'onr': "Northern One",
  'ons': "Ono",
  'ont': "Ontenu",
  'onu': "Unua",
  'onw': "Old Nubian",
  'onx': "Onin Based Pidgin",
  'ood': "Tohono O'odham",
  'oog': "Ong",
  'oon': "Önge",
  'oor': "Oorlams",
  'oos': "Old Ossetic",
  'opa': "Okpamheri",
  'opk': "Kopkaka",
  'opm': "Oksapmin",
  'opo': "Opao",
  'opt': "Opata",
  'opy': "Ofayé",
  'ora': "Oroha",
  'orc': "Orma",
  'ore': "Orejón",
  'org': "Oring",
  'orh': "Oroqen",
  'orn': "Orang Kanaq",
  'oro': "Orokolo",
  'orr': "Oruma",
  'ors': "Orang Seletar",
  'ort': "Adivasi Oriya",
  'oru': "Ormuri",
  'orv': "Old Russian",
  'orw': "Oro Win",
  'orx': "Oro",
  'ory': "Oriya (individual language)",
  'orz': "Ormu",
  'osa': "Osage",
  'osc': "Oscan",
  'osi': "Osing",
  'osn': "Old Sundanese",
  'oso': "Ososo",
  'osp': "Old Spanish",
  'ost': "Osatu",
  'osu': "Southern One",
  'osx': "Old Saxon",
  'ota': "Ottoman Turkish (1500-1928)",
  'otb': "Old Tibetan",
  'otd': "Ot Danum",
  'ote': "Mezquital Otomi",
  'oti': "Oti",
  'otk': "Old Turkish",
  'otl': "Tilapa Otomi",
  'otm': "Eastern Highland Otomi",
  'otn': "Tenango Otomi",
  'oto': "Otomian languages",
  'otq': "Querétaro Otomi",
  'otr': "Otoro",
  'ots': "Estado de México Otomi",
  'ott': "Temoaya Otomi",
  'otu': "Otuke",
  'otw': "Ottawa",
  'otx': "Texcatepec Otomi",
  'oty': "Old Tamil",
  'otz': "Ixtenco Otomi",
  'oua': "Tagargrent",
  'oub': "Glio-Oubi",
  'oue': "Oune",
  'oui': "Old Uighur",
  'oum': "Ouma",
  'oun': "ǃOǃung",
  'ovd': "Övdalian",
  'owi': "Owiniga",
  'owl': "Old Welsh",
  'oyb': "Oy",
  'oyd': "Oyda",
  'oym': "Wayampi",
  'oyy': "Oya'oya",
  'ozm': "Koonzime",
  'paa': "Papuan languages",
  'pab': "Parecís",
  'pac': "Pacoh",
  'pad': "Paumarí",
  'pae': "Pagibete",
  'paf': "Paranawát",
  'pag': "Pangasinan",
  'pah': "Tenharim",
  'pai': "Pe",
  'pak': "Parakanã",
  'pal': "Pahlavi",
  'pam': "Kapampangan",
  'pao': "Northern Paiute",
  'pap': "Papiamento",
  'paq': "Parya",
  'par': "Timbisha",
  'pas': "Papasena",
  'pat': "Papitalai",
  'pau': "Palauan",
  'pav': "Pakaásnovos",
  'paw': "Pawnee",
  'pax': "Pankararé",
  'pay': "Pech",
  'paz': "Pankararú",
  'pbb': "Páez",
  'pbc': "Patamona",
  'pbe': "Mezontla Popoloca",
  'pbf': "Coyotepec Popoloca",
  'pbg': "Paraujano",
  'pbh': "E'ñapa Woromaipu",
  'pbi': "Parkwa",
  'pbl': "Mak (Nigeria)",
  'pbm': "Puebla Mazatec",
  'pbn': "Kpasam",
  'pbo': "Papel",
  'pbp': "Badyara",
  'pbr': "Pangwa",
  'pbs': "Central Pame",
  'pbt': "Southern Pashto",
  'pbu': "Northern Pashto",
  'pbv': "Pnar",
  'pby': "Pyu (Papua New Guinea)",
  'pbz': "Palu",
  'pca': "Santa Inés Ahuatempan Popoloca",
  'pcb': "Pear",
  'pcc': "Bouyei",
  'pcd': "Picard",
  'pce': "Ruching Palaung",
  'pcf': "Paliyan",
  'pcg': "Paniya",
  'pch': "Pardhan",
  'pci': "Duruwa",
  'pcj': "Parenga",
  'pck': "Paite Chin",
  'pcl': "Pardhi",
  'pcm': "Nigerian Pidgin",
  'pcn': "Piti",
  'pcp': "Pacahuara",
  'pcr': "Panang",
  'pcw': "Pyapun",
  'pda': "Anam",
  'pdc': "Pennsylvania German",
  'pdi': "Pa Di",
  'pdn': "Fedan",
  'pdo': "Padoe",
  'pdt': "Plautdietsch",
  'pdu': "Kayan",
  'pea': "Peranakan Indonesian",
  'peb': "Eastern Pomo",
  'ped': "Mala (Papua New Guinea)",
  'pee': "Taje",
  'pef': "Northeastern Pomo",
  'peg': "Pengo",
  'peh': "Bonan",
  'pei': "Chichimeca-Jonaz",
  'pej': "Northern Pomo",
  'pek': "Penchal",
  'pel': "Pekal",
  'pem': "Phende",
  'peo': "Old Persian (ca. 600-400 B.C.)",
  'pep': "Kunja",
  'peq': "Southern Pomo",
  'pes': "Iranian Persian",
  'pev': "Pémono",
  'pex': "Petats",
  'pey': "Petjo",
  'pez': "Eastern Penan",
  'pfa': "Pááfang",
  'pfe': "Pere",
  'pfl': "Pfaelzisch",
  'pga': "Sudanese Creole Arabic",
  'pgd': "Gāndhārī",
  'pgg': "Pangwali",
  'pgi': "Pagi",
  'pgk': "Rerep",
  'pgl': "Primitive Irish",
  'pgn': "Paelignian",
  'pgs': "Pangseng",
  'pgu': "Pagu",
  'pgy': "Pongyong",
  'pgz': "Papua New Guinean Sign Language",
  'pha': "Pa-Hng",
  'phd': "Phudagi",
  'phg': "Phuong",
  'phh': "Phukha",
  'phi': "Philippine languages",
  'phj': "Pahari",
  'phk': "Phake",
  'phl': "Palula",
  'phm': "Phimbi",
  'phn': "Phoenician",
  'pho': "Phunoi",
  'phq': "Phana'",
  'phr': "Pahari-Potwari",
  'pht': "Phu Thai",
  'phu': "Phuan",
  'phv': "Pahlavani",
  'phw': "Phangduwali",
  'pia': "Pima Bajo",
  'pib': "Yine",
  'pic': "Pinji",
  'pid': "Piaroa",
  'pie': "Piro",
  'pif': "Pingelapese",
  'pig': "Pisabo",
  'pih': "Pitcairn-Norfolk",
  'pii': "Pini",
  'pij': "Pijao",
  'pil': "Yom",
  'pim': "Powhatan",
  'pin': "Piame",
  'pio': "Piapoco",
  'pip': "Pero",
  'pir': "Piratapuyo",
  'pis': "Pijin",
  'pit': "Pitta Pitta",
  'piu': "Pintupi-Luritja",
  'piv': "Vaeakau-Taumako",
  'piw': "Pimbwe",
  'pix': "Piu",
  'piy': "Piya-Kwonci",
  'piz': "Pije",
  'pjt': "Pitjantjatjara",
  'pka': "Ardhamāgadhī Prākrit",
  'pkb': "Kipfokomo",
  'pkc': "Paekche",
  'pkg': "Pak-Tong",
  'pkh': "Pankhu",
  'pkn': "Pakanha",
  'pko': "Pökoot",
  'pkp': "Pukapuka",
  'pkr': "Attapady Kurumba",
  'pks': "Pakistan Sign Language",
  'pkt': "Maleng",
  'pku': "Paku",
  'pla': "Miani",
  'plb': "Polonombauk",
  'plc': "Central Palawano",
  'pld': "Polari",
  'ple': "Palu'e",
  'plf': "Central Malayo-Polynesian languages",
  'plg': "Pilagá",
  'plh': "Paulohi",
  'plj': "Polci",
  'plk': "Kohistani Shina",
  'pll': "Shwe Palaung",
  'pln': "Palenquero",
  'plo': "Oluta Popoluca",
  'plp': "Palpa",
  'plq': "Palaic",
  'plr': "Palaka Senoufo",
  'pls': "San Marcos Tlalcoyalco Popoloca",
  'plt': "Plateau Malagasy",
  'plu': "Palikúr",
  'plv': "Southwest Palawano",
  'plw': "Brooke's Point Palawano",
  'ply': "Bolyu",
  'plz': "Paluan",
  'pma': "Paama",
  'pmb': "Pambia",
  'pmc': "Palumata",
  'pmd': "Pallanganmiddang",
  'pme': "Pwaamei",
  'pmf': "Pamona",
  'pmh': "Māhārāṣṭri Prākrit",
  'pmi': "Northern Pumi",
  'pmj': "Southern Pumi",
  'pmk': "Pamlico",
  'pml': "Lingua Franca",
  'pmm': "Pomo",
  'pmn': "Pam",
  'pmo': "Pom",
  'pmq': "Northern Pame",
  'pmr': "Paynamar",
  'pms': "Piemontese",
  'pmt': "Tuamotuan",
  'pmu': "Mirpur Panjabi",
  'pmw': "Plains Miwok",
  'pmx': "Poumei Naga",
  'pmy': "Papuan Malay",
  'pmz': "Southern Pame",
  'pna': "Punan Bah-Biau",
  'pnb': "Western Panjabi",
  'pnc': "Pannei",
  'pnd': "Mpinda",
  'pne': "Western Penan",
  'png': "Pongu",
  'pnh': "Penrhyn",
  'pni': "Aoheng",
  'pnj': "Pinjarup",
  'pnk': "Paunaka",
  'pnl': "Paleni",
  'pnm': "Punan Batu 1",
  'pnn': "Pinai-Hagahai",
  'pno': "Panobo",
  'pnp': "Pancana",
  'pnq': "Pana (Burkina Faso)",
  'pnr': "Panim",
  'pns': "Ponosakan",
  'pnt': "Pontic",
  'pnu': "Jiongnai Bunu",
  'pnv': "Pinigura",
  'pnw': "Panytyima",
  'pnx': "Phong-Kniang",
  'pny': "Pinyin",
  'pnz': "Pana (Central African Republic)",
  'poc': "Poqomam",
  'pod': "Ponares",
  'poe': "San Juan Atzingo Popoloca",
  'pof': "Poke",
  'pog': "Potiguára",
  'poh': "Poqomchi'",
  'poi': "Highland Popoluca",
  'pok': "Pokangá",
  'pom': "Southeastern Pomo",
  'pon': "Pohnpeian",
  'poo': "Central Pomo",
  'pop': "Pwapwâ",
  'poq': "Texistepec Popoluca",
  'pos': "Sayula Popoluca",
  'pot': "Potawatomi",
  'pov': "Upper Guinea Crioulo",
  'pow': "San Felipe Otlaltepec Popoloca",
  'pox': "Polabian",
  'poy': "Pogolo",
  'poz': "Malayo-Polynesian languages",
  'ppa': "Pao",
  'ppe': "Papi",
  'ppi': "Paipai",
  'ppk': "Uma",
  'ppl': "Nicarao",
  'ppm': "Papuma",
  'ppn': "Papapana",
  'ppo': "Folopa",
  'ppp': "Pelende",
  'ppq': "Pei",
  'ppr': "Piru",
  'pps': "San Luís Temalacayuca Popoloca",
  'ppt': "Pare",
  'ppu': "Papora",
  'pqa': "Pa'a",
  'pqe': "Eastern Malayo-Polynesian languages",
  'pqm': "Malecite-Passamaquoddy",
  'pqw': "Western Malayo-Polynesian languages",
  'pra': "Prakrit languages",
  'prb': "Lua'",
  'prc': "Parachi",
  'prd': "Parsi-Dari",
  'pre': "Principense",
  'prf': "Paranan",
  'prg': "Prussian",
  'prh': "Porohanon",
  'pri': "Paicî",
  'prk': "Parauk",
  'prl': "Peruvian Sign Language",
  'prm': "Kibiri",
  'prn': "Prasuni",
  'pro': "Old Occitan (to 1500)",
  'prp': "Parsi",
  'prq': "Ashéninka Perené",
  'prr': "Puri",
  'prs': "Afghan Persian",
  'prt': "Phai",
  'pru': "Puragi",
  'prw': "Parawen",
  'prx': "Purik",
  'pry': "Pray 3",
  'prz': "Providencia Sign Language",
  'psa': "Asue Awyu",
  'psc': "Persian Sign Language",
  'psd': "Plains Indian Sign Language",
  'pse': "Central Malay",
  'psg': "Penang Sign Language",
  'psh': "Southwest Pashayi",
  'psi': "Southeast Pashayi",
  'psl': "Puerto Rican Sign Language",
  'psm': "Pauserna",
  'psn': "Panasuan",
  'pso': "Polish Sign Language",
  'psp': "Philippine Sign Language",
  'psq': "Pasi",
  'psr': "Portuguese Sign Language",
  'pss': "Kaulong",
  'pst': "Central Pashto",
  'psu': "Sauraseni Prākrit",
  'psw': "Port Sandwich",
  'psy': "Piscataway",
  'pta': "Pai Tavytera",
  'pth': "Pataxó Hã-Ha-Hãe",
  'pti': "Wangkatha",
  'ptn': "Patani",
  'pto': "Zo'é",
  'ptp': "Patep",
  'ptq': "Pattapu",
  'ptr': "Piamatsina",
  'ptt': "Enrekang",
  'ptu': "Bambam",
  'ptv': "Port Vato",
  'ptw': "Pentlatch",
  'pty': "Pathiya",
  'pua': "Western Highland Purepecha",
  'pub': "Purum",
  'puc': "Punan Merap",
  'pud': "Punan Aput",
  'pue': "Puelche",
  'puf': "Punan Merah",
  'pug': "Phuie",
  'pui': "Puinave",
  'puj': "Punan Tubu",
  'puk': "Pu Ko",
  'pum': "Puma",
  'puo': "Puoc",
  'pup': "Pulabu",
  'puq': "Puquina",
  'pur': "Puruborá",
  'put': "Putoh",
  'puu': "Punu",
  'puw': "Puluwatese",
  'pux': "Puare",
  'puy': "Purisimeño",
  'puz': "Purum Naga",
  'pwa': "Pawaia",
  'pwb': "Panawa",
  'pwg': "Gapapaiwa",
  'pwi': "Patwin",
  'pwm': "Molbog",
  'pwn': "Paiwan",
  'pwo': "Pwo Western Karen",
  'pwr': "Powari",
  'pww': "Pwo Northern Karen",
  'pxm': "Quetzaltepec Mixe",
  'pye': "Pye Krumen",
  'pym': "Fyam",
  'pyn': "Poyanáwa",
  'pys': "Lengua de Señas del Paraguay",
  'pyu': "Puyuma",
  'pyx': "Pyu (Myanmar)",
  'pyy': "Pyen",
  'pze': "Pesse",
  'pzh': "Pazeh",
  'pzn': "Para Naga",
  'qaa..qtz': "Private use",
  'qua': "Quapaw",
  'qub': "Huallaga Huánuco Quechua",
  'quc': "Quiché",
  'qud': "Calderón Highland Quichua",
  'quf': "Lambayeque Quechua",
  'qug': "Chimborazo Highland Quichua",
  'quh': "South Bolivian Quechua",
  'qui': "Quileute",
  'quk': "Chachapoyas Quechua",
  'qul': "North Bolivian Quechua",
  'qum': "Sipacapense",
  'qun': "Quinault",
  'qup': "Southern Pastaza Quechua",
  'quq': "Quinqui",
  'qur': "Yanahuanca Pasco Quechua",
  'qus': "Santiago del Estero Quichua",
  'quv': "Sacapulteco",
  'quw': "Tena Lowland Quichua",
  'qux': "Yauyos Quechua",
  'quy': "Ayacucho Quechua",
  'quz': "Cusco Quechua",
  'qva': "Ambo-Pasco Quechua",
  'qvc': "Cajamarca Quechua",
  'qve': "Eastern Apurímac Quechua",
  'qvh': "Huamalíes-Dos de Mayo Huánuco Quechua",
  'qvi': "Imbabura Highland Quichua",
  'qvj': "Loja Highland Quichua",
  'qvl': "Cajatambo North Lima Quechua",
  'qvm': "Margos-Yarowilca-Lauricocha Quechua",
  'qvn': "North Junín Quechua",
  'qvo': "Napo Lowland Quechua",
  'qvp': "Pacaraos Quechua",
  'qvs': "San Martín Quechua",
  'qvw': "Huaylla Wanca Quechua",
  'qvy': "Queyu",
  'qvz': "Northern Pastaza Quichua",
  'qwa': "Corongo Ancash Quechua",
  'qwc': "Classical Quechua",
  'qwe': "Quechuan (family)",
  'qwh': "Huaylas Ancash Quechua",
  'qwm': "Kuman (Russia)",
  'qws': "Sihuas Ancash Quechua",
  'qwt': "Kwalhioqua-Tlatskanai",
  'qxa': "Chiquián Ancash Quechua",
  'qxc': "Chincha Quechua",
  'qxh': "Panao Huánuco Quechua",
  'qxl': "Salasaca Highland Quichua",
  'qxn': "Northern Conchucos Ancash Quechua",
  'qxo': "Southern Conchucos Ancash Quechua",
  'qxp': "Puno Quechua",
  'qxq': "Qashqa'i",
  'qxr': "Cañar Highland Quichua",
  'qxs': "Southern Qiang",
  'qxt': "Santa Ana de Tusi Pasco Quechua",
  'qxu': "Arequipa-La Unión Quechua",
  'qxw': "Jauja Wanca Quechua",
  'qya': "Quenya",
  'qyp': "Quiripi",
  'raa': "Dungmali",
  'rab': "Camling",
  'rac': "Rasawa",
  'rad': "Rade",
  'raf': "Western Meohang",
  'rag': "Lulogooli",
  'rah': "Rabha",
  'rai': "Ramoaaina",
  'raj': "Rajasthani",
  'rak': "Tulu-Bohuai",
  'ral': "Ralte",
  'ram': "Canela",
  'ran': "Riantana",
  'rao': "Rao",
  'rap': "Rapanui",
  'raq': "Saam",
  'rar': "Cook Islands Maori",
  'ras': "Tegali",
  'rat': "Razajerdi",
  'rau': "Raute",
  'rav': "Sampang",
  'raw': "Rawang",
  'rax': "Rang",
  'ray': "Rapa",
  'raz': "Rahambuu",
  'rbb': "Rumai Palaung",
  'rbk': "Northern Bontok",
  'rbl': "Miraya Bikol",
  'rbp': "Barababaraba",
  'rcf': "Réunion Creole French",
  'rdb': "Rudbari",
  'rea': "Rerau",
  'reb': "Rembong",
  'ree': "Rejang Kayan",
  'reg': "Kara (Tanzania)",
  'rei': "Reli",
  'rej': "Rejang",
  'rel': "Rendille",
  'rem': "Remo",
  'ren': "Rengao",
  'rer': "Rer Bare",
  'res': "Reshe",
  'ret': "Retta",
  'rey': "Reyesano",
  'rga': "Roria",
  'rge': "Romano-Greek",
  'rgk': "Rangkas",
  'rgn': "Romagnol",
  'rgr': "Resígaro",
  'rgs': "Southern Roglai",
  'rgu': "Ringgou",
  'rhg': "Rohingya",
  'rhp': "Yahang",
  'ria': "Riang (India)",
  'rib': "Bribri Sign Language",
  'rie': "Rien",
  'rif': "Tarifit",
  'ril': "Riang (Myanmar)",
  'rim': "Nyaturu",
  'rin': "Nungu",
  'rir': "Ribun",
  'rit': "Ritharrngu",
  'riu': "Riung",
  'rjg': "Rajong",
  'rji': "Raji",
  'rjs': "Rajbanshi",
  'rka': "Kraol",
  'rkb': "Rikbaktsa",
  'rkh': "Rakahanga-Manihiki",
  'rki': "Rakhine",
  'rkm': "Marka",
  'rkt': "Kamta",
  'rkw': "Arakwal",
  'rma': "Rama",
  'rmb': "Rembarrnga",
  'rmc': "Carpathian Romani",
  'rmd': "Traveller Danish",
  'rme': "Angloromani",
  'rmf': "Kalo Finnish Romani",
  'rmg': "Traveller Norwegian",
  'rmh': "Murkim",
  'rmi': "Lomavren",
  'rmk': "Romkun",
  'rml': "Baltic Romani",
  'rmm': "Roma",
  'rmn': "Balkan Romani",
  'rmo': "Sinte Romani",
  'rmp': "Rempi",
  'rmq': "Caló",
  'rmr': "Caló",
  'rms': "Romanian Sign Language",
  'rmt': "Domari",
  'rmu': "Tavringer Romani",
  'rmv': "Romanova",
  'rmw': "Welsh Romani",
  'rmx': "Romam",
  'rmy': "Vlax Romani",
  'rmz': "Marma",
  'rna': "Runa",
  'rnb': "Brunca Sign Language",
  'rnd': "Ruund",
  'rng': "Ronga",
  'rnl': "Ranglong",
  'rnn': "Roon",
  'rnp': "Rongpo",
  'rnr': "Nari Nari",
  'rnw': "Rungwa",
  'roa': "Romance languages",
  'rob': "Tae'",
  'roc': "Cacgia Roglai",
  'rod': "Rogo",
  'roe': "Ronji",
  'rof': "Rombo",
  'rog': "Northern Roglai",
  'rol': "Romblomanon",
  'rom': "Romany",
  'roo': "Rotokas",
  'rop': "Kriol",
  'ror': "Rongga",
  'rou': "Runga",
  'row': "Dela-Oenale",
  'rpn': "Repanbitip",
  'rpt': "Rapting",
  'rri': "Ririo",
  'rro': "Waima",
  'rrt': "Arritinngithigh",
  'rsb': "Romano-Serbian",
  'rsi': "Rennellese Sign Language",
  'rsk': "Rusnak",
  'rsl': "Russian Sign Language",
  'rsm': "Miriwoong Sign Language",
  'rsn': "Rwandan Sign Language",
  'rsw': "Rishiwa",
  'rtc': "Rungtu Chin",
  'rth': "Ratahan",
  'rtm': "Rotuman",
  'rts': "Yurats",
  'rtw': "Rathawi",
  'rub': "Gungu",
  'ruc': "Ruuli",
  'rue': "Rusyn",
  'ruf': "Luguru",
  'rug': "Roviana",
  'ruh': "Ruga",
  'rui': "Rufiji",
  'ruk': "Che",
  'ruo': "Istro Romanian",
  'rup': "Arumanian",
  'ruq': "Megleno Romanian",
  'rut': "Rutul",
  'ruu': "Lanas Lobu",
  'ruy': "Mala (Nigeria)",
  'ruz': "Ruma",
  'rwa': "Rawo",
  'rwk': "Rwa",
  'rwl': "Ruwila",
  'rwm': "Amba (Uganda)",
  'rwo': "Rawa",
  'rwr': "Marwari (India)",
  'rxd': "Ngardi",
  'rxw': "Garuwali",
  'ryn': "Northern Amami-Oshima",
  'rys': "Yaeyama",
  'ryu': "Central Okinawan",
  'rzh': "Rāziḥī",
  'saa': "Saba",
  'sab': "Buglere",
  'sac': "Meskwaki",
  'sad': "Sandawe",
  'sae': "Sabanê",
  'saf': "Safaliba",
  'sah': "Yakut",
  'sai': "South American Indian languages",
  'saj': "Sahu",
  'sak': "Sake",
  'sal': "Salishan languages",
  'sam': "Samaritan Aramaic",
  'sao': "Sause",
  'sap': "Sanapaná",
  'saq': "Samburu",
  'sar': "Saraveca",
  'sas': "Sasak",
  'sat': "Santali",
  'sau': "Saleman",
  'sav': "Saafi-Saafi",
  'saw': "Sawi",
  'sax': "Sa",
  'say': "Saya",
  'saz': "Saurashtra",
  'sba': "Ngambay",
  'sbb': "Simbo",
  'sbc': "Kele (Papua New Guinea)",
  'sbd': "Southern Samo",
  'sbe': "Saliba",
  'sbf': "Shabo",
  'sbg': "Seget",
  'sbh': "Sori-Harengan",
  'sbi': "Seti",
  'sbj': "Surbakhal",
  'sbk': "Safwa",
  'sbl': "Botolan Sambal",
  'sbm': "Sagala",
  'sbn': "Sindhi Bhil",
  'sbo': "Sabüm",
  'sbp': "Sangu (Tanzania)",
  'sbq': "Sileibi",
  'sbr': "Sembakung Murut",
  'sbs': "Subiya",
  'sbt': "Kimki",
  'sbu': "Stod Bhoti",
  'sbv': "Sabine",
  'sbw': "Simba",
  'sbx': "Seberuang",
  'sby': "Soli",
  'sbz': "Sara Kaba",
  'sca': "Sansu",
  'scb': "Chut",
  'sce': "Dongxiang",
  'scf': "San Miguel Creole French",
  'scg': "Sanggau",
  'sch': "Sakachep",
  'sci': "Sri Lankan Creole Malay",
  'sck': "Sadri",
  'scl': "Shina",
  'scn': "Sicilian",
  'sco': "Scots",
  'scp': "Helambu Sherpa",
  'scq': "Sa'och",
  'scs': "North Slavey",
  'sct': "Southern Katang",
  'scu': "Shumcho",
  'scv': "Sheni",
  'scw': "Sha",
  'scx': "Sicel",
  'sda': "Toraja-Sa'dan",
  'sdb': "Shabak",
  'sdc': "Sassarese Sardinian",
  'sde': "Surubu",
  'sdf': "Sarli",
  'sdg': "Savi",
  'sdh': "Southern Kurdish",
  'sdj': "Suundi",
  'sdk': "Sos Kundi",
  'sdl': "Saudi Arabian Sign Language",
  'sdm': "Semandang",
  'sdn': "Gallurese Sardinian",
  'sdo': "Bukar-Sadung Bidayuh",
  'sdp': "Sherdukpen",
  'sdq': "Semandang",
  'sdr': "Oraon Sadri",
  'sds': "Sened",
  'sdt': "Shuadit",
  'sdu': "Sarudu",
  'sdv': "Eastern Sudanic languages",
  'sdx': "Sibu Melanau",
  'sdz': "Sallands",
  'sea': "Semai",
  'seb': "Shempire Senoufo",
  'sec': "Sechelt",
  'sed': "Sedang",
  'see': "Seneca",
  'sef': "Cebaara Senoufo",
  'seg': "Segeju",
  'seh': "Sena",
  'sei': "Seri",
  'sej': "Sene",
  'sek': "Sekani",
  'sel': "Selkup",
  'sem': "Semitic languages",
  'sen': "Nanerigé Sénoufo",
  'seo': "Suarmin",
  'sep': "Sìcìté Sénoufo",
  'seq': "Senara Sénoufo",
  'ser': "Serrano",
  'ses': "Koyraboro Senni Songhai",
  'set': "Sentani",
  'seu': "Serui-Laut",
  'sev': "Nyarafolo Senoufo",
  'sew': "Sewa Bay",
  'sey': "Secoya",
  'sez': "Senthang Chin",
  'sfb': "French Belgian Sign Language",
  'sfe': "Eastern Subanen",
  'sfm': "Small Flowery Miao",
  'sfs': "South African Sign Language",
  'sfw': "Sehwi",
  'sga': "Old Irish (to 900)",
  'sgb': "Mag-antsi Ayta",
  'sgc': "Kipsigis",
  'sgd': "Surigaonon",
  'sge': "Segai",
  'sgg': "Swiss-German Sign Language",
  'sgh': "Shughni",
  'sgi': "Suga",
  'sgj': "Surgujia",
  'sgk': "Sangkong",
  'sgl': "Sanglechi-Ishkashimi",
  'sgm': "Singa",
  'sgn': "Sign languages",
  'sgo': "Songa",
  'sgp': "Singpho",
  'sgr': "Sangisari",
  'sgs': "Samogitian",
  'sgt': "Brokpake",
  'sgu': "Salas",
  'sgw': "Sebat Bet Gurage",
  'sgx': "Sierra Leone Sign Language",
  'sgy': "Sanglechi",
  'sgz': "Sursurunga",
  'sha': "Shall-Zwall",
  'shb': "Ninam",
  'shc': "Sonde",
  'shd': "Kundal Shahi",
  'she': "Sheko",
  'shg': "Shua",
  'shh': "Shoshoni",
  'shi': "Tachelhit",
  'shj': "Shatt",
  'shk': "Shilluk",
  'shl': "Shendu",
  'shm': "Shahrudi",
  'shn': "Shan",
  'sho': "Shanga",
  'shp': "Shipibo-Conibo",
  'shq': "Sala",
  'shr': "Shi",
  'shs': "Shuswap",
  'sht': "Shasta",
  'shu': "Chadian Arabic",
  'shv': "Shehri",
  'shw': "Shwai",
  'shx': "She",
  'shy': "Tachawit",
  'shz': "Syenara Senoufo",
  'sia': "Akkala Sami",
  'sib': "Sebop",
  'sid': "Sidamo",
  'sie': "Simaa",
  'sif': "Siamou",
  'sig': "Paasaal",
  'sih': "Sîshëë",
  'sii': "Shom Peng",
  'sij': "Numbami",
  'sik': "Sikiana",
  'sil': "Tumulung Sisaala",
  'sim': "Mende (Papua New Guinea)",
  'sio': "Siouan languages",
  'sip': "Sikkimese",
  'siq': "Sonia",
  'sir': "Siri",
  'sis': "Siuslaw",
  'sit': "Sino-Tibetan languages",
  'siu': "Sinagen",
  'siv': "Sumariup",
  'siw': "Siwai",
  'six': "Sumau",
  'siy': "Sivandi",
  'siz': "Siwi",
  'sja': "Epena",
  'sjb': "Sajau Basap",
  'sjd': "Kildin Sami",
  'sje': "Pite Sami",
  'sjg': "Assangori",
  'sjk': "Kemi Sami",
  'sjl': "Miji",
  'sjm': "Mapun",
  'sjn': "Sindarin",
  'sjo': "Xibe",
  'sjp': "Surjapuri",
  'sjr': "Siar-Lak",
  'sjs': "Senhaja De Srair",
  'sjt': "Ter Sami",
  'sju': "Ume Sami",
  'sjw': "Shawnee",
  'ska': "Skagit",
  'skb': "Saek",
  'skc': "Ma Manda",
  'skd': "Southern Sierra Miwok",
  'ske': "Seke (Vanuatu)",
  'skf': "Sakirabiá",
  'skg': "Sakalava Malagasy",
  'skh': "Sikule",
  'ski': "Sika",
  'skj': "Seke (Nepal)",
  'skk': "Sok",
  'skm': "Kutong",
  'skn': "Kolibugan Subanon",
  'sko': "Seko Tengah",
  'skp': "Sekapan",
  'skq': "Sininkere",
  'skr': "Seraiki",
  'sks': "Maia",
  'skt': "Sakata",
  'sku': "Sakao",
  'skv': "Skou",
  'skw': "Skepi Creole Dutch",
  'skx': "Seko Padang",
  'sky': "Sikaiana",
  'skz': "Sekar",
  'sla': "Slavic languages",
  'slc': "Sáliba",
  'sld': "Sissala",
  'sle': "Sholaga",
  'slf': "Swiss-Italian Sign Language",
  'slg': "Selungai Murut",
  'slh': "Southern Puget Sound Salish",
  'sli': "Lower Silesian",
  'slj': "Salumá",
  'sll': "Salt-Yui",
  'slm': "Pangutaran Sama",
  'sln': "Salinan",
  'slp': "Lamaholot",
  'slq': "Salchuq",
  'slr': "Salar",
  'sls': "Singapore Sign Language",
  'slt': "Sila",
  'slu': "Selaru",
  'slw': "Sialum",
  'slx': "Salampasu",
  'sly': "Selayar",
  'slz': "Ma'ya",
  'sma': "Southern Sami",
  'smb': "Simbari",
  'smc': "Som",
  'smd': "Sama",
  'smf': "Auwe",
  'smg': "Simbali",
  'smh': "Samei",
  'smi': "Sami languages",
  'smj': "Lule Sami",
  'smk': "Bolinao",
  'sml': "Central Sama",
  'smm': "Musasa",
  'smn': "Inari Sami",
  'smp': "Samaritan",
  'smq': "Samo",
  'smr': "Simeulue",
  'sms': "Skolt Sami",
  'smt': "Simte",
  'smu': "Somray",
  'smv': "Samvedi",
  'smw': "Sumbawa",
  'smx': "Samba",
  'smy': "Semnani",
  'smz': "Simeku",
  'snb': "Sebuyau",
  'snc': "Sinaugoro",
  'sne': "Bau Bidayuh",
  'snf': "Noon",
  'sng': "Sanga (Democratic Republic of Congo)",
  'snh': "Shinabo",
  'sni': "Sensi",
  'snj': "Riverain Sango",
  'snk': "Soninke",
  'snl': "Sangil",
  'snm': "Southern Ma'di",
  'snn': "Siona",
  'sno': "Snohomish",
  'snp': "Siane",
  'snq': "Sangu (Gabon)",
  'snr': "Sihan",
  'sns': "Nahavaq",
  'snu': "Viid",
  'snv': "Sa'ban",
  'snw': "Selee",
  'snx': "Sam",
  'sny': "Saniyo-Hiyewe",
  'snz': "Kou",
  'soa': "Thai Song",
  'sob': "Sobei",
  'soc': "So (Democratic Republic of Congo)",
  'sod': "Songoora",
  'soe': "Songomeno",
  'sog': "Sogdian",
  'soh': "Aka",
  'soi': "Sonha",
  'soj': "Soi",
  'sok': "Sokoro",
  'sol': "Solos",
  'son': "Songhai languages",
  'soo': "Songo",
  'sop': "Songe",
  'soq': "Kanasi",
  'sor': "Somrai",
  'sos': "Seeku",
  'sou': "Southern Thai",
  'sov': "Sonsorol",
  'sow': "Sowanda",
  'sox': "Swo",
  'soy': "Miyobe",
  'soz': "Temi",
  'spb': "Sepa (Indonesia)",
  'spc': "Sapé",
  'spd': "Saep",
  'spe': "Sepa (Papua New Guinea)",
  'spg': "Sian",
  'spi': "Saponi",
  'spk': "Sengo",
  'spl': "Selepet",
  'spm': "Akukem",
  'spn': "Sanapaná",
  'spo': "Spokane",
  'spp': "Supyire Senoufo",
  'spq': "Loreto-Ucayali Spanish",
  'spr': "Saparua",
  'sps': "Saposa",
  'spt': "Spiti Bhoti",
  'spu': "Sapuan",
  'spv': "Kosli",
  'spx': "South Picene",
  'spy': "Sabaot",
  'sqa': "Shama-Sambuga",
  'sqh': "Shau",
  'sqj': "Albanian languages",
  'sqk': "Albanian Sign Language",
  'sqm': "Suma",
  'sqn': "Susquehannock",
  'sqo': "Sorkhei",
  'sqq': "Sou",
  'sqr': "Siculo Arabic",
  'sqs': "Sri Lankan Sign Language",
  'sqt': "Soqotri",
  'squ': "Squamish",
  'sqx': "Kufr Qassem Sign Language (KQSL)",
  'sra': "Saruga",
  'srb': "Sora",
  'src': "Logudorese Sardinian",
  'sre': "Sara",
  'srf': "Nafi",
  'srg': "Sulod",
  'srh': "Sarikoli",
  'sri': "Siriano",
  'srk': "Serudung Murut",
  'srl': "Isirawa",
  'srm': "Saramaccan",
  'srn': "Sranan Tongo",
  'sro': "Campidanese Sardinian",
  'srq': "Sirionó",
  'srr': "Serer",
  'srs': "Sarsi",
  'srt': "Sauri",
  'sru': "Suruí",
  'srv': "Southern Sorsoganon",
  'srw': "Serua",
  'srx': "Sirmauri",
  'sry': "Sera",
  'srz': "Shahmirzadi",
  'ssa': "Nilo-Saharan languages",
  'ssb': "Southern Sama",
  'ssc': "Suba-Simbiti",
  'ssd': "Siroi",
  'sse': "Bangingih Sama",
  'ssf': "Thao",
  'ssg': "Seimat",
  'ssh': "Shihhi Arabic",
  'ssi': "Sansi",
  'ssj': "Sausi",
  'ssk': "Sunam",
  'ssl': "Western Sisaala",
  'ssm': "Semnam",
  'ssn': "Waata",
  'sso': "Sissano",
  'ssp': "Spanish Sign Language",
  'ssq': "So'a",
  'ssr': "Swiss-French Sign Language",
  'sss': "Sô",
  'sst': "Sinasina",
  'ssu': "Susuami",
  'ssv': "Shark Bay",
  'ssx': "Samberigi",
  'ssy': "Saho",
  'ssz': "Sengseng",
  'sta': "Settla",
  'stb': "Northern Subanen",
  'std': "Sentinel",
  'ste': "Liana-Seti",
  'stf': "Seta",
  'stg': "Trieng",
  'sth': "Shelta",
  'sti': "Bulo Stieng",
  'stj': "Matya Samo",
  'stk': "Arammba",
  'stl': "Stellingwerfs",
  'stm': "Setaman",
  'stn': "Owa",
  'sto': "Stoney",
  'stp': "Southeastern Tepehuan",
  'stq': "Saterfriesisch",
  'str': "Straits Salish",
  'sts': "Shumashti",
  'stt': "Budeh Stieng",
  'stu': "Samtao",
  'stv': "Silt'e",
  'stw': "Satawalese",
  'sty': "Siberian Tatar",
  'sua': "Sulka",
  'sub': "Suku",
  'suc': "Western Subanon",
  'sue': "Suena",
  'sug': "Suganga",
  'sui': "Suki",
  'suj': "Shubi",
  'suk': "Sukuma",
  'sul': "Surigaonon",
  'sum': "Sumo-Mayangna",
  'suo': "Bouni",
  'suq': "Suri",
  'sur': "Mwaghavul",
  'sus': "Susu",
  'sut': "Subtiaba",
  'suv': "Puroik",
  'suw': "Sumbwa",
  'sux': "Sumerian",
  'suy': "Suyá",
  'suz': "Sunwar",
  'sva': "Svan",
  'svb': "Ulau-Suain",
  'svc': "Vincentian Creole English",
  'sve': "Serili",
  'svk': "Slovakian Sign Language",
  'svm': "Slavomolisano",
  'svr': "Savara",
  'svs': "Savosavo",
  'svx': "Skalvian",
  'swb': "Maore Comorian",
  'swc': "Congo Swahili",
  'swf': "Sere",
  'swg': "Swabian",
  'swh': "Kiswahili",
  'swi': "Sui",
  'swj': "Sira",
  'swk': "Malawi Sena",
  'swl': "Swedish Sign Language",
  'swm': "Samosa",
  'swn': "Sawknah",
  'swo': "Shanenawa",
  'swp': "Suau",
  'swq': "Sharwa",
  'swr': "Saweru",
  'sws': "Seluwasan",
  'swt': "Sawila",
  'swu': "Suwawa",
  'swv': "Shekhawati",
  'sww': "Sowa",
  'swx': "Suruahá",
  'swy': "Sarua",
  'sxb': "Suba",
  'sxc': "Sicanian",
  'sxe': "Sighu",
  'sxg': "Shixing",
  'sxk': "Southern Kalapuya",
  'sxl': "Selian",
  'sxm': "Samre",
  'sxn': "Sangir",
  'sxo': "Sorothaptic",
  'sxr': "Saaroa",
  'sxs': "Sasaru",
  'sxu': "Upper Saxon",
  'sxw': "Saxwe Gbe",
  'sya': "Siang",
  'syb': "Central Subanen",
  'syc': "Classical Syriac",
  'syd': "Samoyedic languages",
  'syi': "Seki",
  'syk': "Sukur",
  'syl': "Sylheti",
  'sym': "Maya Samo",
  'syn': "Senaya",
  'syo': "Suoy",
  'syr': "Syriac",
  'sys': "Sinyar",
  'syw': "Kagate",
  'syx': "Samay",
  'syy': "Al-Sayyid Bedouin Sign Language",
  'sza': "Semelai",
  'szb': "Ngalum",
  'szc': "Semaq Beri",
  'szd': "Seru",
  'sze': "Seze",
  'szg': "Sengele",
  'szl': "Silesian",
  'szn': "Sula",
  'szp': "Suabo",
  'szs': "Solomon Islands Sign Language",
  'szv': "Isu (Fako Division)",
  'szw': "Sawai",
  'szy': "Sakizaya",
  'taa': "Lower Tanana",
  'tab': "Tabassaran",
  'tac': "Lowland Tarahumara",
  'tad': "Tause",
  'tae': "Tariana",
  'taf': "Tapirapé",
  'tag': "Tagoi",
  'tai': "Tai languages",
  'taj': "Eastern Tamang",
  'tak': "Tala",
  'tal': "Tal",
  'tan': "Tangale",
  'tao': "Yami",
  'tap': "Taabwa",
  'taq': "Tamasheq",
  'tar': "Central Tarahumara",
  'tas': "Tay Boi",
  'tau': "Upper Tanana",
  'tav': "Tatuyo",
  'taw': "Tai",
  'tax': "Tamki",
  'tay': "Atayal",
  'taz': "Tocho",
  'tba': "Aikanã",
  'tbb': "Tapeba",
  'tbc': "Takia",
  'tbd': "Kaki Ae",
  'tbe': "Tanimbili",
  'tbf': "Mandara",
  'tbg': "North Tairora",
  'tbh': "Thurawal",
  'tbi': "Gaam",
  'tbj': "Tiang",
  'tbk': "Calamian Tagbanwa",
  'tbl': "Tboli",
  'tbm': "Tagbu",
  'tbn': "Barro Negro Tunebo",
  'tbo': "Tawala",
  'tbp': "Diebroud",
  'tbq': "Tibeto-Burman languages",
  'tbr': "Tumtum",
  'tbs': "Tanguat",
  'tbt': "Tembo (Kitembo)",
  'tbu': "Tubar",
  'tbv': "Tobo",
  'tbw': "Tagbanwa",
  'tbx': "Kapin",
  'tby': "Tabaru",
  'tbz': "Ditammari",
  'tca': "Ticuna",
  'tcb': "Tanacross",
  'tcc': "Datooga",
  'tcd': "Tafi",
  'tce': "Southern Tutchone",
  'tcf': "Malinaltepec Tlapanec",
  'tcg': "Tamagario",
  'tch': "Turks And Caicos Creole English",
  'tci': "Wára",
  'tck': "Tchitchege",
  'tcl': "Taman (Myanmar)",
  'tcm': "Tanahmerah",
  'tcn': "Tichurong",
  'tco': "Taungyo",
  'tcp': "Tawr Chin",
  'tcq': "Kaiy",
  'tcs': "Yumplatok",
  'tct': "T'en",
  'tcu': "Southeastern Tarahumara",
  'tcw': "Tecpatlán Totonac",
  'tcx': "Toda",
  'tcy': "Tulu",
  'tcz': "Thado Chin",
  'tda': "Tagdal",
  'tdb': "Panchpargania",
  'tdc': "Emberá-Tadó",
  'tdd': "Tai Nüa",
  'tde': "Tiranige Diga Dogon",
  'tdf': "Talieng",
  'tdg': "Western Tamang",
  'tdh': "Thulung",
  'tdi': "Tomadino",
  'tdj': "Tajio",
  'tdk': "Tambas",
  'tdl': "Sur",
  'tdm': "Taruma",
  'tdn': "Tondano",
  'tdo': "Teme",
  'tdq': "Tita",
  'tdr': "Todrah",
  'tds': "Doutai",
  'tdt': "Tetun Dili",
  'tdu': "Tempasuk Dusun",
  'tdv': "Toro",
  'tdx': "Tandroy-Mahafaly Malagasy",
  'tdy': "Tadyawan",
  'tea': "Temiar",
  'teb': "Tetete",
  'tec': "Terik",
  'ted': "Tepo Krumen",
  'tee': "Huehuetla Tepehua",
  'tef': "Teressa",
  'teg': "Teke-Tege",
  'teh': "Tehuelche",
  'tei': "Torricelli",
  'tek': "Ibali Teke",
  'tem': "Timne",
  'ten': "Tama (Colombia)",
  'teo': "Teso",
  'tep': "Tepecano",
  'teq': "Temein",
  'ter': "Tereno",
  'tes': "Tengger",
  'tet': "Tetum",
  'teu': "Soo",
  'tev': "Teor",
  'tew': "Tewa (USA)",
  'tex': "Tennet",
  'tey': "Tulishi",
  'tez': "Tetserret",
  'tfi': "Tofin Gbe",
  'tfn': "Tanaina",
  'tfo': "Tefaro",
  'tfr': "Teribe",
  'tft': "Ternate",
  'tga': "Sagalla",
  'tgb': "Tobilung",
  'tgc': "Tigak",
  'tgd': "Ciwogai",
  'tge': "Eastern Gorkha Tamang",
  'tgf': "Chalikha",
  'tgg': "Tangga",
  'tgh': "Tobagonian Creole English",
  'tgi': "Lawunuia",
  'tgj': "Tagin",
  'tgn': "Tandaganon",
  'tgo': "Sudest",
  'tgp': "Tangoa",
  'tgq': "Tring",
  'tgr': "Tareng",
  'tgs': "Nume",
  'tgt': "Central Tagbanwa",
  'tgu': "Tanggu",
  'tgv': "Tingui-Boto",
  'tgw': "Tagwana Senoufo",
  'tgx': "Tagish",
  'tgy': "Togoyo",
  'tgz': "Tagalaka",
  'thc': "Tai Hang Tong",
  'thd': "Thayore",
  'the': "Chitwania Tharu",
  'thf': "Thangmi",
  'thh': "Northern Tarahumara",
  'thi': "Tai Long",
  'thk': "Kitharaka",
  'thl': "Dangaura Tharu",
  'thm': "Aheu",
  'thn': "Thachanadan",
  'thp': "Thompson",
  'thq': "Kochila Tharu",
  'thr': "Rana Tharu",
  'ths': "Thakali",
  'tht': "Tahltan",
  'thu': "Thuri",
  'thv': "Tahaggart Tamahaq",
  'thw': "Thudam",
  'thx': "The",
  'thy': "Tha",
  'thz': "Tayart Tamajeq",
  'tia': "Tidikelt Tamazight",
  'tic': "Tira",
  'tid': "Tidong",
  'tie': "Tingal",
  'tif': "Tifal",
  'tig': "Tigre",
  'tih': "Timugon Murut",
  'tii': "Tiene",
  'tij': "Tilung",
  'tik': "Tikar",
  'til': "Tillamook",
  'tim': "Timbe",
  'tin': "Tindi",
  'tio': "Teop",
  'tip': "Trimuris",
  'tiq': "Tiéfo",
  'tis': "Masadiit Itneg",
  'tit': "Tinigua",
  'tiu': "Adasen",
  'tiv': "Tiv",
  'tiw': "Tiwi",
  'tix': "Southern Tiwa",
  'tiy': "Tiruray",
  'tiz': "Tai Hongjin",
  'tja': "Tajuasohn",
  'tjg': "Tunjung",
  'tji': "Northern Tujia",
  'tjj': "Tjungundji",
  'tjl': "Tai Laing",
  'tjm': "Timucua",
  'tjn': "Tonjon",
  'tjo': "Temacine Tamazight",
  'tjp': "Tjupany",
  'tjs': "Southern Tujia",
  'tju': "Tjurruru",
  'tjw': "Djabwurrung",
  'tka': "Truká",
  'tkb': "Buksa",
  'tkd': "Tukudede",
  'tke': "Takwane",
  'tkf': "Tukumanféd",
  'tkg': "Tesaka Malagasy",
  'tkk': "Takpa",
  'tkl': "Tokelau",
  'tkm': "Takelma",
  'tkn': "Toku-No-Shima",
  'tkp': "Tikopia",
  'tkq': "Tee",
  'tkr': "Tsakhur",
  'tks': "Takestani",
  'tkt': "Kathoriya Tharu",
  'tku': "Upper Necaxa Totonac",
  'tkv': "Mur Pano",
  'tkw': "Teanu",
  'tkx': "Tangko",
  'tkz': "Takua",
  'tla': "Southwestern Tepehuan",
  'tlb': "Tobelo",
  'tlc': "Yecuatla Totonac",
  'tld': "Talaud",
  'tlf': "Telefol",
  'tlg': "Tofanma",
  'tlh': "tlhIngan Hol",
  'tli': "Tlingit",
  'tlj': "Talinga-Bwisi",
  'tlk': "Taloki",
  'tll': "Tetela",
  'tlm': "Tolomako",
  'tln': "Talondo'",
  'tlo': "Talodi",
  'tlp': "Filomena Mata-Coahuitlán Totonac",
  'tlq': "Tai Loi",
  'tlr': "Talise",
  'tls': "Tambotalo",
  'tlt': "Teluti",
  'tlu': "Tulehu",
  'tlv': "Taliabu",
  'tlw': "South Wemale",
  'tlx': "Khehek",
  'tly': "Talysh",
  'tma': "Tama (Chad)",
  'tmb': "Avava",
  'tmc': "Tumak",
  'tmd': "Haruai",
  'tme': "Tremembé",
  'tmf': "Toba-Maskoy",
  'tmg': "Ternateño",
  'tmh': "Tamashek",
  'tmi': "Tutuba",
  'tmj': "Samarokena",
  'tmk': "Northwestern Tamang",
  'tml': "Tamnim Citak",
  'tmm': "Tai Thanh",
  'tmn': "Taman (Indonesia)",
  'tmo': "Temoq",
  'tmp': "Tai Mène",
  'tmq': "Tumleo",
  'tmr': "Jewish Babylonian Aramaic (ca. 200-1200 CE)",
  'tms': "Tima",
  'tmt': "Tasmate",
  'tmu': "Iau",
  'tmv': "Tembo (Motembo)",
  'tmw': "Temuan",
  'tmy': "Tami",
  'tmz': "Tamanaku",
  'tna': "Tacana",
  'tnb': "Western Tunebo",
  'tnc': "Tanimuca-Retuarã",
  'tnd': "Angosturas Tunebo",
  'tne': "Tinoc Kallahan",
  'tnf': "Tangshewi",
  'tng': "Tobanga",
  'tnh': "Maiani",
  'tni': "Tandia",
  'tnk': "Kwamera",
  'tnl': "Lenakel",
  'tnm': "Tabla",
  'tnn': "North Tanna",
  'tno': "Toromono",
  'tnp': "Whitesands",
  'tnq': "Taino",
  'tnr': "Ménik",
  'tns': "Tenis",
  'tnt': "Tontemboan",
  'tnu': "Tay Khang",
  'tnv': "Tangchangya",
  'tnw': "Tonsawang",
  'tnx': "Tanema",
  'tny': "Tongwe",
  'tnz': "Ten'edn",
  'tob': "Toba",
  'toc': "Coyutla Totonac",
  'tod': "Toma",
  'toe': "Tomedes",
  'tof': "Gizrra",
  'tog': "Tonga (Nyasa)",
  'toh': "Gitonga",
  'toi': "Tonga (Zambia)",
  'toj': "Tojolabal",
  'tok': "Toki Pona",
  'tol': "Tolowa",
  'tom': "Tombulu",
  'too': "Xicotepec De Juárez Totonac",
  'top': "Papantla Totonac",
  'toq': "Toposa",
  'tor': "Togbo-Vara Banda",
  'tos': "Highland Totonac",
  'tou': "Tho",
  'tov': "Upper Taromi",
  'tow': "Jemez",
  'tox': "Tobian",
  'toy': "Topoiyo",
  'toz': "To",
  'tpa': "Taupota",
  'tpc': "Azoyú Tlapanec",
  'tpe': "Tippera",
  'tpf': "Tarpia",
  'tpg': "Kula",
  'tpi': "Tok Pisin",
  'tpj': "Tapieté",
  'tpk': "Tupinikin",
  'tpl': "Tlacoapa Tlapanec",
  'tpm': "Tampulma",
  'tpn': "Tupinambá",
  'tpo': "Tai Pao",
  'tpp': "Pisaflores Tepehua",
  'tpq': "Tukpa",
  'tpr': "Tuparí",
  'tpt': "Tlachichilco Tepehua",
  'tpu': "Tampuan",
  'tpv': "Tanapag",
  'tpw': "Tupí",
  'tpx': "Acatepec Tlapanec",
  'tpy': "Trumai",
  'tpz': "Tinputz",
  'tqb': "Tembé",
  'tql': "Lehali",
  'tqm': "Turumsa",
  'tqn': "Tenino",
  'tqo': "Toaripi",
  'tqp': "Tomoip",
  'tqq': "Tunni",
  'tqr': "Torona",
  'tqt': "Western Totonac",
  'tqu': "Touo",
  'tqw': "Tonkawa",
  'tra': "Tirahi",
  'trb': "Terebu",
  'trc': "Copala Triqui",
  'trd': "Turi",
  'tre': "East Tarangan",
  'trf': "Trinidadian Creole English",
  'trg': "Lishán Didán",
  'trh': "Turaka",
  'tri': "Trió",
  'trj': "Toram",
  'trk': "Turkic languages",
  'trl': "Traveller Scottish",
  'trm': "Tregami",
  'trn': "Trinitario",
  'tro': "Tarao Naga",
  'trp': "Kok Borok",
  'trq': "San Martín Itunyoso Triqui",
  'trr': "Taushiro",
  'trs': "Chicahuaxtla Triqui",
  'trt': "Tunggare",
  'tru': "Surayt",
  'trv': "Taroko",
  'trw': "Torwali",
  'trx': "Tringgus-Sembaan Bidayuh",
  'try': "Turung",
  'trz': "Torá",
  'tsa': "Tsaangi",
  'tsb': "Tsamai",
  'tsc': "Tswa",
  'tsd': "Tsakonian",
  'tse': "Tunisian Sign Language",
  'tsf': "Southwestern Tamang",
  'tsg': "Tausug",
  'tsh': "Tsuvan",
  'tsi': "Tsimshian",
  'tsj': "Tshangla",
  'tsk': "Tseku",
  'tsl': "Ts'ün-Lao",
  'tsm': "Türk İşaret Dili",
  'tsp': "Northern Toussian",
  'tsq': "Thai Sign Language",
  'tsr': "Akei",
  'tss': "Taiwan Sign Language",
  'tst': "Tondi Songway Kiini",
  'tsu': "Tsou",
  'tsv': "Tsogo",
  'tsw': "Tsishingini",
  'tsx': "Mubami",
  'tsy': "Tebul Sign Language",
  'tsz': "Purepecha",
  'tta': "Tutelo",
  'ttb': "Gaa",
  'ttc': "Tektiteko",
  'ttd': "Tauade",
  'tte': "Bwanabwana",
  'ttf': "Tuotomb",
  'ttg': "Tutong",
  'tth': "Upper Ta'oih",
  'tti': "Tobati",
  'ttj': "Tooro",
  'ttk': "Totoro",
  'ttl': "Totela",
  'ttm': "Northern Tutchone",
  'ttn': "Towei",
  'tto': "Lower Ta'oih",
  'ttp': "Tombelala",
  'ttq': "Tawallammat Tamajaq",
  'ttr': "Tera",
  'tts': "Northeastern Thai",
  'ttt': "Muslim Tat",
  'ttu': "Torau",
  'ttv': "Titan",
  'ttw': "Long Wat",
  'tty': "Sikaritai",
  'ttz': "Tsum",
  'tua': "Wiarumus",
  'tub': "Tübatulabal",
  'tuc': "Mutu",
  'tud': "Tuxá",
  'tue': "Tuyuca",
  'tuf': "Central Tunebo",
  'tug': "Tunia",
  'tuh': "Taulil",
  'tui': "Tupuri",
  'tuj': "Tugutil",
  'tul': "Tula",
  'tum': "Tumbuka",
  'tun': "Tunica",
  'tuo': "Tucano",
  'tup': "Tupi languages",
  'tuq': "Tedaga",
  'tus': "Tuscarora",
  'tut': "Altaic languages",
  'tuu': "Tututni",
  'tuv': "Turkana",
  'tuw': "Tungus languages",
  'tux': "Tuxináwa",
  'tuy': "Tugen",
  'tuz': "Turka",
  'tva': "Vaghua",
  'tvd': "Tsuvadi",
  'tve': "Te'un",
  'tvi': "Tulai",
  'tvk': "Southeast Ambrym",
  'tvl': "Tuvalu",
  'tvm': "Tela-Masbuar",
  'tvn': "Tavoyan",
  'tvo': "Tidore",
  'tvs': "Taveta",
  'tvt': "Tutsa Naga",
  'tvu': "Tunen",
  'tvw': "Sedoa",
  'tvx': "Taivoan",
  'tvy': "Timor Pidgin",
  'twa': "Twana",
  'twb': "Western Tawbuid",
  'twc': "Teshenawa",
  'twd': "Twents",
  'twe': "Tewa (Indonesia)",
  'twf': "Northern Tiwa",
  'twg': "Tereweng",
  'twh': "Tai Dón",
  'twl': "Tawara",
  'twm': "Tawang Monpa",
  'twn': "Twendi",
  'two': "Tswapong",
  'twp': "Ere",
  'twq': "Tasawaq",
  'twr': "Southwestern Tarahumara",
  'twt': "Turiwára",
  'twu': "Termanu",
  'tww': "Tuwari",
  'twx': "Tewe",
  'twy': "Tawoyan",
  'txa': "Tombonuo",
  'txb': "Tokharian B",
  'txc': "Tsetsaut",
  'txe': "Totoli",
  'txg': "Tangut",
  'txh': "Thracian",
  'txi': "Ikpeng",
  'txj': "Tarjumo",
  'txm': "Tomini",
  'txn': "West Tarangan",
  'txo': "Toto",
  'txq': "Tii",
  'txr': "Tartessian",
  'txs': "Tonsea",
  'txt': "Citak",
  'txu': "Kayapó",
  'txx': "Tatana",
  'txy': "Tanosy Malagasy",
  'tya': "Tauya",
  'tye': "Kyanga",
  'tyh': "O'du",
  'tyi': "Teke-Tsaayi",
  'tyj': "Tai Yo",
  'tyl': "Thu Lao",
  'tyn': "Kombai",
  'typ': "Thaypan",
  'tyr': "Tai Daeng",
  'tys': "Tày Sa Pa",
  'tyt': "Tày Tac",
  'tyu': "Kua",
  'tyv': "Tuvinian",
  'tyx': "Teke-Tyee",
  'tyy': "Tiyaa",
  'tyz': "Tày",
  'tza': "Tanzanian Sign Language",
  'tzh': "Tzeltal",
  'tzj': "Tz'utujil",
  'tzl': "Talossan",
  'tzm': "Central Atlas Tamazight",
  'tzn': "Tugun",
  'tzo': "Tzotzil",
  'tzx': "Tabriak",
  'uam': "Uamué",
  'uan': "Kuan",
  'uar': "Tairuma",
  'uba': "Ubang",
  'ubi': "Ubi",
  'ubl': "Buhi'non Bikol",
  'ubr': "Ubir",
  'ubu': "Umbu-Ungu",
  'uby': "Ubykh",
  'uda': "Uda",
  'ude': "Udihe",
  'udg': "Muduga",
  'udi': "Udi",
  'udj': "Ujir",
  'udl': "Wuzlam",
  'udm': "Udmurt",
  'udu': "Uduk",
  'ues': "Kioko",
  'ufi': "Ufim",
  'uga': "Ugaritic",
  'ugb': "Kuku-Ugbanh",
  'uge': "Ughele",
  'ugh': "Kubachi",
  'ugn': "Ugandan Sign Language",
  'ugo': "Ugong",
  'ugy': "Uruguayan Sign Language",
  'uha': "Uhami",
  'uhn': "Damal",
  'uis': "Uisai",
  'uiv': "Iyive",
  'uji': "Tanjijili",
  'uka': "Kaburi",
  'ukg': "Ukuriguma",
  'ukh': "Ukhwejo",
  'uki': "Kui (India)",
  'ukk': "Muak Sa-aak",
  'ukl': "Ukrainian Sign Language",
  'ukp': "Ukpe-Bayobiri",
  'ukq': "Ukwa",
  'uks': "Kaapor Sign Language",
  'uku': "Ukue",
  'ukv': "Kuku",
  'ukw': "Ukwuani-Aboh-Ndoni",
  'uky': "Kuuk-Yak",
  'ula': "Fungwa",
  'ulb': "Ulukwumi",
  'ulc': "Ulch",
  'ule': "Lule",
  'ulf': "Afra",
  'uli': "Ulithian",
  'ulk': "Meriam Mir",
  'ull': "Ullatan",
  'ulm': "Ulumanda'",
  'uln': "Unserdeutsch",
  'ulu': "Uma' Lung",
  'ulw': "Ulwa",
  'uly': "Buli",
  'uma': "Umatilla",
  'umb': "Umbundu",
  'umc': "Marrucinian",
  'umd': "Umbindhamu",
  'umg': "Umbuygamu",
  'umi': "Ukit",
  'umm': "Umon",
  'umn': "Makyan Naga",
  'umo': "Umotína",
  'ump': "Umpila",
  'umr': "Umbugarla",
  'ums': "Pendau",
  'umu': "Munsee",
  'una': "North Watut",
  'und': "Undetermined",
  'une': "Uneme",
  'ung': "Ngarinyin",
  'uni': "Uni",
  'unk': "Enawené-Nawé",
  'unm': "Unami",
  'unn': "Kurnai",
  'unp': "Worora",
  'unr': "Mundari",
  'unu': "Unubahe",
  'unx': "Munda",
  'unz': "Unde Kaili",
  'uok': "Uokha",
  'uon': "Kulon",
  'upi': "Umeda",
  'upv': "Uripiv-Wala-Rano-Atchin",
  'ura': "Urarina",
  'urb': "Kaapor",
  'urc': "Urningangg",
  'ure': "Uru",
  'urf': "Uradhi",
  'urg': "Urigina",
  'urh': "Urhobo",
  'uri': "Urim",
  'urj': "Uralic languages",
  'urk': "Urak Lawoi'",
  'url': "Urali",
  'urm': "Urapmin",
  'urn': "Uruangnirin",
  'uro': "Ura (Papua New Guinea)",
  'urp': "Uru-Pa-In",
  'urr': "Löyöp",
  'urt': "Urat",
  'uru': "Urumi",
  'urv': "Uruava",
  'urw': "Sop",
  'urx': "Urimo",
  'ury': "Orya",
  'urz': "Uru-Eu-Wau-Wau",
  'usa': "Usarufa",
  'ush': "Ushojo",
  'usi': "Usui",
  'usk': "Usaghade",
  'usp': "Uspanteco",
  'uss': "us-Saare",
  'usu': "Uya",
  'uta': "Otank",
  'ute': "Ute-Southern Paiute",
  'uth': "ut-Hun",
  'utp': "Amba (Solomon Islands)",
  'utr': "Etulo",
  'utu': "Utu",
  'uum': "Urum",
  'uun': "Kulon-Pazeh",
  'uur': "Ura (Vanuatu)",
  'uuu': "U",
  'uve': "Fagauvea",
  'uvh': "Uri",
  'uvl': "Lote",
  'uwa': "Kuku-Uwanh",
  'uya': "Doko-Uyanga",
  'uzn': "Northern Uzbek",
  'uzs': "Southern Uzbek",
  'vaa': "Vaagri Booli",
  'vae': "Vale",
  'vaf': "Vafsi",
  'vag': "Vagla",
  'vah': "Varhadi-Nagpuri",
  'vai': "Vai",
  'vaj': "Vasekele",
  'val': "Vehes",
  'vam': "Vanimo",
  'van': "Valman",
  'vao': "Vao",
  'vap': "Vaiphei",
  'var': "Huarijio",
  'vas': "Vasavi",
  'vau': "Vanuma",
  'vav': "Varli",
  'vay': "Wayu",
  'vbb': "Southeast Babar",
  'vbk': "Southwestern Bontok",
  'vec': "Venetian",
  'ved': "Veddah",
  'vel': "Veluws",
  'vem': "Vemgo-Mabas",
  'veo': "Ventureño",
  'vep': "Veps",
  'ver': "Mom Jango",
  'vgr': "Vaghri",
  'vgt': "Flemish Sign Language",
  'vic': "Virgin Islands Creole English",
  'vid': "Vidunda",
  'vif': "Vili",
  'vig': "Viemo",
  'vil': "Vilela",
  'vin': "Vinza",
  'vis': "Vishavan",
  'vit': "Viti",
  'viv': "Iduna",
  'vjk': "Bajjika",
  'vka': "Kariyarra",
  'vki': "Ija-Zuba",
  'vkj': "Kujarge",
  'vkk': "Kaur",
  'vkl': "Kulisusu",
  'vkm': "Kamakan",
  'vkn': "Koro Nulu",
  'vko': "Kodeoha",
  'vkp': "Korlai Creole Portuguese",
  'vkt': "Tenggarong Kutai Malay",
  'vku': "Kurrama",
  'vkz': "Koro Zuba",
  'vlp': "Valpei",
  'vls': "Vlaams",
  'vma': "Martuyhunira",
  'vmb': "Barbaram",
  'vmc': "Juxtlahuaca Mixtec",
  'vmd': "Mudu Koraga",
  'vme': "East Masela",
  'vmf': "Mainfränkisch",
  'vmg': "Lungalunga",
  'vmh': "Maraghei",
  'vmi': "Miwa",
  'vmj': "Ixtayutla Mixtec",
  'vmk': "Makhuwa-Shirima",
  'vml': "Malgana",
  'vmm': "Mitlatongo Mixtec",
  'vmp': "Soyaltepec Mazatec",
  'vmq': "Soyaltepec Mixtec",
  'vmr': "Marenje",
  'vms': "Moksela",
  'vmu': "Muluridyi",
  'vmv': "Valley Maidu",
  'vmw': "Makhuwa",
  'vmx': "Tamazola Mixtec",
  'vmy': "Ayautla Mazatec",
  'vmz': "Mazatlán Mazatec",
  'vnk': "Lovono",
  'vnm': "Neve'ei",
  'vnp': "Vunapu",
  'vor': "Voro",
  'vot': "Votic",
  'vra': "Vera'a",
  'vro': "Võro",
  'vrs': "Varisi",
  'vrt': "Banam Bay",
  'vsi': "Moldova Sign Language",
  'vsl': "Venezuelan Sign Language",
  'vsv': "Llengua de signes valenciana",
  'vto': "Vitou",
  'vum': "Vumbu",
  'vun': "Vunjo",
  'vut': "Vute",
  'vwa': "Awa (China)",
  'waa': "Walla Walla",
  'wab': "Wab",
  'wac': "Wasco-Wishram",
  'wad': "Wondama",
  'wae': "Walser",
  'waf': "Wakoná",
  'wag': "Wa'ema",
  'wah': "Watubela",
  'wai': "Wares",
  'waj': "Waffa",
  'wak': "Wakashan languages",
  'wal': "Wolaitta",
  'wam': "Wampanoag",
  'wan': "Wan",
  'wao': "Wappo",
  'wap': "Wapishana",
  'waq': "Wagiman",
  'war': "Waray (Philippines)",
  'was': "Washo",
  'wat': "Kaninuwa",
  'wau': "Waurá",
  'wav': "Waka",
  'waw': "Waiwai",
  'wax': "Marangis",
  'way': "Wayana",
  'waz': "Wampur",
  'wba': "Warao",
  'wbb': "Wabo",
  'wbe': "Waritai",
  'wbf': "Wara",
  'wbh': "Wanda",
  'wbi': "Vwanji",
  'wbj': "Alagwa",
  'wbk': "Waigali",
  'wbl': "Wakhi",
  'wbm': "Wa",
  'wbp': "Warlpiri",
  'wbq': "Waddar",
  'wbr': "Wagdi",
  'wbs': "West Bengal Sign Language",
  'wbt': "Warnman",
  'wbv': "Wajarri",
  'wbw': "Woi",
  'wca': "Yanomámi",
  'wci': "Waci Gbe",
  'wdd': "Wandji",
  'wdg': "Wadaginam",
  'wdj': "Wadjiginy",
  'wdk': "Wadikali",
  'wdt': "Wendat",
  'wdu': "Wadjigu",
  'wdy': "Wadjabangayi",
  'wea': "Wewaw",
  'wec': "Wè Western",
  'wed': "Wedau",
  'weg': "Wergaia",
  'weh': "Weh",
  'wei': "Kiunum",
  'wem': "Weme Gbe",
  'wen': "Sorbian languages",
  'weo': "Wemale",
  'wep': "Westphalien",
  'wer': "Weri",
  'wes': "Cameroon Pidgin",
  'wet': "Perai",
  'weu': "Rawngtu Chin",
  'wew': "Wejewa",
  'wfg': "Zorop",
  'wga': "Wagaya",
  'wgb': "Wagawaga",
  'wgg': "Wangganguru",
  'wgi': "Wahgi",
  'wgo': "Waigeo",
  'wgu': "Wirangu",
  'wgw': "Wagawaga",
  'wgy': "Warrgamay",
  'wha': "Manusela",
  'whg': "North Wahgi",
  'whk': "Wahau Kenyah",
  'whu': "Wahau Kayan",
  'wib': "Southern Toussian",
  'wic': "Wichita",
  'wie': "Wik-Epa",
  'wif': "Wik-Keyangan",
  'wig': "Wik Ngathan",
  'wih': "Wik-Me'anha",
  'wii': "Minidien",
  'wij': "Wik-Iiyanh",
  'wik': "Wikalkan",
  'wil': "Wilawila",
  'wim': "Wik-Mungkan",
  'win': "Ho-Chunk",
  'wir': "Wiraféd",
  'wit': "Wintu",
  'wiu': "Wiru",
  'wiv': "Vitu",
  'wiw': "Wirangu",
  'wiy': "Wiyot",
  'wja': "Waja",
  'wji': "Warji",
  'wka': "Kw'adza",
  'wkb': "Kumbaran",
  'wkd': "Mo",
  'wkl': "Kalanadi",
  'wkr': "Keerray-Woorroong",
  'wku': "Kunduvadi",
  'wkw': "Wakawaka",
  'wky': "Wangkayutyuru",
  'wla': "Walio",
  'wlc': "Mwali Comorian",
  'wle': "Wolane",
  'wlg': "Kunbarlang",
  'wlh': "Welaun",
  'wli': "Waioli",
  'wlk': "Wailaki",
  'wll': "Wali (Sudan)",
  'wlm': "Middle Welsh",
  'wlo': "Wolio",
  'wlr': "Wailapa",
  'wls': "Wallisian",
  'wlu': "Wuliwuli",
  'wlv': "Wichí Lhamtés Vejoz",
  'wlw': "Walak",
  'wlx': "Wali (Ghana)",
  'wly': "Waling",
  'wma': "Mawa (Nigeria)",
  'wmb': "Wambaya",
  'wmc': "Wamas",
  'wmd': "Mamaindé",
  'wme': "Wambule",
  'wmg': "Western Minyag",
  'wmh': "Waima'a",
  'wmi': "Wamin",
  'wmm': "Maiwa (Indonesia)",
  'wmn': "Waamwang",
  'wmo': "Wom (Papua New Guinea)",
  'wms': "Wambon",
  'wmt': "Walmajarri",
  'wmw': "Mwani",
  'wmx': "Womo",
  'wnb': "Mokati",
  'wnc': "Wantoat",
  'wnd': "Wandarang",
  'wne': "Waneci",
  'wng': "Wanggom",
  'wni': "Ndzwani Comorian",
  'wnk': "Wanukaka",
  'wnm': "Wanggamala",
  'wnn': "Wunumara",
  'wno': "Wano",
  'wnp': "Wanap",
  'wnu': "Usan",
  'wnw': "Wintu",
  'wny': "Waanyi",
  'woa': "Tyaraity",
  'wob': "Wè Northern",
  'woc': "Wogeo",
  'wod': "Wolani",
  'woe': "Woleaian",
  'wof': "Gambian Wolof",
  'wog': "Wogamusin",
  'woi': "Kamang",
  'wok': "Longto",
  'wom': "Wom (Nigeria)",
  'won': "Wongo",
  'woo': "Manombai",
  'wor': "Woria",
  'wos': "Hanga Hundi",
  'wow': "Wawonii",
  'woy': "Weyto",
  'wpc': "Maco",
  'wra': "Warapu",
  'wrb': "Warluwara",
  'wrd': "Warduji",
  'wrg': "Gudjal",
  'wrh': "Wiradjuri",
  'wri': "Wariyangga",
  'wrk': "Garrwa",
  'wrl': "Warlmanpa",
  'wrm': "Warumungu",
  'wrn': "Warnang",
  'wro': "Worrorra",
  'wrp': "Waropen",
  'wrr': "Wardaman",
  'wrs': "Waris",
  'wru': "Waru",
  'wrv': "Waruna",
  'wrw': "Gugu Warra",
  'wrx': "Wae Rana",
  'wry': "Merwari",
  'wrz': "Waray (Australia)",
  'wsa': "Warembori",
  'wsg': "Adilabad Gondi",
  'wsi': "Wusi",
  'wsk': "Waskia",
  'wsr': "Owenia",
  'wss': "Wasa",
  'wsu': "Wasu",
  'wsv': "Wotapuri-Katarqalai",
  'wtb': "Matambwe",
  'wtf': "Watiwa",
  'wth': "Wathawurrung",
  'wti': "Berta",
  'wtk': "Watakataui",
  'wtm': "Mewati",
  'wtw': "Wotu",
  'wua': "Wikngenchera",
  'wub': "Wunambal",
  'wud': "Wudu",
  'wuh': "Wutunhua",
  'wul': "Silimo",
  'wum': "Wumbvu",
  'wun': "Bungu",
  'wur': "Wurrugu",
  'wut': "Wutung",
  'wuu': "Wu Chinese",
  'wuv': "Wuvulu-Aua",
  'wux': "Wulna",
  'wuy': "Wauyai",
  'wwa': "Waama",
  'wwb': "Wakabunga",
  'wwo': "Dorig",
  'wwr': "Warrwa",
  'www': "Wawa",
  'wxa': "Waxianghua",
  'wxw': "Wardandi",
  'wya': "Wyandot",
  'wyb': "Wangaaybuwan-Ngiyambaa",
  'wyi': "Woiwurrung",
  'wym': "Wymysorys",
  'wyn': "Wyandot",
  'wyr': "Wayoró",
  'wyy': "Western Fijian",
  'xaa': "Andalusian Arabic",
  'xab': "Sambe",
  'xac': "Kachari",
  'xad': "Adai",
  'xae': "Aequian",
  'xag': "Aghwan",
  'xai': "Kaimbé",
  'xaj': "Ararandewára",
  'xak': "Máku",
  'xal': "Oirat",
  'xam': "ǀXam",
  'xan': "Xamtanga",
  'xao': "Khao",
  'xap': "Apalachee",
  'xaq': "Aquitanian",
  'xar': "Karami",
  'xas': "Kamas",
  'xat': "Katawixi",
  'xau': "Kauwera",
  'xav': "Xavánte",
  'xaw': "Kawaiisu",
  'xay': "Kayan Mahakam",
  'xba': "Kamba (Brazil)",
  'xbb': "Lower Burdekin",
  'xbc': "Bactrian",
  'xbd': "Bindal",
  'xbe': "Bigambal",
  'xbg': "Bunganditj",
  'xbi': "Kombio",
  'xbj': "Birrpayi",
  'xbm': "Middle Breton",
  'xbn': "Kenaboi",
  'xbo': "Bolgarian",
  'xbp': "Bibbulman",
  'xbr': "Kambera",
  'xbw': "Kambiwá",
  'xbx': "Kabixí",
  'xby': "Batyala",
  'xcb': "Cumbric",
  'xcc': "Camunic",
  'xce': "Celtiberian",
  'xcg': "Cisalpine Gaulish",
  'xch': "Chimakum",
  'xcl': "Classical Armenian",
  'xcm': "Comecrudo",
  'xcn': "Cotoname",
  'xco': "Chorasmian",
  'xcr': "Carian",
  'xct': "Classical Tibetan",
  'xcu': "Curonian",
  'xcv': "Chuvantsy",
  'xcw': "Coahuilteco",
  'xcy': "Cayuse",
  'xda': "Darkinyung",
  'xdc': "Dacian",
  'xdk': "Dharuk",
  'xdm': "Edomite",
  'xdo': "Kwandu",
  'xdq': "Kaitag",
  'xdy': "Malayic Dayak",
  'xeb': "Eblan",
  'xed': "Hdi",
  'xeg': "ǁXegwi",
  'xel': "Kelo",
  'xem': "Kembayan",
  'xep': "Epi-Olmec",
  'xer': "Xerénte",
  'xes': "Kesawai",
  'xet': "Xetá",
  'xeu': "Keoru-Ahia",
  'xfa': "Faliscan",
  'xga': "Galatian",
  'xgb': "Gbin",
  'xgd': "Gudang",
  'xgf': "Gabrielino-Fernandeño",
  'xgg': "Goreng",
  'xgi': "Garingbal",
  'xgl': "Galindan",
  'xgm': "Guwinmal",
  'xgn': "Mongolian languages",
  'xgr': "Garza",
  'xgu': "Unggumi",
  'xgw': "Guwa",
  'xha': "Harami",
  'xhc': "Hunnic",
  'xhd': "Hadrami",
  'xhe': "Khetrani",
  'xhm': "Middle Khmer (1400 to 1850 CE)",
  'xhr': "Hernican",
  'xht': "Hattic",
  'xhu': "Hurrian",
  'xhv': "Khua",
  'xia': "Xiandao",
  'xib': "Iberian",
  'xii': "Xiri",
  'xil': "Illyrian",
  'xin': "Xinca",
  'xip': "Xipináwa",
  'xir': "Xiriâna",
  'xis': "Kisan",
  'xiv': "Indus Valley Language",
  'xiy': "Xipaya",
  'xjb': "Minjungbal",
  'xjt': "Jaitmatang",
  'xka': "Kalkoti",
  'xkb': "Northern Nago",
  'xkc': "Kho'ini",
  'xkd': "Mendalam Kayan",
  'xke': "Kereho",
  'xkf': "Khengkha",
  'xkg': "Kagoro",
  'xkh': "Karahawyana",
  'xki': "Kenyan Sign Language",
  'xkj': "Kajali",
  'xkk': "Kaco'",
  'xkl': "Mainstream Kenyah",
  'xkn': "Kayan River Kayan",
  'xko': "Kiorr",
  'xkp': "Kabatei",
  'xkq': "Koroni",
  'xkr': "Xakriabá",
  'xks': "Kumbewaha",
  'xkt': "Kantosi",
  'xku': "Kaamba",
  'xkv': "Kgalagadi",
  'xkw': "Kembra",
  'xkx': "Karore",
  'xky': "Uma' Lasan",
  'xkz': "Kurtokha",
  'xla': "Kamula",
  'xlb': "Loup B",
  'xlc': "Lycian",
  'xld': "Lydian",
  'xle': "Lemnian",
  'xlg': "Ligurian (Ancient)",
  'xli': "Liburnian",
  'xln': "Alanic",
  'xlo': "Loup A",
  'xlp': "Lepontic",
  'xls': "Lusitanian",
  'xlu': "Cuneiform Luwian",
  'xly': "Elymian",
  'xma': "Mushungulu",
  'xmb': "Mbonga",
  'xmc': "Makhuwa-Marrevone",
  'xmd': "Mbudum",
  'xme': "Median",
  'xmf': "Mingrelian",
  'xmg': "Mengaka",
  'xmh': "Kugu-Muminh",
  'xmj': "Majera",
  'xmk': "Ancient Macedonian",
  'xml': "Malaysian Sign Language",
  'xmm': "Manado Malay",
  'xmn': "Manichaean Middle Persian",
  'xmo': "Morerebi",
  'xmp': "Kuku-Mu'inh",
  'xmq': "Kuku-Mangk",
  'xmr': "Meroitic",
  'xms': "Moroccan Sign Language",
  'xmt': "Matbat",
  'xmu': "Kamu",
  'xmv': "Tankarana Malagasy",
  'xmw': "Tsimihety Malagasy",
  'xmx': "Maden",
  'xmy': "Mayaguduna",
  'xmz': "Mori Bawah",
  'xna': "Ancient North Arabian",
  'xnb': "Kanakanabu",
  'xnd': "Na-Dene languages",
  'xng': "Middle Mongolian",
  'xnh': "Kuanhua",
  'xni': "Ngarigu",
  'xnj': "Ngoni (Tanzania)",
  'xnk': "Nganakarti",
  'xnm': "Ngumbarl",
  'xnn': "Northern Kankanay",
  'xno': "Anglo-Norman",
  'xnq': "Ngoni (Mozambique)",
  'xnr': "Kangri",
  'xns': "Kanashi",
  'xnt': "Narragansett",
  'xnu': "Nukunul",
  'xny': "Nyiyaparli",
  'xnz': "Mattoki",
  'xoc': "O'chi'chi'",
  'xod': "Kokoda",
  'xog': "Soga",
  'xoi': "Kominimung",
  'xok': "Xokleng",
  'xom': "Komo (Sudan)",
  'xon': "Konkomba",
  'xoo': "Xukurú",
  'xop': "Kopar",
  'xor': "Korubo",
  'xow': "Kowaki",
  'xpa': "Pirriya",
  'xpb': "Pyemmairrener",
  'xpc': "Pecheneg",
  'xpd': "Oyster Bay Tasmanian",
  'xpe': "Liberia Kpelle",
  'xpf': "Nuenonne",
  'xpg': "Phrygian",
  'xph': "Tyerrenoterpanner",
  'xpi': "Pictish",
  'xpj': "Mpalitjanh",
  'xpk': "Kulina Pano",
  'xpl': "Port Sorell Tasmanian",
  'xpm': "Pumpokol",
  'xpn': "Kapinawá",
  'xpo': "Pochutec",
  'xpp': "Puyo-Paekche",
  'xpq': "Mohegan-Pequot",
  'xpr': "Parthian",
  'xps': "Pisidian",
  'xpt': "Punthamara",
  'xpu': "Punic",
  'xpv': "Tommeginne",
  'xpw': "Peerapper",
  'xpx': "Toogee",
  'xpy': "Puyo",
  'xpz': "Bruny Island Tasmanian",
  'xqa': "Karakhanid",
  'xqt': "Qatabanian",
  'xra': "Krahô",
  'xrb': "Eastern Karaboro",
  'xrd': "Gundungurra",
  'xre': "Kreye",
  'xrg': "Minang",
  'xri': "Krikati-Timbira",
  'xrm': "Armazic",
  'xrn': "Arin",
  'xrq': "Karranga",
  'xrr': "Raetic",
  'xrt': "Aranama-Tamique",
  'xru': "Marriammu",
  'xrw': "Karawa",
  'xsa': "Sabaean",
  'xsb': "Sambal",
  'xsc': "Scythian",
  'xsd': "Sidetic",
  'xse': "Sempan",
  'xsh': "Shamang",
  'xsi': "Sio",
  'xsj': "Subi",
  'xsl': "South Slavey",
  'xsm': "Kasem",
  'xsn': "Sanga (Nigeria)",
  'xso': "Solano",
  'xsp': "Silopi",
  'xsq': "Makhuwa-Saka",
  'xsr': "Sherpa",
  'xss': "Assan",
  'xsu': "Sanumá",
  'xsv': "Sudovian",
  'xsy': "Saisiyat",
  'xta': "Alcozauca Mixtec",
  'xtb': "Chazumba Mixtec",
  'xtc': "Katcha-Kadugli-Miri",
  'xtd': "Diuxi-Tilantongo Mixtec",
  'xte': "Ketengban",
  'xtg': "Transalpine Gaulish",
  'xth': "Yitha Yitha",
  'xti': "Sinicahua Mixtec",
  'xtj': "San Juan Teita Mixtec",
  'xtl': "Tijaltepec Mixtec",
  'xtm': "Magdalena Peñasco Mixtec",
  'xtn': "Northern Tlaxiaco Mixtec",
  'xto': "Tokharian A",
  'xtp': "San Miguel Piedras Mixtec",
  'xtq': "Tumshuqese",
  'xtr': "Early Tripuri",
  'xts': "Sindihui Mixtec",
  'xtt': "Tacahua Mixtec",
  'xtu': "Cuyamecalco Mixtec",
  'xtv': "Thawa",
  'xtw': "Tawandê",
  'xty': "Yoloxochitl Mixtec",
  'xtz': "Tasmanian",
  'xua': "Alu Kurumba",
  'xub': "Betta Kurumba",
  'xud': "Umiida",
  'xug': "Kunigami",
  'xuj': "Jennu Kurumba",
  'xul': "Nunukul",
  'xum': "Umbrian",
  'xun': "Unggaranggu",
  'xuo': "Kuo",
  'xup': "Upper Umpqua",
  'xur': "Urartian",
  'xut': "Kuthant",
  'xuu': "Khwedam",
  'xve': "Venetic",
  'xvi': "Kamviri",
  'xvn': "Vandalic",
  'xvo': "Volscian",
  'xvs': "Vestinian",
  'xwa': "Kwaza",
  'xwc': "Woccon",
  'xwd': "Wadi Wadi",
  'xwe': "Xwela Gbe",
  'xwg': "Kwegu",
  'xwj': "Wajuk",
  'xwk': "Wangkumara",
  'xwl': "Western Xwla Gbe",
  'xwo': "Written Oirat",
  'xwr': "Kwerba Mamberamo",
  'xwt': "Wotjobaluk",
  'xww': "Wemba Wemba",
  'xxb': "Boro (Ghana)",
  'xxk': "Ke'o",
  'xxm': "Minkin",
  'xxr': "Koropó",
  'xxt': "Tambora",
  'xya': "Yaygir",
  'xyb': "Yandjibara",
  'xyj': "Mayi-Yapi",
  'xyk': "Mayi-Kulan",
  'xyl': "Yalakalore",
  'xyt': "Mayi-Thakurti",
  'xyy': "Yorta Yorta",
  'xzh': "Zhang-Zhung",
  'xzm': "Zemgalian",
  'xzp': "Ancient Zapotec",
  'yaa': "Yaminahua",
  'yab': "Yuhup",
  'yac': "Pass Valley Yali",
  'yad': "Yagua",
  'yae': "Pumé",
  'yaf': "Yaka (Democratic Republic of Congo)",
  'yag': "Yámana",
  'yah': "Yazgulyam",
  'yai': "Yagnobi",
  'yaj': "Banda-Yangere",
  'yak': "Yakama",
  'yal': "Yalunka",
  'yam': "Yamba",
  'yan': "Mayangna",
  'yao': "Yao",
  'yap': "Yapese",
  'yaq': "Yaqui",
  'yar': "Yabarana",
  'yas': "Nugunu (Cameroon)",
  'yat': "Yambeta",
  'yau': "Yuwana",
  'yav': "Yangben",
  'yaw': "Yawalapití",
  'yax': "Yauma",
  'yay': "Agwagwune",
  'yaz': "Lokaa",
  'yba': "Yala",
  'ybb': "Yemba",
  'ybd': "Yangbye",
  'ybe': "West Yugur",
  'ybh': "Yakha",
  'ybi': "Yamphu",
  'ybj': "Hasha",
  'ybk': "Bokha",
  'ybl': "Yukuben",
  'ybm': "Yaben",
  'ybn': "Yabaâna",
  'ybo': "Yabong",
  'ybx': "Yawiyo",
  'yby': "Yaweyuha",
  'ych': "Chesu",
  'ycl': "Lolopo",
  'ycn': "Yucuna",
  'ycp': "Chepya",
  'ycr': "Yilan Creole",
  'yda': "Yanda",
  'ydd': "Eastern Yiddish",
  'yde': "Yangum Dey",
  'ydg': "Yidgha",
  'ydk': "Yoidik",
  'yds': "Yiddish Sign Language",
  'yea': "Ravula",
  'yec': "Yeniche",
  'yee': "Yimas",
  'yei': "Yeni",
  'yej': "Yevanic",
  'yel': "Yela",
  'yen': "Yendang",
  'yer': "Tarok",
  'yes': "Nyankpa",
  'yet': "Yetfa",
  'yeu': "Yerukula",
  'yev': "Yapunda",
  'yey': "Yeyi",
  'yga': "Malyangapa",
  'ygi': "Yiningayi",
  'ygl': "Yangum Gel",
  'ygm': "Yagomi",
  'ygp': "Gepo",
  'ygr': "Yagaria",
  'ygs': "Yolŋu Sign Language",
  'ygu': "Yugul",
  'ygw': "Yagwoia",
  'yha': "Baha Buyang",
  'yhd': "Judeo-Iraqi Arabic",
  'yhl': "Hlepho Phowa",
  'yhs': "Yan-nhaŋu Sign Language",
  'yia': "Yinggarda",
  'yif': "Ache",
  'yig': "Wusa Nasu",
  'yih': "Western Yiddish",
  'yii': "Yidiny",
  'yij': "Yindjibarndi",
  'yik': "Dongshanba Lalo",
  'yil': "Yindjilandji",
  'yim': "Yimchungru Naga",
  'yin': "Yinchia",
  'yip': "Pholo",
  'yiq': "Miqie",
  'yir': "North Awyu",
  'yis': "Yis",
  'yit': "Eastern Lalu",
  'yiu': "Awu",
  'yiv': "Northern Nisu",
  'yix': "Axi Yi",
  'yiy': "Yir Yoront",
  'yiz': "Azhe",
  'yka': "Yakan",
  'ykg': "Northern Yukaghir",
  'ykh': "Khamnigan Mongol",
  'yki': "Yoke",
  'ykk': "Yakaikeke",
  'ykl': "Khlula",
  'ykm': "Kap",
  'ykn': "Kua-nsi",
  'yko': "Yasa",
  'ykr': "Yekora",
  'ykt': "Kathu",
  'yku': "Kuamasi",
  'yky': "Yakoma",
  'yla': "Yaul",
  'ylb': "Yaleba",
  'yle': "Yele",
  'ylg': "Yelogu",
  'yli': "Angguruk Yali",
  'yll': "Yil",
  'ylm': "Limi",
  'yln': "Langnian Buyang",
  'ylo': "Naluo Yi",
  'ylr': "Yalarnnga",
  'ylu': "Aribwaung",
  'yly': "Nyelâyu",
  'yma': "Yamphe",
  'ymb': "Yambes",
  'ymc': "Southern Muji",
  'ymd': "Muda",
  'yme': "Yameo",
  'ymg': "Yamongeri",
  'ymh': "Mili",
  'ymi': "Moji",
  'ymk': "Makwe",
  'yml': "Iamalele",
  'ymm': "Maay",
  'ymn': "Sunum",
  'ymo': "Yangum Mon",
  'ymp': "Yamap",
  'ymq': "Qila Muji",
  'ymr': "Malasar",
  'yms': "Mysian",
  'ymt': "Mator-Taygi-Karagas",
  'ymx': "Northern Muji",
  'ymz': "Muzi",
  'yna': "Aluo",
  'ynd': "Yandruwandha",
  'yne': "Lang'e",
  'yng': "Yango",
  'ynh': "Yangho",
  'ynk': "Naukan Yupik",
  'ynl': "Yangulam",
  'ynn': "Yana",
  'yno': "Yong",
  'ynq': "Yendang",
  'yns': "Yansi",
  'ynu': "Yahuna",
  'yob': "Yoba",
  'yog': "Yogad",
  'yoi': "Yonaguni",
  'yok': "Yokuts",
  'yol': "Yola",
  'yom': "Yombe",
  'yon': "Yongkom",
  'yos': "Yos",
  'yot': "Yotti",
  'yox': "Yoron",
  'yoy': "Yoy",
  'ypa': "Phala",
  'ypb': "Labo Phowa",
  'ypg': "Phola",
  'yph': "Phupha",
  'ypk': "Yupik languages",
  'ypm': "Phuma",
  'ypn': "Ani Phowa",
  'ypo': "Alo Phola",
  'ypp': "Phupa",
  'ypz': "Phuza",
  'yra': "Yerakai",
  'yrb': "Yareba",
  'yre': "Yaouré",
  'yri': "Yarí",
  'yrk': "Nenets",
  'yrl': "Nhengatu",
  'yrm': "Yirrk-Mel",
  'yrn': "Yerong",
  'yro': "Yaroamë",
  'yrs': "Yarsun",
  'yrw': "Yarawata",
  'yry': "Yarluyandi",
  'ysc': "Yassic",
  'ysd': "Samatao",
  'ysg': "Sonaga",
  'ysl': "Yugoslavian Sign Language",
  'ysm': "Myanmar Sign Language",
  'ysn': "Sani",
  'yso': "Nisi (China)",
  'ysp': "Southern Lolopo",
  'ysr': "Sirenik Yupik",
  'yss': "Yessan-Mayo",
  'ysy': "Sanie",
  'yta': "Talu",
  'ytl': "Tanglang",
  'ytp': "Thopho",
  'ytw': "Yout Wam",
  'yty': "Yatay",
  'yua': "Yucatec Maya",
  'yub': "Yugambal",
  'yuc': "Yuchi",
  'yud': "Judeo-Tripolitanian Arabic",
  'yue': "Cantonese",
  'yuf': "Havasupai-Walapai-Yavapai",
  'yug': "Yug",
  'yui': "Yurutí",
  'yuj': "Karkar-Yuri",
  'yuk': "Yuki",
  'yul': "Yulu",
  'yum': "Quechan",
  'yun': "Bena (Nigeria)",
  'yup': "Yukpa",
  'yuq': "Yuqui",
  'yur': "Yurok",
  'yut': "Yopno",
  'yuu': "Yugh",
  'yuw': "Yau (Morobe Province)",
  'yux': "Southern Yukaghir",
  'yuy': "East Yugur",
  'yuz': "Yuracare",
  'yva': "Yawa",
  'yvt': "Yavitero",
  'ywa': "Kalou",
  'ywg': "Yinhawangka",
  'ywl': "Western Lalu",
  'ywn': "Yawanawa",
  'ywq': "Wuding-Luquan Yi",
  'ywr': "Yawuru",
  'ywt': "Central Lalo",
  'ywu': "Wumeng Nasu",
  'yww': "Yawarawarga",
  'yxa': "Mayawali",
  'yxg': "Yagara",
  'yxl': "Yardliyawarra",
  'yxm': "Yinwum",
  'yxu': "Yuyu",
  'yxy': "Yabula Yabula",
  'yyr': "Yir Yoront",
  'yyu': "Yau (Sandaun Province)",
  'yyz': "Ayizi",
  'yzg': "E'ma Buyang",
  'yzk': "Zokhuo",
  'zaa': "Sierra de Juárez Zapotec",
  'zab': "San Juan Guelavía Zapotec",
  'zac': "Ocotlán Zapotec",
  'zad': "Cajonos Zapotec",
  'zae': "Yareni Zapotec",
  'zaf': "Ayoquesco Zapotec",
  'zag': "Zaghawa",
  'zah': "Zangwal",
  'zai': "Isthmus Zapotec",
  'zaj': "Zaramo",
  'zak': "Zanaki",
  'zal': "Zauzou",
  'zam': "Miahuatlán Zapotec",
  'zao': "Ozolotepec Zapotec",
  'zap': "Zapotec",
  'zaq': "Aloápam Zapotec",
  'zar': "Rincón Zapotec",
  'zas': "Santo Domingo Albarradas Zapotec",
  'zat': "Tabaa Zapotec",
  'zau': "Zangskari",
  'zav': "Yatzachi Zapotec",
  'zaw': "Mitla Zapotec",
  'zax': "Xadani Zapotec",
  'zay': "Zaysete",
  'zaz': "Zari",
  'zba': "Balaibalan",
  'zbc': "Central Berawan",
  'zbe': "East Berawan",
  'zbl': "Blissymbolics",
  'zbt': "Batui",
  'zbu': "Bu (Bauchi State)",
  'zbw': "West Berawan",
  'zca': "Coatecas Altas Zapotec",
  'zcd': "Las Delicias Zapotec",
  'zch': "Central Hongshuihe Zhuang",
  'zdj': "Ngazidja Comorian",
  'zea': "Zeeuws",
  'zeg': "Zenag",
  'zeh': "Eastern Hongshuihe Zhuang",
  'zem': "Zeem",
  'zen': "Zenaga",
  'zga': "Kinga",
  'zgb': "Guibei Zhuang",
  'zgh': "Standard Moroccan Tamazight",
  'zgm': "Minz Zhuang",
  'zgn': "Guibian Zhuang",
  'zgr': "Magori",
  'zhb': "Zhaba",
  'zhd': "Dai Zhuang",
  'zhi': "Zhire",
  'zhn': "Nong Zhuang",
  'zhw': "Zhoa",
  'zhx': "Chinese (family)",
  'zia': "Zia",
  'zib': "Zimbabwe Sign Language",
  'zik': "Zimakani",
  'zil': "Zialo",
  'zim': "Mesme",
  'zin': "Zinza",
  'zir': "Ziriya",
  'ziw': "Zigula",
  'ziz': "Zizilivakan",
  'zka': "Kaimbulawa",
  'zkb': "Koibal",
  'zkd': "Kadu",
  'zkg': "Koguryo",
  'zkh': "Khorezmian",
  'zkk': "Karankawa",
  'zkn': "Kanan",
  'zko': "Kott",
  'zkp': "São Paulo Kaingáng",
  'zkr': "Zakhring",
  'zkt': "Kitan",
  'zku': "Kaurna",
  'zkv': "Krevinian",
  'zkz': "Khazar",
  'zla': "Zula",
  'zle': "East Slavic languages",
  'zlj': "Liujiang Zhuang",
  'zlm': "Malay (individual language)",
  'zln': "Lianshan Zhuang",
  'zlq': "Liuqian Zhuang",
  'zls': "South Slavic languages",
  'zlu': "Zul",
  'zlw': "West Slavic languages",
  'zma': "Manda (Australia)",
  'zmb': "Zimba",
  'zmc': "Margany",
  'zmd': "Maridan",
  'zme': "Mangerr",
  'zmf': "Mfinu",
  'zmg': "Marti Ke",
  'zmh': "Makolkol",
  'zmi': "Negeri Sembilan Malay",
  'zmj': "Maridjabin",
  'zmk': "Mandandanyi",
  'zml': "Matngala",
  'zmm': "Marramaninyshi",
  'zmn': "Mbangwe",
  'zmo': "Molo",
  'zmp': "Mpuono",
  'zmq': "Mituku",
  'zmr': "Maranunggu",
  'zms': "Mbesa",
  'zmt': "Maringarr",
  'zmu': "Muruwari",
  'zmv': "Mbariman-Gudhinma",
  'zmw': "Mbo (Democratic Republic of Congo)",
  'zmx': "Bomitaba",
  'zmy': "Mariyedi",
  'zmz': "Mbandja",
  'zna': "Zan Gula",
  'znd': "Zande languages",
  'zne': "Zande (individual language)",
  'zng': "Mang",
  'znk': "Manangkari",
  'zns': "Mangas",
  'zoc': "Copainalá Zoque",
  'zoh': "Chimalapa Zoque",
  'zom': "Zou",
  'zoo': "Asunción Mixtepec Zapotec",
  'zoq': "Tabasco Zoque",
  'zor': "Rayón Zoque",
  'zos': "Francisco León Zoque",
  'zpa': "Lachiguiri Zapotec",
  'zpb': "Yautepec Zapotec",
  'zpc': "Choapan Zapotec",
  'zpd': "Southeastern Ixtlán Zapotec",
  'zpe': "Petapa Zapotec",
  'zpf': "San Pedro Quiatoni Zapotec",
  'zpg': "Guevea De Humboldt Zapotec",
  'zph': "Totomachapan Zapotec",
  'zpi': "Santa María Quiegolani Zapotec",
  'zpj': "Quiavicuzas Zapotec",
  'zpk': "Tlacolulita Zapotec",
  'zpl': "Lachixío Zapotec",
  'zpm': "Mixtepec Zapotec",
  'zpn': "Santa Inés Yatzechi Zapotec",
  'zpo': "Amatlán Zapotec",
  'zpp': "El Alto Zapotec",
  'zpq': "Zoogocho Zapotec",
  'zpr': "Santiago Xanica Zapotec",
  'zps': "Coatlán Zapotec",
  'zpt': "San Vicente Coatlán Zapotec",
  'zpu': "Yalálag Zapotec",
  'zpv': "Chichicapan Zapotec",
  'zpw': "Zaniza Zapotec",
  'zpx': "San Baltazar Loxicha Zapotec",
  'zpy': "Mazaltepec Zapotec",
  'zpz': "Texmelucan Zapotec",
  'zqe': "Qiubei Zhuang",
  'zra': "Kara (Korea)",
  'zrg': "Mirgan",
  'zrn': "Zerenkel",
  'zro': "Záparo",
  'zrp': "Zarphatic",
  'zrs': "Mairasi",
  'zsa': "Sarasira",
  'zsk': "Kaskean",
  'zsl': "Zambian Sign Language",
  'zsm': "Standard Malay",
  'zsr': "Southern Rincon Zapotec",
  'zsu': "Sukurum",
  'zte': "Elotepec Zapotec",
  'ztg': "Xanaguía Zapotec",
  'ztl': "Lapaguía-Guivini Zapotec",
  'ztm': "San Agustín Mixtepec Zapotec",
  'ztn': "Santa Catarina Albarradas Zapotec",
  'ztp': "Loxicha Zapotec",
  'ztq': "Quioquitani-Quierí Zapotec",
  'zts': "Tilquiapan Zapotec",
  'ztt': "Tejalapan Zapotec",
  'ztu': "Güilá Zapotec",
  'ztx': "Zaachila Zapotec",
  'zty': "Yatee Zapotec",
  'zua': "Zeem",
  'zuh': "Tokano",
  'zum': "Kumzari",
  'zun': "Zuni",
  'zuy': "Zumaya",
  'zwa': "Zay",
  'zxx': "Not applicable",
  'zyb': "Yongbei Zhuang",
  'zyg': "Yang Zhuang",
  'zyj': "Youjiang Zhuang",
  'zyn': "Yongnan Zhuang",
  'zyp': "Zyphe Chin",
  'zza': "Zazaki",
  'zzj': "Zuojiang Zhuang",
};

export default languages;
