
const request = ({ method, uri, payload, headers, token }) => {
  headers = headers || {};

  const methodUpper = method.toUpperCase();

  return fetch(uri, {
    method: methodUpper,
    headers: {
      ...headers,
      ...(token ? {
        'Authorization': `Bearer ${token}`
      } : {}),
      ...(payload ? { 'Content-Type': 'application/json' } : {}),
    },
    body: payload ? JSON.stringify(payload) : undefined,
  })
    .then(response => {
      if (!response.ok) {
        throw response;
      }

      return response.json();
    });
};

const api = (token) => {
  const res = {};

  ['post', 'delete', 'put']
    .forEach(method => {
      res[method] = (uri, payload, headers) => request({ method, uri, payload, headers, token });
    });
  res.get = (uri, headers) => request({ method: 'get', uri, undefined, headers, token });

  return res;
};

export default api;
